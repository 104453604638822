import React, { MouseEvent, useState } from 'react'
import {
  FunctionField,
  TextField,
  TextInput,
  useGetList,
  useNotify,
  useRefresh,
} from 'react-admin'
import ListDatagrid from '../components/ListDatagrid'
import {
  MachinePartParams,
  MachinePartRecord,
  MachinePartTypeRecord,
} from '../types/records/machine-part-record'
import IconButton from '../components/button/IconButton'
import { InputAdornment, Grid } from '@mui/material'
import { ReactComponent as SearchIcon } from '../assets/images/search.svg'
import { ReactComponent as PencilIcon } from '../assets/images/pencil.svg'
import { ReactComponent as TrashIcon } from '../assets/images/trash.svg'
import { TextInputSx, FilterSelectInputSx } from './FilterSx'
import {
  AddSelectInputChoisesStyle,
  DeleteSelectInputChoicesStyle,
} from '../components/SelectInputChoisesStyle'
import AddButton from '../components/button/AddButton'
import AddMachinePartModal from '../components/modal/MachinePartModal/AddMachinePartModal'
import { dataProvider } from '../provider/data-provider'
import EditMachinePartModal from '../components/modal/MachinePartModal/EditMachinePartModal'
import { useAuthorize, useNotifyForbidden } from '../hooks/authorization'
import DeleteMachinePartModal from '../components/modal/MachinePartModal/DeleteMachinePartmodal'
import styles from './MachinePartList.module.scss'
import { firstFieldSx } from '../assets/sx/field'
import CustomPlaceholderSelectInput from '../components/customComponent/CustomPlaceholderSelectInput'

const MachinePartList: React.FC = () => {
  const machinePartType = useGetList('machine-part-types')
  const machinePartTypes: MachinePartTypeRecord[] = []
  const [targetMachinePart, setTargetMachinePart] =
    useState<MachinePartRecord>()
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false)
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)

  const notify = useNotify()
  const refresh = useRefresh()

  const canCreateMachinePart = useAuthorize('machinePart', 'create')
  const canEditMachinePart = useAuthorize('machinePart', 'update')
  const canDeleteMachinePart = useAuthorize('machinePart', 'delete')
  const notifyForbidden = useNotifyForbidden()

  const openCreateModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!canCreateMachinePart) {
      notifyForbidden()
      return
    }

    setCreateModalOpen(true)
  }

  const onCreateSubmit = (data: MachinePartParams) => {
    dataProvider
      .create('machine-parts', { data })
      .then(() => {
        notify('機械部品を追加しました')
        setCreateModalOpen(false)
        refresh()
      })
      .catch((error) => {
        notify(error.message, { type: 'error' })
      })
  }

  const closeCreateModal = () => {
    setCreateModalOpen(false)
  }

  const openEditModal = (
    e: MouseEvent<HTMLElement>,
    targetMachinePart: MachinePartRecord | undefined,
  ) => {
    e.preventDefault()
    if (!canEditMachinePart) {
      notifyForbidden()
      return
    }

    setEditModalOpen(true)
    setTargetMachinePart(targetMachinePart)
  }

  const closeEditModal = () => {
    setEditModalOpen(false)
  }

  const onUpdateSubmit = (
    id: number,
    data: MachinePartParams,
    previousData: MachinePartRecord,
  ) => {
    dataProvider
      .update('machine-parts', {
        id: id,
        data: data,
        previousData: previousData,
      })
      .then(() => {
        notify('機械部品を更新しました')
        setEditModalOpen(false)
        refresh()
      })
      .catch((error) => {
        notify(error.message, { type: 'error' })
      })
  }

  const openDeleteModal = (
    e: MouseEvent<HTMLElement>,
    targetMachinePart: MachinePartRecord | undefined,
  ) => {
    e.preventDefault()
    if (!canDeleteMachinePart) {
      notifyForbidden()
      return
    }

    setDeleteModalOpen(true)
    setTargetMachinePart(targetMachinePart)
  }

  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const onDeleteSubmit = (machinePart: MachinePartRecord) => {
    dataProvider
      .delete('machine-parts', {
        id: machinePart.id,
        previousData: machinePart,
      })
      .then(() => {
        notify('機械部品を削除しました')
        setDeleteModalOpen(false)
        refresh()
      })
      .catch(() => {
        notify('機械部品の削除に失敗しました')
      })
  }

  machinePartType.data?.map((machinePartTypeRecord: MachinePartTypeRecord) => {
    machinePartTypes.push({
      id: machinePartTypeRecord.id,
      name: machinePartTypeRecord.name,
    })
  })

  const filters = [
    <TextInput
      key="machinePartName"
      label={false}
      source="machinePartName"
      placeholder="部品名で検索"
      alwaysOn
      fullWidth
      resettable
      sx={TextInputSx}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />,
    <TextInput
      key="drawingNumber"
      label={false}
      source="drawingNumber"
      placeholder="図面番号で検索"
      alwaysOn
      fullWidth
      resettable
      sx={TextInputSx}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />,
    <CustomPlaceholderSelectInput
      type="filter"
      className="machinePartTypeID"
      key="machinePartTypeID"
      label={false}
      source="machinePartTypeID"
      alwaysOn
      margin="none"
      emptyText="すべて"
      emptyValue=""
      value=""
      placeholder="すべて"
      localStorageKey="machinePartTypeID"
      sx={FilterSelectInputSx}
      choices={machinePartTypes}
      onFocus={() => AddSelectInputChoisesStyle('menu-machinePartTypeID')}
      onBlur={DeleteSelectInputChoicesStyle}
    />,
  ]

  const modals = [
    <AddMachinePartModal
      key="addMachinePartModal"
      open={createModalOpen}
      onClose={closeCreateModal}
      onSubmit={onCreateSubmit}
      machinePartTypes={machinePartTypes}
    />,
    <EditMachinePartModal
      key="editMachinePartModal"
      machinePart={targetMachinePart}
      open={editModalOpen}
      onClose={closeEditModal}
      onSubmit={onUpdateSubmit}
      machinePartTypes={machinePartTypes}
    />,
    <DeleteMachinePartModal
      key="deleteIngredientModal"
      machinePart={targetMachinePart}
      open={deleteModalOpen}
      onClose={closeDeleteModal}
      onSubmit={onDeleteSubmit}
    />,
  ]

  const settingButtonItems = [
    { tooltipLabel: '編集', icon: <PencilIcon />, onClick: openEditModal },
    { tooltipLabel: '削除', icon: <TrashIcon />, onClick: openDeleteModal },
  ]

  return (
    <>
      <ListDatagrid
        title="機械部品マスター"
        filters={filters}
        addButton={
          <AddButton disabled={!canCreateMachinePart} onClick={openCreateModal}>
            機械部品を追加する
          </AddButton>
        }
        modals={modals}>
        <TextField
          source="drawingNumber"
          label="図面番号"
          sortable={false}
          headerClassName={styles.machinePartDrawingNumberField}
          sx={firstFieldSx}
        />
        <TextField
          source="machinePartType.name"
          label="機械部品種別名"
          sortable={false}
          headerClassName={styles.machinePartTypeNameField}
        />
        <TextField
          source="name"
          label="機械部品名"
          sortable={false}
          headerClassName={styles.machinePartNameField}
        />
        <FunctionField
          label="最大稼働時間"
          sortable={false}
          render={(record: MachinePartRecord) =>
            record.motorPartLifetime &&
            record.motorPartLifetime.maximumOperatingHour + '時間'
          }
          headerClassName={styles.maximumActuatingCycleField}
        />
        <FunctionField
          label="最大動作回数"
          sortable={false}
          render={(record: MachinePartRecord) =>
            record.machinePartLifetime &&
            record.machinePartLifetime.maximumActuatingCycle + '万回'
          }
          headerClassName={styles.maximumOperatingHourField}
        />
        <FunctionField
          sortable={false}
          render={(record: MachinePartRecord) => {
            return (
              <Grid container display="flex" justifyContent="right">
                {settingButtonItems.map((item, index) => {
                  return (
                    <IconButton<MachinePartRecord>
                      key={`iconButton_${index}`}
                      tooltipLabel={item.tooltipLabel}
                      onClick={item.onClick}
                      icon={item.icon}
                      record={record}
                    />
                  )
                })}
              </Grid>
            )
          }}
          headerClassName={styles.machinePartIconButtonsField}
        />
      </ListDatagrid>
    </>
  )
}

export default MachinePartList
