import { TubingPumpRecord } from '../../../types/records/tubing_pump-record'

export const masterDataTubingPumps: TubingPumpRecord[] = [
  {
    id: 1,
    name: 'チューブポンプ1',
    liquidLineNumber: 1,
  },
  {
    id: 2,
    name: 'チューブポンプ2',
    liquidLineNumber: 1,
  },
  {
    id: 3,
    name: 'チューブポンプ3',
    liquidLineNumber: 1,
  },
  {
    id: 4,
    name: 'チューブポンプ4',
    liquidLineNumber: 1,
  },
  {
    id: 5,
    name: 'チューブポンプ5',
    liquidLineNumber: 1,
  },
  {
    id: 6,
    name: 'チューブポンプ6',
    liquidLineNumber: 1,
  },
  {
    id: 7,
    name: 'チューブポンプ7',
    liquidLineNumber: 1,
  },
  {
    id: 8,
    name: 'チューブポンプ8',
    liquidLineNumber: 1,
  },
  {
    id: 9,
    name: 'チューブポンプ9',
    liquidLineNumber: 1,
  },
]
