import React from 'react'
import { TextInput, required, DateInput } from 'react-admin'
import { Grid, InputLabel, Typography, InputAdornment } from '@mui/material'

import { MachineParams } from '../../../types/records/machine-record'
import {
  gridSx,
  labelGrayOutSx,
  textInputSx,
  dateInputSx,
} from '../../../assets/sx/form'
import { modalTitleSx } from '../../../assets/sx/modalSx'
import { ReactComponent as CalenderIcon } from '../../../assets/images/calender.svg'

type Props = {
  formData: MachineParams
  handleOnChangeModel: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleOnChangeProductionDate: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void
  handleOnChangeSerial: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleOnChangeSimImsi: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleOnChangeIccid: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const AddMachineModalBasicInfo: React.FC<Props> = ({
  formData,
  handleOnChangeModel,
  handleOnChangeProductionDate,
  handleOnChangeSerial,
  handleOnChangeSimImsi,
  handleOnChangeIccid,
}) => {
  const validateIccid = (iccidInput: string) => {
    if (!/^[0-9]{19}$/.test(iccidInput)) {
      return 'エラー半角数字19文字で入力してください'
    }
    return undefined
  }

  return (
    <Grid>
      <Typography sx={modalTitleSx}>マシンを追加する 1/2</Typography>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="serial" sx={labelGrayOutSx}>
          マシン シリアル *
        </InputLabel>
        <TextInput
          source="serial"
          variant="outlined"
          placeholder="入力してください"
          label={false}
          sx={textInputSx}
          validate={required('エラー入力してください')}
          defaultValue={formData.serial}
          onChange={(event) => handleOnChangeSerial(event)}
          fullWidth
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="model" sx={labelGrayOutSx}>
          機種コード *
        </InputLabel>
        <TextInput
          source="model"
          variant="outlined"
          placeholder="入力してください"
          label={false}
          sx={textInputSx}
          validate={[required('エラー入力してください')]}
          defaultValue={formData.model}
          onChange={(event) => handleOnChangeModel(event)}
          fullWidth
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="simImsi" sx={labelGrayOutSx}>
          SIM IMSI *
        </InputLabel>
        <TextInput
          source="simImsi"
          variant="outlined"
          placeholder="入力してください"
          label={false}
          sx={textInputSx}
          validate={required('エラー入力してください')}
          defaultValue={formData.simImsi}
          onChange={(event) => handleOnChangeSimImsi(event)}
          fullWidth
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="iccid" sx={labelGrayOutSx}>
          SIM ICCID *
        </InputLabel>
        <TextInput
          source="iccid"
          variant="outlined"
          placeholder="入力してください"
          label={false}
          sx={textInputSx}
          validate={[required('エラー入力してください'), validateIccid]}
          onChange={(event) => handleOnChangeIccid(event)}
          fullWidth
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="productionDate" sx={labelGrayOutSx}>
          製造年月日 *
        </InputLabel>
        <DateInput
          source="productionDate"
          variant="outlined"
          label={false}
          sx={dateInputSx}
          validate={required('エラー選択してください')}
          defaultValue={formData.productionDate}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalenderIcon />
              </InputAdornment>
            ),
          }}
          onChange={(event) => handleOnChangeProductionDate(event)}
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

export default AddMachineModalBasicInfo
