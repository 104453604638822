import React from 'react'
import { useRecordContext } from 'react-admin'
import { ErrorLogRecord } from '../../types/records/error-log-record'

import styles from './ErrorLogStatusField.module.scss'

const ErrorLogStatusField = () => {
  const record = useRecordContext<ErrorLogRecord>()
  if (!record) {
    return null
  }

  return (
    <>
      {!record.isSolved && (
        <span className={`${styles.base} ${styles.not_is_solved}`}>未対応</span>
      )}
      {record.isSolved && (
        <span className={`${styles.base} ${styles.is_solved}`}>対応済み</span>
      )}
    </>
  )
}

export default ErrorLogStatusField
