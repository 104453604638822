import React, { useEffect } from 'react'

import { Grid, Typography, InputLabel, InputAdornment } from '@mui/material'
import { required, TextInput, SelectInput, DateInput } from 'react-admin'
import {
  MaintenanceLogParams,
  MaintenanceLogRecord,
} from '../../../types/records/maintenance-log-record'
import { ErrorLogRecord } from '../../../types/records/error-log-record'
import { MaintenanceTypeRecord } from '../../../types/records/maintenance-type-record'
import { MachineRecord } from '../../../types/records/machine-record'
import { ReactComponent as CalenderIcon } from '../../../assets/images/calender.svg'

import {
  labelGrayOutSx,
  textInputSx,
  selectInputSx,
  gridSx,
  dateInputSx,
} from '../../../assets/sx/form'
import { modalTitleSx } from '../../../assets/sx/modalSx'
import CustomPlaceholderSelectInput from '../../customComponent/CustomPlaceholderSelectInput'

type Props = {
  maintenanceTypes: MaintenanceTypeRecord[]
  errorLogs: ErrorLogRecord[]
  machines: MachineRecord[]
  maintenanceLog: MaintenanceLogRecord
  setHasConfirmed: (hasConfirmed: boolean) => void
  maintenanceLogParams: MaintenanceLogParams
  setMaintenanceLogParams: React.Dispatch<
    React.SetStateAction<MaintenanceLogParams>
  >
}

const EditMaintenanceLogModalInput: React.FC<Props> = ({
  maintenanceTypes,
  maintenanceLogParams,
  maintenanceLog,
  errorLogs,
  machines,
  setMaintenanceLogParams,
  setHasConfirmed,
}) => {
  useEffect(() => {
    const formattedImplementedAt = new Date(
      maintenanceLog.implementedAt,
    ).toLocaleDateString('en-CA')

    setMaintenanceLogParams({
      machineID: maintenanceLog.machine.id,
      maintenanceTypeID: maintenanceLog.maintenanceType.id,
      description: maintenanceLog.description,
      implementedAt: formattedImplementedAt,
      errorLogID: maintenanceLog.errorLogID,
    })
  }, [maintenanceLog, setMaintenanceLogParams])

  useEffect(() => {
    setHasConfirmed(false)
  }, [])

  return (
    <Grid sx={{ width: 398 }}>
      <Typography sx={modalTitleSx}>メンテナンスログを編集する 1/2</Typography>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="machineID" sx={labelGrayOutSx}>
          マシン名 *
        </InputLabel>
        <SelectInput
          source="machineID"
          variant="outlined"
          label={false}
          placeholder="入力してください"
          sx={selectInputSx}
          choices={machines}
          validate={required('エラー入力してください')}
          optionText={(record) => record.serial}
          optionValue="id"
          defaultValue={maintenanceLog.machine.id}
          disabled
          fullWidth
          onChange={(e) =>
            setMaintenanceLogParams({
              ...maintenanceLogParams,
              machineID: e.target.value,
            })
          }
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="maintenanceLogID" sx={labelGrayOutSx}>
          メンテナンス種別 *
        </InputLabel>
        <SelectInput
          source="maintenanceTypeID"
          variant="outlined"
          label={false}
          placeholder="入力してください"
          sx={selectInputSx}
          choices={maintenanceTypes}
          validate={required('入力してください')}
          defaultValue={maintenanceLog.maintenanceType.id}
          fullWidth
          onChange={(e) =>
            setMaintenanceLogParams({
              ...maintenanceLogParams,
              maintenanceTypeID: e.target.value,
            })
          }
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="description" sx={labelGrayOutSx}>
          詳細 *
        </InputLabel>
        <TextInput
          source="description"
          label={false}
          placeholder="入力してください"
          sx={textInputSx}
          validate={required('エラー入力してください')}
          fullWidth
          onChange={(e) =>
            setMaintenanceLogParams({
              ...maintenanceLogParams,
              description: e.target.value,
            })
          }
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="implementedAt" sx={labelGrayOutSx}>
          実施日 *
        </InputLabel>
        <DateInput
          label={false}
          source="implementedAt"
          fullWidth
          disabled
          sx={dateInputSx}
          validate={required('エラー選択してください')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalenderIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) =>
            setMaintenanceLogParams({
              ...maintenanceLogParams,
              implementedAt: e.target.value,
            })
          }
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="name" sx={labelGrayOutSx}>
          解決したエラー
        </InputLabel>
        <CustomPlaceholderSelectInput
          type="formInput"
          source="errorLogID"
          variant="outlined"
          label={false}
          placeholder="選択してください"
          sx={selectInputSx}
          choices={errorLogs.filter(
            (errorLog) =>
              errorLog.machine.id === maintenanceLogParams.machineID &&
              errorLog.isSolved === false,
          )}
          optionText={(record) => record.errorEvent.name}
          defaultValue={maintenanceLog.errorLogID}
          fullWidth
          onChange={(e) =>
            setMaintenanceLogParams({
              ...maintenanceLogParams,
              errorLogID: e.target.value,
            })
          }
        />
      </Grid>
    </Grid>
  )
}

export default EditMaintenanceLogModalInput
