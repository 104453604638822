export const deleteModalSx = {
  width: 'auto',
  maxHeight: '20vh',
  backgroundColor: '#FFF',
  borderRadius: 2,
  p: 3,
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '50%',
  overflow: 'hidden',
}
