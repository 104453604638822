import jsonServerProvider from 'ra-data-json-server'
import { fetchUtils } from 'react-admin'

import { User } from './types'

const httpClient = (
  url: string,
  options: fetchUtils.Options | undefined = {},
) => {
  const user: User = { authenticated: false }
  const authToken = localStorage.getItem('accessToken')
  if (authToken !== null) {
    user.authenticated = true
    user.token = `Bearer ${authToken}`
  }

  return fetchUtils.fetchJson(url, { ...options, user })
}

const baseDataProvider = jsonServerProvider(
  process.env.REACT_APP_API_ENDPOINT,
  httpClient,
)

export const dataProvider = {
  ...baseDataProvider,
  resetPasswordToken: (email: string) => {
    return fetchUtils
      .fetchJson(`${process.env.REACT_APP_API_ENDPOINT}/password-reset-token`, {
        method: 'POST',
        body: JSON.stringify({ email }),
      })
      .then((res) => {
        return {
          data: res.json,
        }
      })
  },
  resetPassword: (
    password: string,
    passwordConfirmation: string,
    passwordResetToken: string,
  ) => {
    return fetchUtils
      .fetchJson(`${process.env.REACT_APP_API_ENDPOINT}/password-reset`, {
        method: 'POST',
        body: JSON.stringify({
          password,
          passwordConfirmation,
          passwordResetToken,
        }),
      })
      .then((res) => {
        return {
          data: res.json,
        }
      })
  },
  getRecipesSetting: () => {
    const user: User = { authenticated: false }
    const authToken = localStorage.getItem('accessToken')
    if (authToken !== null) {
      user.authenticated = true
      user.token = `Bearer ${authToken}`
    }
    return fetchUtils
      .fetchJson(`${process.env.REACT_APP_API_ENDPOINT}/recipes/setting`, {
        method: 'GET',
        user,
      })
      .then((res) => {
        return {
          data: res.json,
        }
      })
  },
  getMachineLinkInfos: (id: number) => {
    const user: User = { authenticated: false }
    const authToken = localStorage.getItem('accessToken')
    if (authToken !== null) {
      user.authenticated = true
      user.token = `Bearer ${authToken}`
    }
    return fetchUtils
      .fetchJson(
        `${process.env.REACT_APP_API_ENDPOINT}/maintenance-logs/${id}/machine-part-link-infos`,
        {
          // テンプレート文字列でidを埋め込み
          method: 'GET',
          user,
        },
      )
      .then((res) => {
        return {
          data: res.json,
        }
      })
  },

  getShowMachineLinkInfos: (id: number) => {
    const user: User = { authenticated: false }
    const authToken = localStorage.getItem('accessToken')
    if (authToken !== null) {
      user.authenticated = true
      user.token = `Bearer ${authToken}`
    }
    return fetchUtils
      .fetchJson(
        `${process.env.REACT_APP_API_ENDPOINT}/maintenance-logs/${id}/show-machine-part-link-infos`,
        {
          // テンプレート文字列でidを埋め込み
          method: 'GET',
          user,
        },
      )
      .then((res) => {
        return {
          data: res.json,
        }
      })
  },
}
