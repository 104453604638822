import React, { useState, useRef, useEffect } from 'react'
import { Form, SaveButton } from 'react-admin'
import { Modal, Box, Grid, Button } from '@mui/material'
import CancelInputModal from '../CancelInputModal'
import useCancelModal from '../../../hooks/useCancelModal'
import CancelButton from '../../button/CancelButton'
import { ModalSectionType } from './ModalSectionType'
import { masterDataChipPowderMotors } from './MasterDataChipPowderMotors'
import { masterDataDustCollectingFans } from './MasterDataDustCollectingFans'
import { masterDataLinearActuators } from './MasterDataLinearActuators'
import { masterDataPushSolenoids } from './MasterDataPushSolenoids'
import { masterDataSolenoidValves } from './MasterDataSolenoidValves'
import { masterDataTubingPumps } from './MasterDataTubingPumps'
import { masterDataVacuumPumps } from './MasterDataVacuumPumps'
import {
  MachineParams,
  MachineTubingPumpLinkParams,
  MachineChipPowderMotorLinkParams,
  MachineVacuumPumpLinkParams,
  MachinePushSolenoidLinkParams,
  MachineLinearActuatorLinkParams,
  MachineDustCollectingFanLinkParams,
  MachineSolenoidValveLinkParams,
} from '../../../types/records/machine-record'
import {
  MachinePartRecord,
  MachinePartTypeRecord,
} from '../../../types/records/machine-part-record'
import AddMachineModalBasicInfo from './AddMachineModalBasicInfo'
import AddMachineModalMachinePartsInput from './AddMachineModalMachinePartsInput'
import AddMachineModalConfirm from './AddMachineModalConfirm'
import {
  saveButtonSx,
  nextButtonSx,
  modalWrapperSx,
  cancelButtonWrapperSx,
} from '../../../assets/sx/form'

type Props = {
  open: boolean
  machinePartTypes: MachinePartTypeRecord[]
  machineParts: MachinePartRecord[]
  onClose: () => void
  onSubmit: (data: MachineParams) => void
}

const AddMachineModal: React.FC<Props> = ({
  open,
  machinePartTypes,
  machineParts,
  onClose,
  onSubmit,
}) => {
  // propsから受け取った各部品のIDをparamsに変換
  const machineTubingPumpLinks: MachineTubingPumpLinkParams[] = []
  masterDataTubingPumps.forEach((tubingPump) => {
    machineTubingPumpLinks.push({
      tubingPumpId: tubingPump.id,
      machinePartId: 0,
    })
  })
  const machineChipPowderMotorLinks: MachineChipPowderMotorLinkParams[] = []
  masterDataChipPowderMotors.forEach((chipPowderMotor) => {
    machineChipPowderMotorLinks.push({
      chipPowderMotorId: chipPowderMotor.id,
      machinePartId: 0,
    })
  })
  const machineDustCollectingFanLinks: MachineDustCollectingFanLinkParams[] = []
  masterDataDustCollectingFans.forEach((dustCollectingFan) => {
    machineDustCollectingFanLinks.push({
      dustCollectingFanId: dustCollectingFan.id,
      machinePartId: 0,
    })
  })
  const machineVacuumPumpLinks: MachineVacuumPumpLinkParams[] = []
  masterDataVacuumPumps.forEach((vacuumPump) => {
    machineVacuumPumpLinks.push({
      vacuumPumpId: vacuumPump.id,
      machinePartId: 0,
    })
  })
  const machineLinearActuatorLinks: MachineLinearActuatorLinkParams[] = []
  masterDataLinearActuators.forEach((linearActuator) => {
    machineLinearActuatorLinks.push({
      linearActuatorId: linearActuator.id,
      machinePartId: 0,
    })
  })
  const machineSolenoidValveLinks: MachineSolenoidValveLinkParams[] = []
  masterDataSolenoidValves.forEach((solenoidValve) => {
    machineSolenoidValveLinks.push({
      solenoidValveId: solenoidValve.id,
      machinePartId: 0,
    })
  })
  const machinePushSolenoidLinks: MachinePushSolenoidLinkParams[] = []
  masterDataPushSolenoids.forEach((pushSolenoid) => {
    machinePushSolenoidLinks.push({
      pushSolenoidId: pushSolenoid.id,
      machinePartId: 0,
    })
  })

  const modalRef = useRef<HTMLDivElement>(null)

  const [formData, setFormData] = useState<MachineParams>({
    model: '',
    serial: '',
    simImsi: '',
    iccid: '',
    productionDate: '',
    machineTubingPumpLinks: machineTubingPumpLinks,
    machineChipPowderMotorLinks: machineChipPowderMotorLinks,
    machineDustCollectingFanLinks: machineDustCollectingFanLinks,
    machineVacuumPumpLinks: machineVacuumPumpLinks,
    machineLinearActuatorLinks: machineLinearActuatorLinks,
    machineSolenoidValveLinks: machineSolenoidValveLinks,
    machinePushSolenoidLinks: machinePushSolenoidLinks,
  })

  const [step, setStep] = useState<ModalSectionType>(ModalSectionType.BasicInfo)

  const handleOnChangeModel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, model: e.target.value })
  }

  const handleOnChangeProductionDate = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFormData({
      ...formData,
      productionDate: e.target.value,
    })
  }

  const handleOnChangeSerial = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, serial: e.target.value })
  }

  const handleOnChangeSimImsi = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, simImsi: e.target.value })
  }

  const handleOnChangeIccid = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, iccid: e.target.value })
  }

  const handleOnChangeMachineTubingPumpLink = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const newMachineTubingPumpLinks =
      formData.machineTubingPumpLinks as MachineTubingPumpLinkParams[]
    newMachineTubingPumpLinks[index].machinePartId = Number(e.target.value)
    setFormData({
      ...formData,
      machineTubingPumpLinks: newMachineTubingPumpLinks,
    })
  }

  const handleOnChangeMachineChipPowderMotorLink = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const newMachineChipPowderMotorLinks =
      formData.machineChipPowderMotorLinks as MachineChipPowderMotorLinkParams[]
    newMachineChipPowderMotorLinks[index].machinePartId = Number(e.target.value)
    setFormData({
      ...formData,
      machineChipPowderMotorLinks: newMachineChipPowderMotorLinks,
    })
  }

  const handleOnChangeMachineDustCollectingFanLink = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const newMachineDustCollectingFanLinks =
      formData.machineDustCollectingFanLinks as MachineDustCollectingFanLinkParams[]
    newMachineDustCollectingFanLinks[index].machinePartId = Number(
      e.target.value,
    )
    setFormData({
      ...formData,
      machineDustCollectingFanLinks: newMachineDustCollectingFanLinks,
    })
  }

  const handleOnChangeMachineVacuumPumpLink = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const newMachineVacuumPumpLinks =
      formData.machineVacuumPumpLinks as MachineVacuumPumpLinkParams[]
    newMachineVacuumPumpLinks[index].machinePartId = Number(e.target.value)
    setFormData({
      ...formData,
      machineVacuumPumpLinks: newMachineVacuumPumpLinks,
    })
  }

  const handleOnChangeMachineLinearActuatorLink = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const newMachineLinearActuatorLinks =
      formData.machineLinearActuatorLinks as MachineLinearActuatorLinkParams[]
    newMachineLinearActuatorLinks[index].machinePartId = Number(e.target.value)
    setFormData({
      ...formData,
      machineLinearActuatorLinks: newMachineLinearActuatorLinks,
    })
  }

  const handleOnChangeMachineSolenoidValveLink = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const newMachineSolenoidValveLinks =
      formData.machineSolenoidValveLinks as MachineSolenoidValveLinkParams[]
    newMachineSolenoidValveLinks[index].machinePartId = Number(e.target.value)
    setFormData({
      ...formData,
      machineSolenoidValveLinks: newMachineSolenoidValveLinks,
    })
  }

  const handleOnChangeMachinePushSolenoidLink = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const newMachinePushSolenoidLinks =
      formData.machinePushSolenoidLinks as MachinePushSolenoidLinkParams[]
    newMachinePushSolenoidLinks[index].machinePartId = Number(e.target.value)
    setFormData({
      ...formData,
      machinePushSolenoidLinks: newMachinePushSolenoidLinks,
    })
  }

  const canMoveToMachinePartsInput = () => {
    if (
      formData.model &&
      formData.productionDate &&
      formData.serial &&
      formData.simImsi &&
      formData.iccid &&
      /^[0-9]{19}$/.test(formData.iccid)
    ) {
      return true
    }
    return false
  }

  const canMoveToConfirm = () => {
    const machineTubingPumpLinks = formData.machineTubingPumpLinks?.filter(
      (link) => link.machinePartId !== 0,
    )
    const machineChipPowderMotorLinks =
      formData.machineChipPowderMotorLinks?.filter(
        (link) => link.machinePartId !== 0,
      )
    const machineDustCollectingFanLinks =
      formData.machineDustCollectingFanLinks?.filter(
        (link) => link.machinePartId !== 0,
      )
    const machineVacuumPumpLinks = formData.machineVacuumPumpLinks?.filter(
      (link) => link.machinePartId !== 0,
    )
    const machineLinearActuatorLinks =
      formData.machineLinearActuatorLinks?.filter(
        (link) => link.machinePartId !== 0,
      )
    const machineSolenoidValveLinks =
      formData.machineSolenoidValveLinks?.filter(
        (link) => link.machinePartId !== 0,
      )
    const machinePushSolenoidLinks = formData.machinePushSolenoidLinks?.filter(
      (link) => link.machinePartId !== 0,
    )
    if (
      machineTubingPumpLinks?.length === masterDataTubingPumps.length &&
      machineChipPowderMotorLinks?.length ===
        masterDataChipPowderMotors.length &&
      machineDustCollectingFanLinks?.length ===
        masterDataDustCollectingFans.length &&
      machineVacuumPumpLinks?.length === masterDataVacuumPumps.length &&
      machineLinearActuatorLinks?.length === masterDataLinearActuators.length &&
      machineSolenoidValveLinks?.length === masterDataSolenoidValves.length &&
      machinePushSolenoidLinks?.length === masterDataPushSolenoids.length
    ) {
      return true
    }
    return false
  }

  const moveToBasicInfoSection = () => {
    setStep(ModalSectionType.BasicInfo)
    modalRef.current?.scrollTo(0, 0)
  }

  const moveToMachinePartsInputSection = () => {
    setStep(ModalSectionType.MachinePartsInput)
    modalRef.current?.scrollTo(0, 0)
  }

  const moveToConfirmSection = () => {
    setStep(ModalSectionType.Confirm)
    modalRef.current?.scrollTo(0, 0)
  }

  const switchCancelButton = () => {
    switch (step) {
      case ModalSectionType.BasicInfo:
        handleOnCancelModalOpen()
        break
      case ModalSectionType.MachinePartsInput:
        moveToBasicInfoSection()
        break
      case ModalSectionType.Confirm:
        moveToMachinePartsInputSection()
        break
    }
  }

  const resetFormData = () => {
    setFormData({
      model: '',
      productionDate: '',
      serial: '',
      simImsi: '',
      iccid: '',
      machineTubingPumpLinks: machineTubingPumpLinks,
      machineChipPowderMotorLinks: machineChipPowderMotorLinks,
      machineDustCollectingFanLinks: machineDustCollectingFanLinks,
      machineVacuumPumpLinks: machineVacuumPumpLinks,
      machineLinearActuatorLinks: machineLinearActuatorLinks,
      machineSolenoidValveLinks: machineSolenoidValveLinks,
      machinePushSolenoidLinks: machinePushSolenoidLinks,
    })
  }

  const handleOnSubmit = () => {
    onSubmit(formData)
  }

  useEffect(() => {
    if (!open) {
      moveToBasicInfoSection()
      resetFormData()
    }
  }, [open])

  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box sx={modalWrapperSx} ref={modalRef}>
          <Form onSubmit={handleOnSubmit} mode="onBlur">
            {step === ModalSectionType.BasicInfo && (
              <AddMachineModalBasicInfo
                formData={formData}
                handleOnChangeModel={handleOnChangeModel}
                handleOnChangeProductionDate={handleOnChangeProductionDate}
                handleOnChangeSerial={handleOnChangeSerial}
                handleOnChangeSimImsi={handleOnChangeSimImsi}
                handleOnChangeIccid={handleOnChangeIccid}
              />
            )}
            {step === ModalSectionType.MachinePartsInput && (
              <AddMachineModalMachinePartsInput
                machinePartTypes={machinePartTypes}
                machineParts={machineParts}
                handleOnChangeMachineTubingPumpLink={
                  handleOnChangeMachineTubingPumpLink
                }
                handleOnChangeMachineChipPowderMotorLink={
                  handleOnChangeMachineChipPowderMotorLink
                }
                handleOnChangeMachineDustCollectingFanLink={
                  handleOnChangeMachineDustCollectingFanLink
                }
                handleOnChangeMachineVacuumPumpLink={
                  handleOnChangeMachineVacuumPumpLink
                }
                handleOnChangeMachineLinearActuatorLink={
                  handleOnChangeMachineLinearActuatorLink
                }
                handleOnChangeMachineSolenoidValveLink={
                  handleOnChangeMachineSolenoidValveLink
                }
                handleOnChangeMachinePushSolenoidLink={
                  handleOnChangeMachinePushSolenoidLink
                }
              />
            )}
            {step === ModalSectionType.Confirm && (
              <AddMachineModalConfirm
                formData={formData}
                machineParts={machineParts}
              />
            )}
            <Grid container direction="row" justifyContent="flex-end">
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton
                  onClick={() => {
                    switchCancelButton()
                  }}>
                  {step === ModalSectionType.BasicInfo ? 'キャンセル' : '戻る'}
                </CancelButton>
              </Box>
              {step === ModalSectionType.BasicInfo && (
                <Button
                  sx={nextButtonSx}
                  disabled={!canMoveToMachinePartsInput()}
                  onClick={() => moveToMachinePartsInputSection()}>
                  次へ
                </Button>
              )}
              {step === ModalSectionType.MachinePartsInput && (
                <Button
                  sx={saveButtonSx}
                  disabled={!canMoveToConfirm()}
                  onClick={() => moveToConfirmSection()}>
                  次へ
                </Button>
              )}
              {step === ModalSectionType.Confirm && (
                <SaveButton label="登録する" sx={saveButtonSx} />
              )}
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default AddMachineModal
