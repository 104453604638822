import React, { useEffect, useState, useRef } from 'react'

import { Grid, Modal, Box, Button } from '@mui/material'
import CancelInputModal from '../CancelInputModal'
import useCancelModal from '../../../hooks/useCancelModal'
import { Form, SaveButton } from 'react-admin'
import CancelButton from '../../button/CancelButton'

import { ModalSectionType } from './ModalSectionType'
import { saveButtonSx, nextButtonSx } from '../../../assets/sx/form'

import MaintenanceLogModalConfirm from './MaintenanceLogConfirm'

import {
  MaintenanceLogParams,
  MaintenanceLogRecord,
} from '../../../types/records/maintenance-log-record'
import { MaintenanceTypeRecord } from '../../../types/records/maintenance-type-record'

import AddMaintenanceLogModalInput from './AddMaintenanceLogModalInput'
import AddMaintenanceLogModalReplacementPartsInput from './AddMaintenanceLogReplacementModalInput'
import { ErrorLogRecord } from '../../../types/records/error-log-record'
import { MachineRecord } from '../../../types/records/machine-record'
import { MachinePartRecord } from '../../../types/records/machine-part-record'
import { MachineTubingPumpLinkParam } from '../../../types/records/machine-tubing-pump-link-record'
import { MachineChipPowderMotorLinkParam } from '../../../types/records/machine-chip-powder-motor-link-record'
import { MachineVacuumPumpLinkParam } from '../../../types/records/machine-vacuum-pump-link-record'
import { MachinePushSolenoidLinkParam } from '../../../types/records/machine-push-solenoid-link-record'
import { MachineLinearActuatorLinkParam } from '../../../types/records/machine-linear-actuator-link-record'
import { MachineDustCollectingFanLinkParam } from '../../../types/records/machine-dust-collecting-fan-link-record'
import { MachineSolenoidValveLinkParam } from '../../../types/records/machine-solenoid-valve-link-record'
import { cancelButtonWrapperSx } from '../../../assets/sx/form'

type Props = {
  errorLogs: ErrorLogRecord[]
  maintenanceTypes: MaintenanceTypeRecord[]
  machines: MachineRecord[]
  machineParts: MachinePartRecord[]
  getLatestMaintenanceLog: (
    machineID: number | undefined,
  ) => MaintenanceLogRecord | null
  open: boolean
  onClose: () => void
  onSubmit: (data: MaintenanceLogParams) => void
}

const AddMaintenanceLogModal: React.FC<Props> = ({
  getLatestMaintenanceLog,
  open,
  onClose,
  onSubmit,
  maintenanceTypes,
  errorLogs,
  machines,
  machineParts,
}) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const [previewMaintenanceLog, setPreviewMaintenanceLog] =
    useState<MaintenanceLogParams>()

  const [maintenanceLogParams, setMaintenanceLogParams] =
    useState<MaintenanceLogParams>({})

  const [hasConfirmed, setHasConfirmed] = useState<boolean>(false)
  const [isEditMode] = useState<boolean>(false)

  // paramsの初期化処理
  useEffect(() => {
    if (!open) {
      setModalSection(ModalSectionType.MaintenanceLogInput)
      setMaintenanceLogParams(initialMaintenanceLogParams)
    }
  }, [open])

  const validateDateAfterLatestMaintenance = (inputDate: Date) => {
    const latestLog: MaintenanceLogRecord | null = getLatestMaintenanceLog(
      maintenanceLogParams.machineID,
    )

    if (!latestLog?.implementedAt) return undefined // 初めてのログの場合は常にtrue

    const inputDateTime = new Date(inputDate).getTime()
    const latestDateTime = new Date(latestLog.implementedAt).getTime()

    if (inputDateTime >= latestDateTime) {
      return undefined // 入力日付は問題なし
    }

    // 日付を '7/22' のような形式に変換
    const latestDate = new Date(latestLog.implementedAt)
    const formattedLatestDate = `${
      latestDate.getMonth() + 1
    }/${latestDate.getDate()}`

    // 最新のメンテナンス日をエラーメッセージに含める
    return `最新のメンテナンス日 (${formattedLatestDate}) 以降の日付を選択してください`
  }

  const canMoveToReplacementPartsSection = () => {
    if (
      maintenanceLogParams.machineID &&
      maintenanceLogParams.maintenanceTypeID &&
      maintenanceLogParams.description &&
      maintenanceLogParams.implementedAt &&
      !validateDateAfterLatestMaintenance(
        new Date(maintenanceLogParams.implementedAt),
      )
    ) {
      return true
    }
    return false
  }

  const initialMaintenanceLogParams = {
    maintenanceTypeID: undefined,
    machineID: undefined,
    description: undefined,
    implementedAt: undefined,
    errorLogID: undefined,
  }

  const filterMaintenanceLogParams = {
    ...maintenanceLogParams,
    newMachineTubingPumpLinks:
      // machinePartIDが0のものは排除する
      maintenanceLogParams.newMachineTubingPumpLinks?.filter(
        (link: MachineTubingPumpLinkParam) => link.machinePartID !== 0,
      ),
    newMachineChipPowderMotorLinks:
      maintenanceLogParams.newMachineChipPowderMotorLinks?.filter(
        (link: MachineChipPowderMotorLinkParam) => link.machinePartID !== 0,
      ),
    newMachineVacuumPumpLinks:
      maintenanceLogParams.newMachineVacuumPumpLinks?.filter(
        (link: MachineVacuumPumpLinkParam) => link.machinePartID !== 0,
      ),
    newMachinePushSolenoidLinks:
      maintenanceLogParams.newMachinePushSolenoidLinks?.filter(
        (link: MachinePushSolenoidLinkParam) => link.machinePartID !== 0,
      ),
    newMachineSolenoidValveLinks:
      maintenanceLogParams.newMachineSolenoidValveLinks?.filter(
        (link: MachineSolenoidValveLinkParam) => link.machinePartID !== 0,
      ),
    newMachineLinearActuatorLinks:
      maintenanceLogParams.newMachineLinearActuatorLinks?.filter(
        (link: MachineLinearActuatorLinkParam) => link.machinePartID !== 0,
      ),
    newMachineDustCollectingFanLinks:
      maintenanceLogParams.newMachineDustCollectingFanLinks?.filter(
        (link: MachineDustCollectingFanLinkParam) => link.machinePartID !== 0,
      ),

    // ReplacementLogsでfalsyなオブジェクトは排除する
    newTubingPumpReplacementLogs:
      maintenanceLogParams.newTubingPumpReplacementLogs?.filter(Boolean),

    newChipPowderMotorReplacementLogs:
      maintenanceLogParams.newChipPowderMotorReplacementLogs?.filter(Boolean),

    newVacuumPumpReplacementLogs:
      maintenanceLogParams.newVacuumPumpReplacementLogs?.filter(Boolean),

    newPushSolenoidReplacementLogs:
      maintenanceLogParams.newPushSolenoidReplacementLogs?.filter(Boolean),

    newSolenoidValveReplacementLogs:
      maintenanceLogParams.newSolenoidValveReplacementLogs?.filter(Boolean),

    newLinearActuatorReplacementLogs:
      maintenanceLogParams.newLinearActuatorReplacementLogs?.filter(Boolean),

    newDustCollectingFanReplacementLogs:
      maintenanceLogParams.newDustCollectingFanReplacementLogs?.filter(Boolean),
  }

  const onCreateSubmit = () => {
    onSubmit(filterMaintenanceLogParams)
  }

  // 次のセクションに移動する処理
  const [modalSection, setModalSection] = useState<ModalSectionType>(
    ModalSectionType.MaintenanceLogInput,
  )
  const moveToMaintenanceLogInputSection = () => {
    setModalSection(ModalSectionType.MaintenanceLogInput)
    modalRef.current?.scrollTo(0, 0)
  }
  const moveToReplacementPartsInput = () => {
    setModalSection(ModalSectionType.ReplacementPartsInput)
    modalRef.current?.scrollTo(0, 0)
  }
  const moveToConfirmSection = () => {
    const filterMaintenanceLogParams = { ...maintenanceLogParams }
    setModalSection(ModalSectionType.Confirm)
    setPreviewMaintenanceLog(filterMaintenanceLogParams)
    modalRef.current?.scrollTo(0, 0)
  }

  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box
          sx={{
            maxHeight: '80vh',
            backgroundColor: '#FFF',
            borderRadius: 2,
            p: 3,
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%',
            overflow: 'scroll',
          }}
          ref={modalRef}>
          <Form onSubmit={onCreateSubmit} mode="onBlur">
            {modalSection === ModalSectionType.MaintenanceLogInput ? (
              <AddMaintenanceLogModalInput
                maintenanceLogParams={maintenanceLogParams}
                maintenanceTypes={maintenanceTypes}
                errorLogs={errorLogs}
                machines={machines}
                validateDateAfterLatestMaintenance={
                  validateDateAfterLatestMaintenance
                }
                setMaintenanceLogParams={setMaintenanceLogParams}
              />
            ) : (
              <></>
            )}
            {modalSection === ModalSectionType.ReplacementPartsInput ? (
              <AddMaintenanceLogModalReplacementPartsInput
                maintenanceLogParams={maintenanceLogParams}
                machineParts={machineParts}
                machines={machines}
                hasConfirmed={hasConfirmed}
                setMaintenanceLogParams={setMaintenanceLogParams}
              />
            ) : (
              <></>
            )}
            {modalSection === ModalSectionType.Confirm ? (
              <MaintenanceLogModalConfirm
                maintenanceLog={previewMaintenanceLog as MaintenanceLogParams}
                maintenanceTypes={maintenanceTypes}
                errorLogs={errorLogs}
                machineParts={machineParts}
                machines={machines}
                setHasConfirmed={setHasConfirmed}
                isEditMode={isEditMode}
              />
            ) : (
              <></>
            )}
            <Grid container direction="row" justifyContent="flex-end">
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton
                  onClick={() => {
                    switch (modalSection) {
                      case ModalSectionType.MaintenanceLogInput:
                        handleOnCancelModalOpen()
                        break
                      case ModalSectionType.ReplacementPartsInput:
                        moveToMaintenanceLogInputSection()
                        break
                      case ModalSectionType.Confirm:
                        moveToReplacementPartsInput()
                    }
                  }}>
                  {modalSection == ModalSectionType.MaintenanceLogInput
                    ? 'キャンセル'
                    : '戻る'}
                </CancelButton>
              </Box>
              {modalSection == ModalSectionType.MaintenanceLogInput && (
                <Button
                  sx={nextButtonSx}
                  onClick={() => {
                    moveToReplacementPartsInput()
                  }}
                  disabled={!canMoveToReplacementPartsSection()}>
                  次へ
                </Button>
              )}
              {modalSection == ModalSectionType.ReplacementPartsInput && (
                <Button
                  sx={nextButtonSx}
                  onClick={() => {
                    moveToConfirmSection()
                  }}>
                  次へ
                </Button>
              )}
              {modalSection == ModalSectionType.Confirm && (
                <SaveButton label="登録する" sx={saveButtonSx} />
              )}
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default AddMaintenanceLogModal
