import React, { MouseEvent, useState } from 'react'
import { format, parseISO } from 'date-fns'
import { useGetList } from 'react-admin'
import {
  TextField,
  FunctionField,
  useDataProvider,
  useRefresh,
  useNotify,
} from 'react-admin'
import ListDatagrid from '../components/ListDatagrid'
import { InputAdornment } from '@mui/material'
import { ReactComponent as SearchIcon } from '../assets/images/search.svg'
import { ReactComponent as PencilIcon } from '../assets/images/pencil.svg'
import { ReactComponent as TrashIcon } from '../assets/images/trash.svg'
import { ReactComponent as CalenderIcon } from '../assets/images/calender.svg'
import { ReactComponent as DetailsIcon } from '../assets/images/details.svg'

import { Grid } from '@mui/material'

import AddButton from '../components/button/AddButton'
import IconButton from '../components/button/IconButton'

import { TextInput, DateInput } from 'react-admin'
import {
  MaintenanceLogRecord,
  MaintenanceLogParams,
} from '../types/records/maintenance-log-record'
import { StartDateInputSx, EndDateInputSx, TextInputSx } from './FilterSx'

import {
  useAuthorize,
  useNotifyForbidden,
  useNotifyEditMaintenanceLogRestriction,
  useNotifyDeleteMaintenanceLogRestriction,
} from '../hooks/authorization'
import { MaintenanceTypeRecord } from '../types/records/maintenance-type-record'
import { ErrorLogRecord } from '../types/records/error-log-record'

import AddMaintenanceLogModal from '../components/modal/MaintenanceLog/AddMaintenanceLogModal'
import { MachineRecord } from '../types/records/machine-record'
import { MachinePartRecord } from '../types/records/machine-part-record'
import EditMaintenanceLogModal from '../components/modal/MaintenanceLog/EditMaintenanceLogModal'
import DeleteMaintenanceLogModal from '../components/modal/MaintenanceLog/DeleteMaintenanceLogModal'
import ShowMaintenanceLogModal from '../components/modal/MaintenanceLog/ShowMaintenanceLogModal'
import styles from './MaintenanceLogList.module.scss'
import { firstFieldSx } from '../assets/sx/field'

const MaintenanceLogList: React.FC = () => {
  const filters = [
    <TextInput
      key="store-name"
      label={false}
      source="storeName"
      placeholder="店舗名で検索"
      alwaysOn
      fullWidth
      resettable
      sx={TextInputSx}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />,
    <TextInput
      key="machineSerial"
      label={false}
      source="machineSerial"
      placeholder="マシン シリアルで検索"
      alwaysOn
      fullWidth
      resettable
      sx={TextInputSx}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />,
    <DateInput
      key="maintenancePerformedStartAt"
      label={false}
      source="maintenancePerformedStartAt"
      alwaysOn
      fullWidth
      sx={StartDateInputSx}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CalenderIcon />
          </InputAdornment>
        ),
      }}
    />,
    <DateInput
      key="maintenancePerformedEndAt"
      label={false}
      source="maintenancePerformedEndAt"
      alwaysOn
      fullWidth
      sx={EndDateInputSx}
    />,
  ]

  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  const maintenanceType = useGetList('maintenance-types')
  const errorLog = useGetList('error-logs')
  const machine = useGetList('machines')
  const machinePart = useGetList('machine-parts')
  const maintenanceLog = useGetList('maintenance-logs')

  const [targetMaintenanceLog, setTargetMaintenanceLog] =
    useState<MaintenanceLogRecord>()

  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false)
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [showModalOpen, setShowModalOpen] = useState<boolean>(false)

  const canCreateMaintenanceLog = useAuthorize('maintenanceLog', 'create')
  const canUpdateMaintenanceLog = useAuthorize('maintenanceLog', 'update')
  const canDeleteMaintenanceLog = useAuthorize('maintenanceLog', 'delete')
  const notifyForbidden = useNotifyForbidden()
  const notifyEditRestriction = useNotifyEditMaintenanceLogRestriction()
  const notifyDeleteRestriction = useNotifyDeleteMaintenanceLogRestriction()

  const openCreateModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!canCreateMaintenanceLog) {
      notifyForbidden()
      return
    }

    setCreateModalOpen(true)
  }

  const getLatestMaintenanceLog = (machineID: number | undefined) => {
    if (typeof machineID === 'undefined') return null

    const filteredLogs = maintenanceLog.data?.filter(
      (log) => log.machine.id === machineID,
    )
    if (!filteredLogs?.length) return null

    return filteredLogs.reduce((prev, current) => {
      const prevDate = new Date(prev.implementedAt)
      const currentDate = new Date(current.implementedAt)

      if (prevDate > currentDate) {
        return prev
      } else if (prevDate < currentDate) {
        return current
      } else {
        // 日時が同じ場合、IDを比較
        return prev.id > current.id ? prev : current
      }
    })
  }

  const isLatestMaintenanceLog = (
    log: MaintenanceLogRecord | undefined,
  ): boolean => {
    if (!log) return false

    const latestLog = getLatestMaintenanceLog(log.machine.id)
    return log.id === latestLog?.id
  }

  const openEditModal = (
    e: MouseEvent<HTMLElement>,
    targetMaintenanceLog: MaintenanceLogRecord | undefined,
  ) => {
    e.preventDefault()

    if (!canUpdateMaintenanceLog) {
      notifyForbidden()
      return
    }

    if (!isLatestMaintenanceLog(targetMaintenanceLog)) {
      notifyEditRestriction()
      return
    }

    setEditModalOpen(true)
    setTargetMaintenanceLog(targetMaintenanceLog)
  }

  const openDeleteModal = (
    e: MouseEvent<HTMLElement>,
    targetMaintenanceLog: MaintenanceLogRecord | undefined,
  ) => {
    e.preventDefault()
    if (!canDeleteMaintenanceLog) {
      notifyForbidden()
      return
    }

    if (!isLatestMaintenanceLog(targetMaintenanceLog)) {
      notifyDeleteRestriction()
      return
    }

    setDeleteModalOpen(true)
    setTargetMaintenanceLog(targetMaintenanceLog)
  }

  const openShowModal = (
    e: MouseEvent<HTMLElement>,
    targetMaintenanceLog: MaintenanceLogRecord | undefined,
  ) => {
    e.preventDefault()
    setShowModalOpen(true)
    setTargetMaintenanceLog(targetMaintenanceLog)
  }

  const onCreateSubmit = (data: MaintenanceLogParams) => {
    dataProvider
      .create('maintenance-logs', { data })
      .then(() => {
        notify('メンテナンスログを追加しました')
        setCreateModalOpen(false)
        refresh()
      })
      .catch(() => {
        notify('メンテナンスログの追加に失敗しました')
      })
  }
  const findStoreNameById = (id: number): string => {
    const machineRecord = machine?.data?.find((m) => m.id === id)
    return machineRecord?.store?.name ?? 'N/A'
  }

  const onUpdateSubmit = (
    id: number,
    data: MaintenanceLogParams,
    previousData: MaintenanceLogRecord,
  ) => {
    dataProvider
      .update('maintenance-logs', {
        id: id,
        data: data,
        previousData: previousData,
      })
      .then(() => {
        notify('メンテナンスログを更新しました')
        setEditModalOpen(false)
        refresh()
      })
      .catch(() => {
        notify('メンテナンスログの更新に失敗しました')
      })
  }

  const onDeleteSubmit = (maintenanceLog: MaintenanceLogRecord) => {
    dataProvider
      .delete('maintenance-logs', {
        id: maintenanceLog.id,
        previousData: maintenanceLog,
      })
      .then(() => {
        notify('メンテナンスログを削除しました')
        setDeleteModalOpen(false)
        refresh()
      })
      .catch(() => {
        notify('メンテナンスログの削除に失敗しました')
      })
  }

  const closeCreateModal = () => {
    setCreateModalOpen(false)
  }

  const closeEditModal = () => {
    setEditModalOpen(false)
  }

  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const closeShowModal = () => {
    setShowModalOpen(false)
  }

  const settingButtonItems = [
    { tooltipLabel: '詳細', icon: <DetailsIcon />, onClick: openShowModal },
    { tooltipLabel: '編集', icon: <PencilIcon />, onClick: openEditModal },
    { tooltipLabel: '削除', icon: <TrashIcon />, onClick: openDeleteModal },
  ]

  const modals = []

  if (
    maintenanceType.data &&
    machine.data &&
    errorLog.data &&
    machinePart.data
  ) {
    modals.push(
      <AddMaintenanceLogModal
        key="addMaintenanceLogModal"
        getLatestMaintenanceLog={getLatestMaintenanceLog}
        maintenanceTypes={maintenanceType.data as MaintenanceTypeRecord[]}
        errorLogs={errorLog.data as ErrorLogRecord[]}
        machines={machine.data as MachineRecord[]}
        machineParts={machinePart.data as MachinePartRecord[]}
        open={createModalOpen}
        onClose={closeCreateModal}
        onSubmit={onCreateSubmit}
      />,
      <EditMaintenanceLogModal
        key="editMaintenanceLogModal"
        maintenanceTypes={maintenanceType.data as MaintenanceTypeRecord[]}
        maintenanceLog={targetMaintenanceLog as MaintenanceLogRecord}
        errorLogs={errorLog.data as ErrorLogRecord[]}
        machines={machine.data as MachineRecord[]}
        machineParts={machinePart.data as MachinePartRecord[]}
        onClose={closeEditModal}
        onSubmit={onUpdateSubmit}
        open={editModalOpen}
      />,
      <DeleteMaintenanceLogModal
        key="deleteMaintenanceLogModal"
        maintenanceLog={targetMaintenanceLog}
        open={deleteModalOpen}
        onClose={closeDeleteModal}
        onSubmit={onDeleteSubmit}
      />,
      <ShowMaintenanceLogModal
        key="showMaintenanceLogModal"
        maintenanceLog={targetMaintenanceLog as MaintenanceLogRecord}
        maintenanceTypes={maintenanceType.data as MaintenanceTypeRecord[]}
        errorLogs={errorLog.data as ErrorLogRecord[]}
        machines={machine.data as MachineRecord[]}
        machineParts={machinePart.data as MachinePartRecord[]}
        open={showModalOpen}
        onClose={closeShowModal}
      />,
    )
  }

  return (
    <>
      <ListDatagrid
        title="メンテナンスログマスター"
        filters={filters}
        addButton={
          <AddButton onClick={openCreateModal}>
            メンテナンスログを追加する
          </AddButton>
        }
        modals={modals}>
        <TextField
          source="id"
          label="ID"
          sortable={false}
          headerClassName={styles.maintenanceLogID}
          sx={firstFieldSx}
        />
        <TextField
          source="maintenanceType.name"
          label="メンテナンス種別"
          sortable={false}
          headerClassName={styles.maintenanceLogTypeNameField}
        />
        <FunctionField
          source="machineId"
          label="店舗名"
          sortable={false}
          render={(record: MaintenanceLogRecord) => {
            return findStoreNameById(record.machine.id)
          }}
          headerClassName={styles.maintenanceLogStoreNameField}
        />
        <TextField
          source="machine.serial"
          label="マシン シリアル"
          sortable={false}
          headerClassName={styles.maintenanceLogMachineSerialField}
        />
        <FunctionField
          render={(record: MaintenanceLogRecord) =>
            record && format(parseISO(record.implementedAt), 'yyyy/MM/dd HH:mm')
          }
          label="実施日"
          sortable={false}
          headerClassName={styles.maintenanceLogImplementedAtField}
        />
        <FunctionField
          sortable={false}
          render={(record: MaintenanceLogRecord) => {
            return (
              <Grid container display="flex" justifyContent="right">
                {settingButtonItems.map((item, index) => {
                  return (
                    <IconButton<MaintenanceLogRecord>
                      key={`iconButton_${index}`}
                      tooltipLabel={item.tooltipLabel}
                      onClick={item.onClick}
                      icon={item.icon}
                      record={record}
                    />
                  )
                })}
              </Grid>
            )
          }}
          headerClassName={styles.maintenanceLogIconButtonsField}
        />
      </ListDatagrid>
    </>
  )
}

export default MaintenanceLogList
