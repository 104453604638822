import { VacuumPumpRecord } from '../../../types/records/vacuum-pump-record'

export const masterDataVacuumPumps: VacuumPumpRecord[] = [
  {
    id: 1,
    name: '左真空ポンプ',
  },
  {
    id: 2,
    name: '右真空ポンプ',
  },
]
