import React from 'react'
import {
  MachineRecord,
  MachineParams,
} from '../../../types/records/machine-record'
import {
  Grid,
  Typography,
  Modal,
  Box,
  InputLabel,
  InputAdornment,
} from '@mui/material'
import CancelInputModal from '../CancelInputModal'
import useCancelModal from '../../../hooks/useCancelModal'
import { Form, required, SaveButton, TextInput, DateInput } from 'react-admin'
import CancelButton from '../../button/CancelButton'
import {
  gridSx,
  labelGrayOutSx,
  textInputSx,
  dateInputSx,
  saveButtonSx,
  cancelButtonWrapperSx,
} from '../../../assets/sx/form'
import { modalTitleSx } from '../../../assets/sx/modalSx'
import { ReactComponent as CalenderIcon } from '../../../assets/images/calender.svg'

type Props = {
  open: boolean
  machine: MachineRecord | undefined
  onClose: () => void
  onSubmit: (
    id: number,
    data: MachineParams,
    previousData: MachineRecord,
  ) => void
}

const EditMachineModal: React.FC<Props> = ({
  open,
  machine,
  onClose,
  onSubmit,
}) => {
  const onUpdateSubmit = (data: MachineParams) => {
    if (!machine || !machine.id) {
      return null
    }
    onSubmit(machine.id, data, machine)
  }

  const validateIccid = (iccidInput: string) => {
    if (!/^[0-9]{19}$/.test(iccidInput)) {
      return 'エラー半角数字19文字で入力してください'
    }
    return undefined
  }

  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box
          sx={{
            width: 398,
            MaxHeight: '80vh',
            backgroundColor: '#FFF',
            borderRadius: 2,
            p: 3,
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%',
            overflow: 'scroll',
          }}>
          <Typography sx={modalTitleSx}>マシンを編集する</Typography>
          <Form
            onSubmit={onUpdateSubmit}
            record={machine}
            defaultValues={machine}>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="serial" sx={labelGrayOutSx}>
                マシン シリアル *
              </InputLabel>
              <TextInput
                source="serial"
                variant="outlined"
                placeholder="入力してください"
                label={false}
                sx={textInputSx}
                validate={required('エラー入力してください')}
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="model" sx={labelGrayOutSx}>
                機種コード *
              </InputLabel>
              <TextInput
                source="model"
                variant="outlined"
                placeholder="入力してください"
                label={false}
                sx={textInputSx}
                validate={required('エラー入力してください')}
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="simImsi" sx={labelGrayOutSx}>
                SIM IMSI *
              </InputLabel>
              <TextInput
                source="simImsi"
                variant="outlined"
                placeholder="入力してください"
                label={false}
                sx={textInputSx}
                validate={required('エラー入力してください')}
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="iccid" sx={labelGrayOutSx}>
                SIM ICCID *
              </InputLabel>
              <TextInput
                source="iccid"
                variant="outlined"
                placeholder="入力してください"
                label={false}
                sx={textInputSx}
                validate={[required('エラー入力してください'), validateIccid]}
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="productionDate" sx={labelGrayOutSx}>
                製造年月日 *
              </InputLabel>
              <DateInput
                source="productionDate"
                variant="outlined"
                label={false}
                sx={dateInputSx}
                validate={required('エラー選択してください')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalenderIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid container direction="row" justifyContent="flex-end">
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton onClick={handleOnCancelModalOpen}>
                  キャンセル
                </CancelButton>
              </Box>
              <SaveButton label="更新する" sx={saveButtonSx} />
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default EditMachineModal
