import { SolenoidValveRecord } from '../../../types/records/solenoid-valve-record'

export const masterDataSolenoidValves: SolenoidValveRecord[] = [
  {
    id: 1,
    name: '左ソレノイドバルブ',
  },
  {
    id: 2,
    name: '右ソレノイドバルブ',
  },
]
