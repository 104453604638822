import React from 'react'
import { Form, TextInput, SaveButton, required } from 'react-admin'
import { Box, Grid, InputLabel, Modal, Typography } from '@mui/material'
import CancelInputModal from './CancelInputModal'
import useCancelModal from '../../hooks/useCancelModal'
import {
  StaffParams,
  AuthStaffGroupRecord,
} from '../../types/records/staff-record'
import {
  modalWrapperSx,
  gridSx,
  labelGrayOutSx,
  textInputSx,
  selectInputSx,
  cancelButtonWrapperSx,
  saveButtonSx,
} from '../../assets/sx/form'
import { modalTitleSx } from '../../assets/sx/modalSx'
import CancelButton from '../button/CancelButton'
import CustomPlaceholderSelectInput from '../customComponent/CustomPlaceholderSelectInput'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: (data: StaffParams) => void
  authStaffGroups: AuthStaffGroupRecord[]
}

const AddStaffModal: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  authStaffGroups,
}) => {
  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box sx={modalWrapperSx}>
          <Typography sx={modalTitleSx}>ユーザーを追加する</Typography>
          <Form onSubmit={onSubmit}>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="name" sx={labelGrayOutSx}>
                ユーザー名 *
              </InputLabel>
              <TextInput
                source="name"
                variant="outlined"
                label={false}
                sx={textInputSx}
                validate={required('エラー入力してください')}
                placeholder="入力してください"
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="email" sx={labelGrayOutSx}>
                メールアドレス *
              </InputLabel>
              <TextInput
                source="email"
                variant="outlined"
                label={false}
                sx={textInputSx}
                validate={[required('エラー入力してください')]}
                placeholder="入力してください"
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="department" sx={labelGrayOutSx}>
                部署 *
              </InputLabel>
              <TextInput
                source="department"
                variant="outlined"
                label={false}
                sx={textInputSx}
                validate={required('エラー入力してください')}
                placeholder="入力してください"
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="email" sx={labelGrayOutSx}>
                権限 *
              </InputLabel>
              <CustomPlaceholderSelectInput
                type="formInput"
                placeholder="選択してください"
                source="authStaffGroupID"
                variant="outlined"
                label={false}
                choices={authStaffGroups}
                validate={required('エラー選択してください')}
                sx={selectInputSx}
                fullWidth
              />
            </Grid>
            <Grid container direction="row" justifyContent="flex-end">
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton onClick={handleOnCancelModalOpen}>
                  キャンセル
                </CancelButton>
              </Box>
              <SaveButton sx={saveButtonSx} label="追加する" />
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default AddStaffModal
