import React from 'react'

import { Grid, Typography, InputLabel, InputAdornment } from '@mui/material'
import { required, TextInput, DateInput } from 'react-admin'
import { MaintenanceLogParams } from '../../../types/records/maintenance-log-record'
import { ErrorLogRecord } from '../../../types/records/error-log-record'
import { MaintenanceTypeRecord } from '../../../types/records/maintenance-type-record'
import { MachineRecord } from '../../../types/records/machine-record'
import CustomPlaceholderSelectInput from '../../customComponent/CustomPlaceholderSelectInput'
import { ReactComponent as CalenderIcon } from '../../../assets/images/calender.svg'

import {
  labelGrayOutSx,
  textInputSx,
  selectInputSx,
  gridSx,
  dateInputSx,
} from '../../../assets/sx/form'
import { modalTitleSx } from '../../../assets/sx/modalSx'

type Props = {
  maintenanceTypes: MaintenanceTypeRecord[]
  errorLogs: ErrorLogRecord[]
  machines: MachineRecord[]
  maintenanceLogParams: MaintenanceLogParams
  setMaintenanceLogParams: React.Dispatch<
    React.SetStateAction<MaintenanceLogParams>
  >
  validateDateAfterLatestMaintenance: (
    inputDate: Date,
    machineID: number,
  ) => string | undefined
}

const AddMaintenanceLogModalInput: React.FC<Props> = ({
  maintenanceTypes,
  maintenanceLogParams,
  errorLogs,
  machines,
  validateDateAfterLatestMaintenance,
  setMaintenanceLogParams,
}) => {
  return (
    <Grid width={398}>
      <Typography sx={modalTitleSx}>メンテナンスログを追加する 1/2</Typography>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="machineID" sx={labelGrayOutSx}>
          マシン シリアル *
        </InputLabel>
        <CustomPlaceholderSelectInput
          type="formInput"
          source="machineID"
          variant="outlined"
          label={false}
          placeholder="選択してください"
          sx={selectInputSx}
          choices={machines}
          validate={required('エラー選択してください')}
          optionText={(record) => record.serial}
          optionValue="id"
          fullWidth
          onChange={(e) =>
            setMaintenanceLogParams({
              ...maintenanceLogParams,
              machineID: e.target.value,
            })
          }
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="maintenanceLogID" sx={labelGrayOutSx}>
          メンテナンス種別 *
        </InputLabel>
        <CustomPlaceholderSelectInput
          type="formInput"
          source="maintenanceLogID"
          variant="outlined"
          label={false}
          placeholder="選択してください"
          sx={selectInputSx}
          choices={maintenanceTypes}
          validate={required('エラー選択してください')}
          fullWidth
          onChange={(e) =>
            setMaintenanceLogParams({
              ...maintenanceLogParams,
              maintenanceTypeID: e.target.value,
            })
          }
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="description" sx={labelGrayOutSx}>
          詳細 *
        </InputLabel>
        <TextInput
          source="description"
          label={false}
          placeholder="入力してください"
          sx={textInputSx}
          validate={required('エラー入力してください')}
          fullWidth
          onChange={(e) =>
            setMaintenanceLogParams({
              ...maintenanceLogParams,
              description: e.target.value,
            })
          }
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="implementedAt" sx={labelGrayOutSx}>
          実施日 *
        </InputLabel>
        <DateInput
          label={false}
          source="implementedAt"
          fullWidth
          sx={dateInputSx}
          validate={[
            required('選択してください'),
            validateDateAfterLatestMaintenance,
          ]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalenderIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) =>
            setMaintenanceLogParams({
              ...maintenanceLogParams,
              implementedAt: e.target.value,
            })
          }
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="name" sx={labelGrayOutSx}>
          解決したエラー
        </InputLabel>
        <CustomPlaceholderSelectInput
          type="formInput"
          source="errorLogID"
          variant="outlined"
          label={false}
          placeholder="選択してください"
          sx={selectInputSx}
          choices={errorLogs.filter(
            (errorLog) =>
              errorLog.machine.id === maintenanceLogParams.machineID &&
              errorLog.isSolved === false,
          )}
          optionText={(record) => record.errorEvent.name}
          fullWidth
          onChange={(e) =>
            setMaintenanceLogParams({
              ...maintenanceLogParams,
              errorLogID: e.target.value,
            })
          }
        />
      </Grid>
    </Grid>
  )
}

export default AddMaintenanceLogModalInput
