import React from 'react'
import { Grid, Typography, Modal, Box } from '@mui/material'
import CancelButton from '../../button/CancelButton'
import DeleteButton from '../../button/DeleteButton'
import { MaintenanceLogRecord } from '../../../types/records/maintenance-log-record'
import { cancelButtonWrapperSx } from '../../../assets/sx/form'
import { deleteModalSx } from '../../../pages/ModalSx'

type Props = {
  maintenanceLog: MaintenanceLogRecord | undefined
  open: boolean
  onClose: () => void
  onSubmit: (data: MaintenanceLogRecord) => void
}

const DeleteMaintenanceLogModal: React.FC<Props> = ({
  maintenanceLog,
  open,
  onClose,
  onSubmit,
}) => {
  const onDeleteSubmit = () => {
    if (!maintenanceLog) {
      return null
    }
    onSubmit(maintenanceLog)
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={deleteModalSx}>
        <Typography sx={{ fontSize: '16px', fontWeight: 700, mb: '10px' }}>
          メンテンナンスログを削除してもよろしいですか?
        </Typography>
        <Box sx={{ fontSize: '14px', fontWeight: 400, mb: '40px' }}>
          一度メンテンナンスログを削除するともとに戻すことはできません
        </Box>
        <Grid container direction="row" justifyContent="flex-end">
          <Box sx={cancelButtonWrapperSx}>
            <CancelButton onClick={onClose}>キャンセル</CancelButton>
          </Box>
          <Box sx={{ width: '118px' }}>
            <DeleteButton onClick={onDeleteSubmit}>削除</DeleteButton>
          </Box>
        </Grid>
      </Box>
    </Modal>
  )
}

export default DeleteMaintenanceLogModal
