import React from 'react'
import { NumberInput, required } from 'react-admin'
import { Grid, InputLabel } from '@mui/material'
import {
  MachinePartParams,
  WhichMaximumValueType,
  MaximumValueType,
  MachinePartRecord,
  MachinePartTypeRecord,
} from '../../../types/records/machine-part-record'
import { gridSx, labelGrayOutSx, numberInputSx } from '../../../assets/sx/form'

type Props = {
  setMachinePartParam: React.Dispatch<React.SetStateAction<MachinePartParams>>
  machinePartParam: MachinePartParams
  machinePart?: MachinePartRecord | undefined
  machinePartTypes: MachinePartTypeRecord[]
}

const SelectInputMaximumValue: React.FC<Props> = ({
  setMachinePartParam,
  machinePartParam,
  machinePart,
  machinePartTypes,
}) => {
  const machinePartType = machinePartTypes.find(
    (target) => target.id === machinePartParam.machinePartTypeID,
  )

  if (!machinePartType) return <></>

  return (
    <Grid>
      {WhichMaximumValueType(machinePartType.name) ===
      MaximumValueType.OperatingHour ? (
        <Grid sx={gridSx}>
          <InputLabel htmlFor="maximumOperatingHour" sx={labelGrayOutSx}>
            最大稼働時間(時間)
          </InputLabel>
          <NumberInput
            source="maximumOperatingHour"
            variant="outlined"
            label={false}
            sx={{ ...numberInputSx, legend: { display: 'none' } }}
            validate={required('エラー入力してください')}
            fullWidth
            defaultValue={machinePart?.motorPartLifetime?.maximumOperatingHour}
            onChange={(e) =>
              setMachinePartParam({
                ...machinePartParam,
                maximumOperatingHour: e.target.value,
              })
            }
          />
        </Grid>
      ) : (
        <></>
      )}

      {WhichMaximumValueType(machinePartType.name) ===
      MaximumValueType.ActuatingCycle ? (
        <Grid sx={gridSx}>
          <InputLabel htmlFor="maximumActuatingCycle" sx={labelGrayOutSx}>
            最大動作回数(万回)
          </InputLabel>
          <NumberInput
            source="maximumActuatingCycle"
            variant="outlined"
            label={false}
            sx={numberInputSx}
            validate={required('エラー入力してください')}
            fullWidth
            defaultValue={
              machinePart?.machinePartLifetime?.maximumActuatingCycle
            }
            onChange={(e) =>
              setMachinePartParam({
                ...machinePartParam,
                maximumActuatingCycle: e.target.value,
              })
            }
          />
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  )
}

export default SelectInputMaximumValue
