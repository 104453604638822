import { PushSolenoidRecord } from '../../../types/records/push-solenoid-record'

export const masterDataPushSolenoids: PushSolenoidRecord[] = [
  {
    id: 1,
    name: '左プッシュソレノイド1',
  },
  {
    id: 2,
    name: '左プッシュソレノイド2',
  },
  {
    id: 3,
    name: '左プッシュソレノイド3',
  },
  {
    id: 4,
    name: '左プッシュソレノイド4',
  },
  {
    id: 5,
    name: '右プッシュソレノイド5',
  },
  {
    id: 6,
    name: '右プッシュソレノイド6',
  },
  {
    id: 7,
    name: '右プッシュソレノイド7',
  },
  {
    id: 8,
    name: '右プッシュソレノイド8',
  },
]
