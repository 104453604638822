import React from 'react'
import { Admin, Resource, CustomRoutes, Authenticated } from 'react-admin'
import { Route } from 'react-router-dom'
import { createTheme } from '@mui/material/styles'
import { dataProvider } from './provider/data-provider'
import { authProvider } from './provider/auth-provider'
import Layout from './layouts/Layout'
import Login from './pages/Login'
import MachineList from './pages/MachineList'
import StaffList from './pages/StaffList'
import PasswordResetToken from './pages/PasswordResetToken'
import PasswordReset from './pages/PasswordReset'
import ErrorLogList from './pages/ErrorLogList'
import MachinePartList from './pages/MachinePartList'
import MaintenanceLogList from './pages/MaintenanceLogList'

import './assets/styles/index.scss'

const theme = createTheme({
  palette: {
    primary: {
      main: '#212121',
    },
    secondary: {
      main: '#005739',
    },
    warning: {
      main: '#CD0000',
    },
    text: {
      primary: '#000',
      secondary: '#757575',
    },
    background: {
      default: '#212121',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Noto Sans JP',
    fontWeightRegular: 400,
    fontSize: 14,
  },
})

const App: React.FC = () => {
  return (
    <Admin
      loginPage={Login}
      layout={Layout}
      theme={theme}
      dataProvider={dataProvider}
      authProvider={authProvider}>
      <Resource
        name="machines"
        list={
          <Authenticated>
            <MachineList />
          </Authenticated>
        }
      />
      <Resource
        name="staffs"
        list={
          <Authenticated>
            <StaffList />
          </Authenticated>
        }
      />
      <Resource
        name="error-logs"
        list={
          <Authenticated>
            <ErrorLogList />
          </Authenticated>
        }
      />
      <Resource
        name="machine-parts"
        list={
          <Authenticated>
            <MachinePartList />
          </Authenticated>
        }
      />
      <Resource
        name="maintenance-logs"
        list={
          <Authenticated>
            <MaintenanceLogList />
          </Authenticated>
        }
      />
      <CustomRoutes noLayout>
        <Route path="/password-reset-token" element={<PasswordResetToken />} />
        <Route path="/password-reset" element={<PasswordReset />} />
      </CustomRoutes>
    </Admin>
  )
}

export default App
