import { ChipPowderMotorRecord } from '../../../types/records/chip-powder-motor-record'

export const masterDataChipPowderMotors: ChipPowderMotorRecord[] = [
  {
    id: 1,
    name: 'パウダーモーター1',
  },
  {
    id: 2,
    name: 'パウダーモーター2',
  },
  {
    id: 3,
    name: 'パウダーモーター3',
  },
  {
    id: 4,
    name: 'チップモーター1',
  },
  {
    id: 5,
    name: 'チップモーター2',
  },
]
