export type MachinePartRecord = {
  id: number
  name: string
  drawingNumber: string
  machinePartType: MachinePartTypeRecord
  motorPartLifetime: MotorPartLifetimeRecord
  machinePartLifetime: MachinePartLifetimeRecord
}

export type MachinePartTypeRecord = {
  id: number
  name: string
}

export type MachinePartLifetimeRecord = {
  id: number
  maximumActuatingCycle: number
}

export type MotorPartLifetimeRecord = {
  id: number
  maximumOperatingHour: number
}

export type MachinePartParams = {
  name?: string
  drawingNumber?: string
  machinePartTypeID?: number
  maximumActuatingCycle?: number
  maximumOperatingHour?: number
}

export const MaximumValueType = {
  OperatingHour: 'operating_hour',
  ActuatingCycle: 'actuating_cycle',
  None: 'none',
}

export type MaximumValueType =
  typeof MaximumValueType[keyof typeof MaximumValueType]

export const MachinePartType = {
  TubePump: 'シロップ用チューブポンプ',
  Motor: 'チップ・パウダー用モーター',
  VacuumPump: '真空ポンプ',
  DustCollectingFan: '吸塵ファン',
  PushSolenoid: 'プッシュソレノイド',
  SolenoidValve: 'ソレノイドバルブ',
  LinearActuator: 'リニアアクチュエータ',
}

export type MachinePartType =
  typeof MachinePartType[keyof typeof MachinePartType]

export const WhichMaximumValueType = (name: string) => {
  switch (name) {
    case MachinePartType.TubePump:
    case MachinePartType.Motor:
    case MachinePartType.VacuumPump:
    case MachinePartType.DustCollectingFan:
      return MaximumValueType.OperatingHour
    case MachinePartType.PushSolenoid:
    case MachinePartType.SolenoidValve:
    case MachinePartType.LinearActuator:
      return MaximumValueType.ActuatingCycle
    default:
      return MaximumValueType.None
  }
}
