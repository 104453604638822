import React, { useState, MouseEvent } from 'react'
import { format, parseISO } from 'date-fns'
import {
  useDataProvider,
  useNotify,
  useGetList,
  useRefresh,
  FunctionField,
  WrapperField,
  TextField,
  TextInput,
} from 'react-admin'
import { InputAdornment, Grid } from '@mui/material'
import AddButton from '../components/button/AddButton'
import IconButton from '../components/button/IconButton'
import ListDatagrid from '../components/ListDatagrid'
import { ReactComponent as SearchIcon } from '../assets/images/search.svg'
import AddStaffModal from '../components/modal/AddStaffModal'
import EditStaffModal from '../components/modal/EditStaffModal'
import DeleteStaffModal from '../components/modal/DeleteStaffModal'
import {
  StaffRecord,
  AuthStaffGroupRecord,
  StaffParams,
} from '../types/records/staff-record'
import { useAuthorize, useNotifyForbidden } from '../hooks/authorization'
import { ReactComponent as PencilIcon } from '../assets/images/pencil.svg'
import { ReactComponent as TrashIcon } from '../assets/images/trash.svg'
import style from './StaffList.module.scss'
import { TextInputSx } from './FilterSx'
import { firstFieldSx } from '../assets/sx/field'

const StaffList: React.FC = () => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const [targetStaff, setTargetStaff] = useState<StaffRecord>()
  const userStaffGroups = useGetList('auth-staff-groups')
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false)
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const canCreateStaff = useAuthorize('staff', 'create')
  const canUpdateStaff = useAuthorize('staff', 'update')
  const canDeleteStaff = useAuthorize('staff', 'delete')

  const notifyForbidden = useNotifyForbidden()
  const openCreateModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!canCreateStaff) {
      notifyForbidden()
      return
    }
    setCreateModalOpen(true)
  }

  const openEditModal = (
    e: MouseEvent<HTMLElement>,
    targetUser: StaffRecord | undefined,
  ) => {
    e.preventDefault()
    if (!canUpdateStaff) {
      notifyForbidden()
      return
    }

    setEditModalOpen(true)
    setTargetStaff(targetUser)
  }

  const openDeleteModal = (
    e: MouseEvent<HTMLElement>,
    targetStaff: StaffRecord | undefined,
  ) => {
    e.preventDefault()
    if (!canDeleteStaff) {
      notifyForbidden()
      return
    }

    setDeleteModalOpen(true)
    setTargetStaff(targetStaff)
  }

  const closeCreateModal = () => {
    setCreateModalOpen(false)
  }

  const closeEditModal = () => {
    setEditModalOpen(false)
  }

  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const onCreateSubmit = (data: StaffParams) => {
    dataProvider
      .create('staffs', { data })
      .then(() => {
        notify('ユーザーを追加しました')
        setCreateModalOpen(false)
        refresh()
      })
      .catch((error) => {
        notify(error.message, { type: 'error' })
      })
  }

  const onUpdateSubmit = (
    id: number,
    data: StaffParams,
    previousData: StaffRecord,
  ) => {
    dataProvider
      .update('staffs', {
        id: id,
        data: data,
        previousData: previousData,
      })
      .then(() => {
        notify('ユーザーを更新しました')
        setEditModalOpen(false)
        refresh()
      })
      .catch((error) => {
        notify(error.message, { type: 'error' })
      })
  }

  const onDeleteSubmit = (staff: StaffRecord) => {
    dataProvider
      .delete('staffs', { id: staff.id, previousData: staff })
      .then(() => {
        notify('ユーザーを削除しました')
        setDeleteModalOpen(false)
        refresh()
      })
      .catch(() => {
        notify('ユーザーの削除に失敗しました')
      })
  }

  const filters = [
    <TextInput
      key="name"
      label={false}
      source="name"
      placeholder="従業員名で検索"
      alwaysOn
      fullWidth
      resettable
      sx={TextInputSx}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />,
  ]

  const settingButtonItems = [
    { tooltipLabel: '編集', icon: <PencilIcon />, onClick: openEditModal },
    { tooltipLabel: '削除', icon: <TrashIcon />, onClick: openDeleteModal },
  ]

  return (
    <>
      <ListDatagrid
        title="ユーザーマスター"
        filters={filters}
        addButton={
          <AddButton onClick={openCreateModal} disabled={!canCreateStaff}>
            ユーザーを追加する
          </AddButton>
        }
        modals={[
          <AddStaffModal
            key="addStaffModal"
            open={createModalOpen}
            onClose={closeCreateModal}
            onSubmit={onCreateSubmit}
            authStaffGroups={userStaffGroups.data as AuthStaffGroupRecord[]}
          />,
          <EditStaffModal
            key="editStaffModal"
            open={editModalOpen}
            onClose={closeEditModal}
            onSubmit={onUpdateSubmit}
            authStaffGroups={userStaffGroups.data as AuthStaffGroupRecord[]}
            staff={targetStaff}
          />,
          <DeleteStaffModal
            key="deleteStaffModal"
            open={deleteModalOpen}
            onClose={closeDeleteModal}
            onSubmit={onDeleteSubmit}
            staff={targetStaff}
          />,
        ]}>
        <TextField
          source="id"
          label="ユーザーID"
          sortable={false}
          headerClassName={style.staffIdColumn}
          sx={firstFieldSx}
        />
        <WrapperField
          label="ユーザー名"
          sortable={false}
          headerClassName={style.staffNameColumn}>
          <TextField
            sx={{
              fontWeight: 700,
            }}
            source="name"
          />
          <br></br>
          <TextField
            source="email"
            sx={{
              color: '#757575 !important',
            }}
          />
        </WrapperField>
        <TextField
          source="department"
          label="部署"
          sortable={false}
          headerClassName={style.staffDepartmentColumn}
        />
        <TextField
          source="authStaffGroup.name"
          label="権限"
          sortable={false}
          headerClassName={style.staffAuthGroupColumn}
        />
        <FunctionField
          render={(record: StaffRecord) =>
            record && format(parseISO(record.createdAt), 'yyyy/MM/dd')
          }
          label="登録日"
          sortable={false}
          headerClassName={style.staffCreatedAtColumn}
        />
        <FunctionField
          render={(record: StaffRecord) =>
            record.loggedInAt &&
            format(parseISO(record.loggedInAt), 'yyyy/MM/dd HH:mm')
          }
          label="最終ログイン日時"
          sortable={false}
          headerClassName={style.staffLoggedInAtColumn}
        />
        <FunctionField
          sortable={false}
          headerClassName={style.staffIconButtonsColumn}
          render={(record: StaffRecord) => {
            return (
              <Grid container display="flex" justifyContent="right">
                {settingButtonItems.map((item, index) => {
                  return (
                    <IconButton<StaffRecord>
                      key={`iconButton_${index}`}
                      tooltipLabel={item.tooltipLabel}
                      onClick={item.onClick}
                      icon={item.icon}
                      record={record}
                    />
                  )
                })}
              </Grid>
            )
          }}
        />
      </ListDatagrid>
    </>
  )
}

export default StaffList
