import { format, parseISO } from 'date-fns'
import React, { MouseEvent, useState } from 'react'
import {
  DateInput,
  FunctionField,
  RadioButtonGroupInput,
  TextField,
  TextInput,
  WrapperField,
} from 'react-admin'
import { InputAdornment, Grid } from '@mui/material'
import IconButton from '../components/button/IconButton'
import { ReactComponent as DetialIcon } from '../assets/images/details.svg'
import { ReactComponent as SearchIcon } from '../assets/images/search.svg'
import { ReactComponent as CalenderIcon } from '../assets/images/calender.svg'
import ListDatagrid from '../components/ListDatagrid'
import ErrorLogStatusField from '../components/field/ErrorLogStatusField'
import {
  ErrorLogStatusInputSx,
  StartDateInputSx,
  EndDateInputSx,
  TextInputSx,
  FilterSelectInputSx,
} from './FilterSx'
import { ErrorLogRecord } from '../types/records/error-log-record'
import {
  AddSelectInputChoisesStyle,
  DeleteSelectInputChoicesStyle,
} from '../components/SelectInputChoisesStyle'
import styles from './ErrorLogList.module.scss'
import { setFilterWidth } from '../assets/styles/setFilterWidth'
import CustomPlaceholderSelectInput from '../components/customComponent/CustomPlaceholderSelectInput'
import ShowErrorLogModal from '../components/modal/ShowErrorLogModal'

const filters = [
  <RadioButtonGroupInput
    className="status"
    key="status"
    label={false}
    source="status"
    alwaysOn
    margin="none"
    sx={ErrorLogStatusInputSx}
    choices={[
      { id: 'all', name: 'すべて' },
      { id: 'not_solved', name: '未対応' },
      { id: 'solved', name: '対応済み' },
    ]}
  />,
  <TextInput
    className="machineSerial"
    key="machineSerial"
    label={false}
    source="machineSerial"
    placeholder="マシン シリアルで検索"
    alwaysOn
    fullWidth
    resettable
    sx={TextInputSx}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput
    className="storeName"
    key="storeName"
    label={false}
    source="storeName"
    placeholder="店舗名で検索"
    alwaysOn
    fullWidth
    resettable
    sx={TextInputSx}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <DateInput
    className="errorOccurredAtStart"
    key="errorOccurredAtStart"
    label={false}
    source="errorOccurredAtStart"
    alwaysOn
    fullWidth
    sx={StartDateInputSx}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <CalenderIcon />
        </InputAdornment>
      ),
    }}
  />,
  <DateInput
    className="errorOccurredAtEnd"
    key="errorOccurredAtEnd"
    label={false}
    source="errorOccurredAtEnd"
    alwaysOn
    fullWidth
    sx={EndDateInputSx}
  />,
  <CustomPlaceholderSelectInput
    className="errorLevelID"
    type="filter"
    localStorageKey="errorLevelID"
    placeholder="すべて"
    key="errorLevelID"
    label={false}
    source="errorLevelID"
    alwaysOn
    margin="none"
    sx={FilterSelectInputSx}
    emptyText="すべて"
    emptyValue=""
    value=""
    choices={[
      {
        id: 1,
        name: 'エラーレベル1',
      },
      {
        id: 2,
        name: 'エラーレベル2',
      },
      {
        id: 3,
        name: 'エラーレベル3',
      },
    ]}
    onFocus={() => AddSelectInputChoisesStyle('menu-errorLevelID')}
    onBlur={DeleteSelectInputChoicesStyle}
  />,
]

const ErrorLogList: React.FC = () => {
  const existingData = JSON.parse(
    localStorage.getItem('RaStore.error-logs.listParams') || '{}',
  )

  const updatedData = {
    ...existingData,
    displayedFilters: {},
    filter: {
      status: 'all',
      orderReceivedAtStart: `${format(new Date(), 'yyyy-MM-dd')}`,
      orderReceivedAtEnd: `${format(new Date(), 'yyyy-MM-dd')}`,
    },
    order: 'ASC',
    page: 1,
    perPage: 10,
    sort: 'id',
  }

  //  更新したデータを再度localStorageに保存する
  localStorage.setItem(
    'RaStore.error-logs.listParams',
    JSON.stringify(updatedData),
  )

  // フィルターの幅を設定
  setFilterWidth('errorLogStatus', '35%')
  setFilterWidth('errorLogMachineSerial', '17.5%')
  setFilterWidth('errorLogStoreName', '17.5%')
  setFilterWidth('errorOccurredAtStart', '10%')
  setFilterWidth('errorOccurredAtEnd', '10%')
  setFilterWidth('errorLevelID', '10%')

  const [targetErrorLog, setErrorLog] = useState<ErrorLogRecord>()
  const [showModalOpen, setShowModalOpen] = useState<boolean>(false)

  const openShowModal = (
    e: MouseEvent<HTMLButtonElement>,
    targetErrorLog: ErrorLogRecord | undefined,
  ) => {
    e.preventDefault()
    setShowModalOpen(true)
    setErrorLog(targetErrorLog)
  }

  const closeShowModal = () => {
    setShowModalOpen(false)
  }

  const settingButtonItems = [
    { tooltipLabel: '詳細', icon: <DetialIcon />, onClick: openShowModal },
  ]

  const modals = [
    <ShowErrorLogModal
      key="showErrorLogModal"
      errorLog={targetErrorLog}
      open={showModalOpen}
      onClose={closeShowModal}
    />,
  ]

  return (
    <ListDatagrid title="エラーログ" filters={filters} modals={modals}>
      <WrapperField
        label="ステータス"
        sortable={false}
        headerClassName={styles.errorLogStatusField}>
        <Grid style={{ paddingLeft: '10px' }}>
          <ErrorLogStatusField />
        </Grid>
      </WrapperField>
      <TextField
        source="id"
        label="イベントID"
        sortable={false}
        headerClassName={styles.errorLogEventIDField}
      />
      <TextField
        source="errorEvent.errorLevel"
        label="エラーレベル"
        sortable={false}
        headerClassName={styles.errorLogLevelField}
      />
      <TextField
        source="errorEvent.name"
        label="エラー名"
        sortable={false}
        headerClassName={styles.errorLogNameField}
      />
      <TextField
        source="machine.store.name"
        label="店舗名"
        sortable={false}
        headerClassName={styles.errorLogStoreNameField}
      />
      <TextField
        source="machine.serial"
        label="マシン シリアル"
        sortable={false}
        headerClassName={styles.errorLogMachineSerialField}
      />
      <FunctionField
        render={(record: ErrorLogRecord) =>
          record && format(parseISO(record.occurredAt), 'yyyy/MM/dd HH.mm')
        }
        label="発生日時"
        sortable={false}
        headerClassName={styles.errorLogOccurredAtField}
      />
      <FunctionField
        sortable={false}
        headerClassName={styles.machineIconButtonsField}
        render={(record: ErrorLogRecord) => {
          return (
            <Grid container display="flex" justifyContent="right">
              {settingButtonItems.map((item, index) => {
                return (
                  <IconButton<ErrorLogRecord>
                    key={`iconButton_${index}`}
                    tooltipLabel={item.tooltipLabel}
                    onClick={item.onClick}
                    icon={item.icon}
                    record={record}
                  />
                )
              })}
            </Grid>
          )
        }}
      />
    </ListDatagrid>
  )
}

export default ErrorLogList
