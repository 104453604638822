import { useNotify, usePermissions } from 'react-admin'
import { Action, Resource } from '../types/authorization'
import { AuthStaffPermission } from '../types/records/auth-staff-permission-record'

export const useAuthorize = (resource: Resource, action: Action) => {
  const { permissions } = usePermissions<AuthStaffPermission[]>()

  return permissions?.find(
    (permission) =>
      permission.resource === resource && permission.action === action,
  )
    ? true
    : false
}

export const useNotifyForbidden = (): (() => void) => {
  const notify = useNotify()

  return () => notify('権限がないため操作できません')
}

export const useNotifyEditMaintenanceLogRestriction = (): (() => void) => {
  const notify = useNotify()

  return () => notify('最新のメンテナンスログのみ編集できます')
}

export const useNotifyDeleteMaintenanceLogRestriction = (): (() => void) => {
  const notify = useNotify()

  return () => notify('最新のメンテナンスログのみ削除できます')
}
