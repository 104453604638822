import React, { useEffect, useState } from 'react'
import {
  Form,
  SaveButton,
  PasswordInput,
  required,
  regex,
  useDataProvider,
  useNotify,
  useRedirect,
} from 'react-admin'
import {
  Container,
  Typography,
  Grid,
  Box,
  Stack,
  InputLabel,
} from '@mui/material'
import { useLocation } from 'react-router-dom'

import { ServiceLogo } from '../components/ServiceLogo'

import { gridSx, labelSx, saveButtonSx, textInputSx } from '../assets/sx/form'
import { validationErrorSx } from '../assets/sx/form'

const passwordRegex = /^(?=.*?[a-z])(?=.*?\d)[a-zA-Z\d!-~]{8,100}$/i

const PasswordReset: React.FC = () => {
  const search = useLocation().search
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const redirect = useRedirect()

  const [password, setPassword] = useState(String)
  const [passwordConfirmation, setPasswordConfirmation] = useState(String)
  const [passwordError, setPasswordError] = useState(String)

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const handlePasswordConfirmationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPasswordConfirmation(event.target.value)
  }

  useEffect(() => {
    if (password !== passwordConfirmation) {
      setPasswordError('エラーパスワードが一致しません')
    } else {
      setPasswordError('')
    }
  }, [password, passwordConfirmation])

  const onSubmit = (data: {
    password?: string
    passwordConfirmation?: string
  }) => {
    const query = new URLSearchParams(search)
    const passwordResetToken = query.get('password-reset-token')
    dataProvider
      .resetPassword(
        data?.password,
        data?.passwordConfirmation,
        passwordResetToken,
      )
      .then(() => {
        notify('パスワードを設定しました\n再度ログインしてください', {
          type: 'success',
          undoable: false,
          multiLine: true,
        })
        redirect('/login')
      })
      .catch(() => {
        notify('パスワードの設定に失敗しました', { type: 'error' })
      })
  }
  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ backgroundColor: 'primary.main' }}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}>
        <Stack sx={{ maxWidth: '398px' }}>
          <Box sx={{ mb: 5 }}>
            <ServiceLogo />
          </Box>
          <Box
            sx={{
              backgroundColor: '#FFF',
              p: 3,
              borderRadius: 1,
              mb: 3,
            }}>
            <Box sx={{ mb: 5 }}>
              <Typography sx={{ fontWeight: 700 }}>
                パスワードの再設定
              </Typography>
            </Box>
            <Form onSubmit={onSubmit}>
              <Grid sx={gridSx}>
                <InputLabel htmlFor="password" sx={labelSx}>
                  新しいパスワード(必須)
                </InputLabel>
                <PasswordInput
                  source="password"
                  variant="outlined"
                  label={false}
                  sx={textInputSx}
                  validate={[
                    required('エラー入力してください'),
                    regex(
                      passwordRegex,
                      'エラー半角英数字を混同で8文字以上のパスワードにしてください',
                    ),
                  ]}
                  fullWidth
                  onChange={handlePasswordChange}
                />
              </Grid>
              <Grid sx={gridSx}>
                <InputLabel htmlFor="passwordConfirmation" sx={labelSx}>
                  新しいパスワード再入力(必須)
                </InputLabel>
                <PasswordInput
                  source="passwordConfirmation"
                  variant="outlined"
                  label={false}
                  sx={textInputSx}
                  validate={[required('エラー入力してください')]}
                  onChange={handlePasswordConfirmationChange}
                  fullWidth
                />
                {passwordConfirmation && passwordError && (
                  <Box sx={validationErrorSx}>{passwordError}</Box>
                )}
              </Grid>
              <Grid container direction="row" justifyContent="flex-end">
                <SaveButton label="変更する" sx={saveButtonSx} />
              </Grid>
            </Form>
          </Box>
        </Stack>
      </Grid>
    </Container>
  )
}

export default PasswordReset
