import { LinearActuatorRecord } from '../../../types/records/linear-actuator-record'

export const masterDataLinearActuators: LinearActuatorRecord[] = [
  {
    id: 1,
    name: '水平移動用アクチュエータ',
  },
  {
    id: 2,
    name: '左蓋吸引用アクチュエータ',
  },
  {
    id: 3,
    name: '右蓋吸引用アクチュエータ',
  },
]
