import React, { ReactNode, MouseEvent } from 'react'
import Button from '@mui/material/Button'

import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg'

type Props = {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  children: ReactNode
}

const AddButton: React.FC<Props> = ({
  onClick,
  disabled = false,
  children,
}) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={onClick}
      disabled={disabled}
      sx={{
        height: '40px',
        borderRadius: '20px',
        fontWeight: 700,
        letterSpacing: '0.1em',
        '& svg': {
          marginRight: '10px',
        },
      }}
      disableElevation
      fullWidth>
      <PlusIcon className="icon" />
      {children}
    </Button>
  )
}

export default AddButton

export const TextAddButton: React.FC<Props> = ({
  onClick,
  disabled = false,
  children,
}) => {
  return (
    <Button
      variant="text"
      color="secondary"
      onClick={onClick}
      disabled={disabled}
      sx={{
        fontWeight: 700,
        letterSpacing: '0.1em',
        '& svg': {
          marginRight: '10px',

          '& path': {
            fill: 'currentcolor',
          },
        },
      }}
      disableElevation>
      <PlusIcon className="icon" />
      {children}
    </Button>
  )
}
