import React from 'react'
import { Grid, Typography, InputLabel, Divider } from '@mui/material'
import { useEffect } from 'react'
import { SelectInput, NumberInput } from 'react-admin'
import { MaintenanceLogParams } from '../../../types/records/maintenance-log-record'
import { MachinePartRecord } from '../../../types/records/machine-part-record'
import { MachineRecord } from '../../../types/records/machine-record'
import { MachineSolenoidValveLinkRecord } from '../../../types/records/machine-solenoid-valve-link-record'
import { MachineTubingPumpLinkRecord } from '../../../types/records/machine-tubing-pump-link-record'
import { MachineChipPowderMotorLinkRecord } from '../../../types/records/machine-chip-powder-motor-link-record'
import { MachineVacuumPumpLinkRecord } from '../../../types/records/machine-vacuum-pump-link-record'
import { MachinePushSolenoidLinkRecord } from '../../../types/records/machine-push-solenoid-link-record'
import { MachineLinearActuatorLinkRecord } from '../../../types/records/machine-linear-actuator-link-record'
import { MachineDustCollectingFanLinkRecord } from '../../../types/records/machine-dust-collecting-fan-link-record'
import { labelGrayOutSx, selectInputSx } from '../../../assets/sx/form'
import CustomPlaceholderSelectInput from '../../customComponent/CustomPlaceholderSelectInput'
import { modalDividerSx, modalSubtitleSx } from '../../../assets/sx/modalSx'

type Props = {
  machineParts: MachinePartRecord[]
  machines: MachineRecord[]
  maintenanceLogParams: MaintenanceLogParams
  hasConfirmed: boolean
  setMaintenanceLogParams: React.Dispatch<
    React.SetStateAction<MaintenanceLogParams>
  >
}

const AddMaintenanceLogModalReplacementPartsInput: React.FC<Props> = ({
  machineParts,
  maintenanceLogParams,
  machines,
  hasConfirmed,
  setMaintenanceLogParams,
}) => {
  useEffect(() => {
    if (hasConfirmed !== false) {
      return
    }

    // linksの更新
    // 0値を設定し確認画面で部品名のみを表示させる
    const updatedTubingPumpLinks = machines
      ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
      ?.flatMap((machine) => machine.machineTubingPumpLinks)
      .map((tubingPumpLink) => ({
        tubingPumpID: tubingPumpLink.tubingPumpID,
        machinePartID: 0,
      }))

    const updatedChipPowderMotorLinks = machines
      ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
      ?.flatMap((machine) => machine.machineChipPowderMotorLinks)
      .map((chipPowderMotorLink) => ({
        chipPowderMotorID: chipPowderMotorLink.chipPowderMotorID,
        machinePartID: 0,
      }))

    const updatedVacuumPumpLinks = machines
      ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
      ?.flatMap((machine) => machine.machineVacuumPumpLinks)
      .map((vacuumPumpLink) => ({
        vacuumPumpID: vacuumPumpLink.vacuumPumpID,
        machinePartID: 0,
      }))

    const updatedPushSolenoidLinks = machines
      ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
      ?.flatMap((machine) => machine.machinePushSolenoidLinks)
      .map((pushSolenoidLink) => ({
        pushSolenoidID: pushSolenoidLink.pushSolenoidID,
        machinePartID: 0,
      }))

    const updatedLinearActuatorLinks = machines
      ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
      ?.flatMap((machine) => machine.machineLinearActuatorLinks)
      .map((linearActuatorLink) => ({
        linearActuatorID: linearActuatorLink.linearActuatorID,
        machinePartID: 0,
      }))

    const updatedDustCollectingFanLinks = machines
      ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
      ?.flatMap((machine) => machine.machineDustCollectingFanLinks)
      .map((dustCollectingFanLink) => ({
        dustCollectingFanID: dustCollectingFanLink.dustCollectingFanID,
        machinePartID: 0,
      }))

    const updatedSolenoidValveLinks = machines
      ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
      ?.flatMap((machine) => machine.machineSolenoidValveLinks)
      .map((solenoidValveLink) => ({
        solenoidValveID: solenoidValveLink.solenoidValveID,
        machinePartID: 0,
      }))

    setMaintenanceLogParams({
      ...maintenanceLogParams,
      newMachineTubingPumpLinks: updatedTubingPumpLinks,
      newMachineChipPowderMotorLinks: updatedChipPowderMotorLinks,
      newMachineVacuumPumpLinks: updatedVacuumPumpLinks,
      newMachinePushSolenoidLinks: updatedPushSolenoidLinks,
      newMachineLinearActuatorLinks: updatedLinearActuatorLinks,
      newMachineDustCollectingFanLinks: updatedDustCollectingFanLinks,
      newMachineSolenoidValveLinks: updatedSolenoidValveLinks,
    })
  }, [])

  return (
    <Grid width={772} marginLeft={2}>
      <Typography sx={{ fontSize: '16px', fontWeight: 700, mb: '10px' }}>
        メンテナンスログを追加する 2/2
      </Typography>

      <Grid container wrap="wrap">
        {machines
          ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
          ?.map((machine: MachineRecord) =>
            machine.machineTubingPumpLinks?.map(
              (
                tubingPumpLink: MachineTubingPumpLinkRecord,
                tubingPumpIndex: number,
              ) => {
                const filteredMachineParts = machineParts.filter(
                  (part) => part.id === tubingPumpLink.machinePartID,
                )

                return (
                  <Grid
                    item
                    sx={{
                      width: '100%',
                      marginBottom: '24px',
                    }}
                    key={`${tubingPumpLink.tubingPump.name}-${tubingPumpIndex}`}>
                    {tubingPumpIndex === 0 && (
                      <Typography sx={modalSubtitleSx}>
                        シロップ用チューブポンプ
                      </Typography>
                    )}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="newTubingPumpReplacementLogs"
                          sx={labelGrayOutSx}>
                          {tubingPumpLink.tubingPump.name + ' 交換前'}
                        </InputLabel>
                        <SelectInput
                          source={`newTubingPumpReplacementLogs[[${tubingPumpIndex}]oldMachinePartID]`}
                          choices={filteredMachineParts.map((part) => ({
                            id: part.id,
                            name: `${part.drawingNumber}  ${part.name}`,
                          }))}
                          label={false}
                          sx={{ ...selectInputSx, width: '350px' }}
                          defaultValue={tubingPumpLink.machinePartID}
                          disabled
                        />
                      </Grid>
                      <Grid>
                        <NumberInput
                          source={`newTubingPumpReplacementLogs[tubingPumpID[${tubingPumpIndex}]]`}
                          label={false}
                          defaultValue={tubingPumpLink.tubingPumpID}
                          disabled
                          sx={{ display: 'none' }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="newMachineTubingPumpLinks"
                          sx={labelGrayOutSx}>
                          {tubingPumpLink.tubingPump.name + ' 交換後'}
                        </InputLabel>
                        <CustomPlaceholderSelectInput
                          type="formInput"
                          placeholder="選択してください"
                          source={`newMachineTubingPumpLinks[machinePartID[${tubingPumpIndex}]]`}
                          choices={machineParts
                            .filter((part) => part.machinePartType.id === 1)
                            .map((part) => ({
                              id: part.id,
                              name: `${part.drawingNumber}  ${part.name}`,
                            }))}
                          label={false}
                          sx={{ ...selectInputSx, width: '350px' }}
                          onChange={(e) => {
                            const updatedTubingPumpReplacementLogs = [
                              ...(maintenanceLogParams.newTubingPumpReplacementLogs ||
                                []),
                            ]
                            updatedTubingPumpReplacementLogs[tubingPumpIndex] =
                              {
                                ...updatedTubingPumpReplacementLogs[
                                  tubingPumpIndex
                                ],
                                oldMachinePartID: tubingPumpLink.machinePartID,
                                tubingPumpID: tubingPumpLink.tubingPumpID,
                              }

                            const updatedMachineTubingPumpLinks = [
                              ...(maintenanceLogParams.newMachineTubingPumpLinks ||
                                []),
                            ]
                            updatedMachineTubingPumpLinks[tubingPumpIndex] = {
                              ...updatedMachineTubingPumpLinks[tubingPumpIndex],
                              machinePartID: Number(e.target.value),
                              tubingPumpID: tubingPumpLink.tubingPumpID,
                            }

                            setMaintenanceLogParams((prev) => ({
                              ...prev,
                              newTubingPumpReplacementLogs:
                                updatedTubingPumpReplacementLogs,
                              newMachineTubingPumpLinks:
                                updatedMachineTubingPumpLinks,
                            }))
                          }}
                        />
                      </Grid>
                      <Grid>
                        <NumberInput
                          source={`newMachineTubingPumpLinks[tubingPumpID[${tubingPumpIndex}]]`}
                          label={false}
                          defaultValue={tubingPumpLink.tubingPumpID}
                          disabled
                          sx={{ display: 'none' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              },
            ),
          )}
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid container wrap="wrap">
        {machines
          ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
          ?.map((machine: MachineRecord) =>
            machine.machineChipPowderMotorLinks?.map(
              (
                chipPowderMotorLink: MachineChipPowderMotorLinkRecord,
                chipPowderMotorIndex: number,
              ) => {
                const filteredMachineParts = machineParts.filter(
                  (part) => part.id === chipPowderMotorLink.machinePartID,
                )

                return (
                  <Grid
                    item
                    sx={{
                      width: '100%',
                      marginBottom: '24px',
                    }}
                    key={`${chipPowderMotorLink.chipPowderMotor.name}-${chipPowderMotorIndex}`}>
                    {chipPowderMotorIndex === 0 && (
                      <Typography sx={modalSubtitleSx}>
                        チップ・パウダー用モーター
                      </Typography>
                    )}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="newChipPowderMotorReplacementLogs"
                          sx={labelGrayOutSx}>
                          {chipPowderMotorLink.chipPowderMotor.name + ' 交換前'}
                        </InputLabel>
                        <SelectInput
                          source={`newChipPowderMotorReplacementLogs[[${chipPowderMotorIndex}]oldMachinePartID]`}
                          choices={filteredMachineParts.map((part) => ({
                            id: part.id,
                            name: `${part.drawingNumber}  ${part.name}`,
                          }))}
                          label={false}
                          defaultValue={chipPowderMotorLink.machinePartID}
                          disabled
                          sx={{ ...selectInputSx, width: '350px' }}
                        />
                      </Grid>
                      <Grid>
                        <NumberInput
                          source={`newChipPowderMotorReplacementLogs[chipPowderMotorID[${chipPowderMotorIndex}]]`}
                          label={false}
                          defaultValue={chipPowderMotorLink.chipPowderMotorID}
                          disabled
                          sx={{ display: 'none' }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="newMachineChipPowderMotorLinks"
                          sx={labelGrayOutSx}>
                          {chipPowderMotorLink.chipPowderMotor.name + ' 交換後'}
                        </InputLabel>
                        <CustomPlaceholderSelectInput
                          type="formInput"
                          placeholder="選択してください"
                          source={`newMachineChipPowderMotorLinks[machinePartID[${chipPowderMotorIndex}]]`}
                          choices={machineParts
                            .filter((part) => part.machinePartType.id === 2)
                            .map((part) => ({
                              id: part.id,
                              name: `${part.drawingNumber}  ${part.name}`,
                            }))}
                          label={false}
                          sx={{ ...selectInputSx, width: '350px' }}
                          onChange={(e) => {
                            const updatedChipPowderMotorReplacementLogs = [
                              ...(maintenanceLogParams.newChipPowderMotorReplacementLogs ||
                                []),
                            ]
                            updatedChipPowderMotorReplacementLogs[
                              chipPowderMotorIndex
                            ] = {
                              ...updatedChipPowderMotorReplacementLogs[
                                chipPowderMotorIndex
                              ],
                              oldMachinePartID:
                                chipPowderMotorLink.machinePartID,
                              chipPowderMotorID:
                                chipPowderMotorLink.chipPowderMotorID,
                            }

                            const updatedMachineChipPowderMotorLinks = [
                              ...(maintenanceLogParams.newMachineChipPowderMotorLinks ||
                                []),
                            ]
                            updatedMachineChipPowderMotorLinks[
                              chipPowderMotorIndex
                            ] = {
                              ...updatedMachineChipPowderMotorLinks[
                                chipPowderMotorIndex
                              ],
                              machinePartID: Number(e.target.value),
                              chipPowderMotorID:
                                chipPowderMotorLink.chipPowderMotorID,
                            }

                            setMaintenanceLogParams((prev) => ({
                              ...prev,
                              newChipPowderMotorReplacementLogs:
                                updatedChipPowderMotorReplacementLogs,
                              newMachineChipPowderMotorLinks:
                                updatedMachineChipPowderMotorLinks,
                            }))
                          }}
                        />
                      </Grid>
                      <Grid>
                        <NumberInput
                          source={`newMachineChipPowderMotorLinks[chipPowderMotorID[${chipPowderMotorIndex}]]`}
                          label={false}
                          defaultValue={chipPowderMotorLink.chipPowderMotorID}
                          disabled
                          sx={{ display: 'none' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              },
            ),
          )}
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid container wrap="wrap">
        {machines
          ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
          ?.map((machine: MachineRecord) =>
            machine.machineVacuumPumpLinks?.map(
              (
                vacuumPumpLink: MachineVacuumPumpLinkRecord,
                vacuumPumpIndex: number,
              ) => {
                const filteredMachineParts = machineParts.filter(
                  (part) => part.id === vacuumPumpLink.machinePartID,
                )

                return (
                  <Grid
                    item
                    sx={{
                      width: '100%',
                      marginBottom: '24px',
                    }}
                    key={`${vacuumPumpLink.vacuumPump.name}-${vacuumPumpIndex}`}>
                    {vacuumPumpIndex === 0 && (
                      <Typography sx={modalSubtitleSx}>真空ポンプ</Typography>
                    )}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="newVacuumPumpReplacementLogs"
                          sx={labelGrayOutSx}>
                          {vacuumPumpLink.vacuumPump.name + ' 交換前'}
                        </InputLabel>
                        <SelectInput
                          source={`newVacuumPumpReplacementLogs[[${vacuumPumpIndex}]oldMachinePartID]`}
                          choices={filteredMachineParts.map((part) => ({
                            id: part.id,
                            name: `${part.drawingNumber}  ${part.name}`,
                          }))}
                          label={false}
                          defaultValue={vacuumPumpLink.machinePartID}
                          disabled
                          sx={{ ...selectInputSx, width: '350px' }}
                        />
                      </Grid>
                      <Grid>
                        <NumberInput
                          source={`newVacuumPumpReplacementLogs[vacuumPumpID[${vacuumPumpIndex}]]`}
                          label={false}
                          defaultValue={vacuumPumpLink.vacuumPumpID}
                          disabled
                          sx={{ display: 'none' }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="newMachineVacuumPumpLinks"
                          sx={labelGrayOutSx}>
                          {vacuumPumpLink.vacuumPump.name + ' 交換後'}
                        </InputLabel>
                        <CustomPlaceholderSelectInput
                          type="formInput"
                          placeholder="選択してください"
                          source={`newMachineVacuumPumpLinks[machinePartID[${vacuumPumpIndex}]]`}
                          choices={machineParts
                            .filter((part) => part.machinePartType.id === 3)
                            .map((part) => ({
                              id: part.id,
                              name: `${part.drawingNumber}  ${part.name}`,
                            }))}
                          label={false}
                          sx={{ ...selectInputSx, width: '350px' }}
                          onChange={(e) => {
                            const updatedVacuumPumpReplacementLogs = [
                              ...(maintenanceLogParams.newVacuumPumpReplacementLogs ||
                                []),
                            ]
                            updatedVacuumPumpReplacementLogs[vacuumPumpIndex] =
                              {
                                ...updatedVacuumPumpReplacementLogs[
                                  vacuumPumpIndex
                                ],
                                oldMachinePartID: vacuumPumpLink.machinePartID,
                                vacuumPumpID: vacuumPumpLink.vacuumPumpID,
                              }

                            const updatedMachineVacuumPumpLinks = [
                              ...(maintenanceLogParams.newMachineVacuumPumpLinks ||
                                []),
                            ]
                            updatedMachineVacuumPumpLinks[vacuumPumpIndex] = {
                              ...updatedMachineVacuumPumpLinks[vacuumPumpIndex],
                              machinePartID: Number(e.target.value),
                              vacuumPumpID: vacuumPumpLink.vacuumPumpID,
                            }

                            setMaintenanceLogParams((prev) => ({
                              ...prev,
                              newVacuumPumpReplacementLogs:
                                updatedVacuumPumpReplacementLogs,
                              newMachineVacuumPumpLinks:
                                updatedMachineVacuumPumpLinks,
                            }))
                          }}
                        />
                      </Grid>
                      <Grid>
                        <NumberInput
                          source={`newMachineVacuumPumpLinks[vacuumPumpID[${vacuumPumpIndex}]]`}
                          label={false}
                          defaultValue={vacuumPumpLink.vacuumPumpID}
                          disabled
                          sx={{ display: 'none' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              },
            ),
          )}
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid container wrap="wrap">
        {machines
          ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
          ?.map((machine: MachineRecord) =>
            machine.machineDustCollectingFanLinks?.map(
              (
                dustCollectingFanLink: MachineDustCollectingFanLinkRecord,
                dustCollectingFanIndex: number,
              ) => {
                const filteredMachineParts = machineParts.filter(
                  (part) => part.id === dustCollectingFanLink.machinePartID,
                )

                return (
                  <Grid
                    item
                    sx={{
                      width: '100%',
                      marginBottom: '24px',
                    }}
                    key={`${dustCollectingFanLink.dustCollectingFan.name}-${dustCollectingFanIndex}`}>
                    {dustCollectingFanIndex === 0 && (
                      <Typography sx={modalSubtitleSx}>吸塵ファン</Typography>
                    )}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="newDustCollectingFanReplacementLogs"
                          sx={labelGrayOutSx}>
                          {dustCollectingFanLink.dustCollectingFan.name +
                            ' 交換前'}
                        </InputLabel>
                        <SelectInput
                          source={`newDustCollectingFanReplacementLogs[[${dustCollectingFanIndex}]oldMachinePartID]`}
                          choices={filteredMachineParts.map((part) => ({
                            id: part.id,
                            name: `${part.drawingNumber}  ${part.name}`,
                          }))}
                          label={false}
                          defaultValue={dustCollectingFanLink.machinePartID}
                          disabled
                          sx={{ ...selectInputSx, width: '350px' }}
                        />
                      </Grid>
                      <Grid>
                        <NumberInput
                          source={`newDustCollectingFanReplacementLogs[dustCollectingFanID[${dustCollectingFanIndex}]]`}
                          label={false}
                          defaultValue={
                            dustCollectingFanLink.dustCollectingFanID
                          }
                          disabled
                          sx={{ display: 'none' }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="newMachineDustCollectingFanLinks"
                          sx={labelGrayOutSx}>
                          {dustCollectingFanLink.dustCollectingFan.name +
                            ' 交換後'}
                        </InputLabel>
                        <CustomPlaceholderSelectInput
                          type="formInput"
                          placeholder="選択してください"
                          source={`newMachineDustCollectingFanLinks[machinePartID[${dustCollectingFanIndex}]]`}
                          choices={machineParts
                            .filter((part) => part.machinePartType.id === 4)
                            .map((part) => ({
                              id: part.id,
                              name: `${part.drawingNumber}  ${part.name}`,
                            }))}
                          label={false}
                          sx={{ ...selectInputSx, width: '350px' }}
                          onChange={(e) => {
                            const updatedDustCollectingFanReplacementLogs = [
                              ...(maintenanceLogParams.newDustCollectingFanReplacementLogs ||
                                []),
                            ]
                            updatedDustCollectingFanReplacementLogs[
                              dustCollectingFanIndex
                            ] = {
                              ...updatedDustCollectingFanReplacementLogs[
                                dustCollectingFanIndex
                              ],
                              oldMachinePartID:
                                dustCollectingFanLink.machinePartID,
                              dustCollectingFanID:
                                dustCollectingFanLink.dustCollectingFanID,
                            }

                            const updatedMachineDustCollectingFanLinks = [
                              ...(maintenanceLogParams.newMachineDustCollectingFanLinks ||
                                []),
                            ]
                            updatedMachineDustCollectingFanLinks[
                              dustCollectingFanIndex
                            ] = {
                              ...updatedMachineDustCollectingFanLinks[
                                dustCollectingFanIndex
                              ],
                              machinePartID: Number(e.target.value),
                              dustCollectingFanID:
                                dustCollectingFanLink.dustCollectingFanID,
                            }

                            setMaintenanceLogParams((prev) => ({
                              ...prev,
                              newDustCollectingFanReplacementLogs:
                                updatedDustCollectingFanReplacementLogs,
                              newMachineDustCollectingFanLinks:
                                updatedMachineDustCollectingFanLinks,
                            }))
                          }}
                        />
                      </Grid>
                      <Grid>
                        <NumberInput
                          source={`newMachineDustCollectingFanLinks[dustCollectingFanID[${dustCollectingFanIndex}]]`}
                          label={false}
                          defaultValue={
                            dustCollectingFanLink.dustCollectingFanID
                          }
                          disabled
                          sx={{ display: 'none' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              },
            ),
          )}
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid container wrap="wrap">
        {machines
          ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
          ?.map((machine: MachineRecord) =>
            machine.machinePushSolenoidLinks?.map(
              (
                pushSolenoidLink: MachinePushSolenoidLinkRecord,
                pushSolenoidIndex: number,
              ) => {
                const filteredMachineParts = machineParts.filter(
                  (part) => part.id === pushSolenoidLink.machinePartID,
                )

                return (
                  <Grid
                    item
                    sx={{
                      width: '100%',
                      marginBottom: '24px',
                    }}
                    key={`${pushSolenoidLink.pushSolenoid.name}-${pushSolenoidIndex}`}>
                    {pushSolenoidIndex === 0 && (
                      <Typography sx={modalSubtitleSx}>
                        プッシュソレノイド
                      </Typography>
                    )}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="newPushSolenoidReplacementLogs"
                          sx={labelGrayOutSx}>
                          {pushSolenoidLink.pushSolenoid.name + ' 交換前'}
                        </InputLabel>
                        <SelectInput
                          source={`newPushSolenoidReplacementLogs[[${pushSolenoidIndex}]oldMachinePartID]`}
                          choices={filteredMachineParts.map((part) => ({
                            id: part.id,
                            name: `${part.drawingNumber}  ${part.name}`,
                          }))}
                          label={false}
                          defaultValue={pushSolenoidLink.machinePartID}
                          disabled
                          sx={{ ...selectInputSx, width: '350px' }}
                        />
                      </Grid>
                      <Grid>
                        <NumberInput
                          source={`newPushSolenoidReplacementLogs[pushSolenoidID[${pushSolenoidIndex}]]`}
                          label={false}
                          defaultValue={pushSolenoidLink.pushSolenoidID}
                          disabled
                          sx={{ display: 'none' }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="newMachinePushSolenoidLinks"
                          sx={labelGrayOutSx}>
                          {pushSolenoidLink.pushSolenoid.name + ' 交換後'}
                        </InputLabel>
                        <CustomPlaceholderSelectInput
                          type="formInput"
                          placeholder="選択してください"
                          source={`newMachinePushSolenoidLinks[machinePartID[${pushSolenoidIndex}]]`}
                          choices={machineParts
                            .filter((part) => part.machinePartType.id === 5)
                            .map((part) => ({
                              id: part.id,
                              name: `${part.drawingNumber}  ${part.name}`,
                            }))}
                          label={false}
                          sx={{ ...selectInputSx, width: '350px' }}
                          onChange={(e) => {
                            const updatedPushSolenoidReplacementLogs = [
                              ...(maintenanceLogParams.newPushSolenoidReplacementLogs ||
                                []),
                            ]
                            updatedPushSolenoidReplacementLogs[
                              pushSolenoidIndex
                            ] = {
                              ...updatedPushSolenoidReplacementLogs[
                                pushSolenoidIndex
                              ],
                              oldMachinePartID: pushSolenoidLink.machinePartID,
                              pushSolenoidID: pushSolenoidLink.pushSolenoidID,
                            }

                            const updatedMachinePushSolenoidLinks = [
                              ...(maintenanceLogParams.newMachinePushSolenoidLinks ||
                                []),
                            ]
                            updatedMachinePushSolenoidLinks[pushSolenoidIndex] =
                              {
                                ...updatedMachinePushSolenoidLinks[
                                  pushSolenoidIndex
                                ],
                                machinePartID: Number(e.target.value),
                                pushSolenoidID: pushSolenoidLink.pushSolenoidID,
                              }

                            setMaintenanceLogParams((prev) => ({
                              ...prev,
                              newPushSolenoidReplacementLogs:
                                updatedPushSolenoidReplacementLogs,
                              newMachinePushSolenoidLinks:
                                updatedMachinePushSolenoidLinks,
                            }))
                          }}
                        />
                      </Grid>
                      <Grid>
                        <NumberInput
                          source={`newMachinePushSolenoidLinks[pushSolenoidID[${pushSolenoidIndex}]]`}
                          label={false}
                          defaultValue={pushSolenoidLink.pushSolenoidID}
                          disabled
                          sx={{ display: 'none' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              },
            ),
          )}
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid container wrap="wrap">
        {machines
          ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
          ?.map((machine: MachineRecord) =>
            machine.machineSolenoidValveLinks?.map(
              (
                solenoidValveLink: MachineSolenoidValveLinkRecord,
                solenoidValveIndex: number,
              ) => {
                const filteredMachineParts = machineParts.filter(
                  (part) => part.id === solenoidValveLink.machinePartID,
                )

                return (
                  <Grid
                    item
                    sx={{
                      width: '100%',
                      marginBottom: '24px',
                    }}
                    key={`${solenoidValveLink.solenoidValve.name}-${solenoidValveIndex}`}>
                    {solenoidValveIndex === 0 && (
                      <Typography sx={modalSubtitleSx}>
                        ソレノイドバルブ
                      </Typography>
                    )}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="newSolenoidValveReplacementLogs"
                          sx={labelGrayOutSx}>
                          {solenoidValveLink.solenoidValve.name + ' 交換前'}
                        </InputLabel>
                        <SelectInput
                          source={`newSolenoidValveReplacementLogs[[${solenoidValveIndex}]oldMachinePartID]`}
                          choices={filteredMachineParts.map((part) => ({
                            id: part.id,
                            name: `${part.drawingNumber}  ${part.name}`,
                          }))}
                          label={false}
                          defaultValue={solenoidValveLink.machinePartID}
                          disabled
                          sx={{ ...selectInputSx, width: '350px' }}
                        />
                      </Grid>
                      <Grid>
                        <NumberInput
                          source={`newSolenoidValveReplacementLogs[solenoidValveID[${solenoidValveIndex}]]`}
                          label={false}
                          defaultValue={solenoidValveLink.solenoidValveID}
                          disabled
                          sx={{ display: 'none' }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="newMachineSolenoidValveLinks"
                          sx={labelGrayOutSx}>
                          {solenoidValveLink.solenoidValve.name + ' 交換後'}
                        </InputLabel>
                        <CustomPlaceholderSelectInput
                          type="formInput"
                          placeholder="選択してください"
                          source={`newMachineSolenoidValveLinks[machinePartID[${solenoidValveIndex}]]`}
                          choices={machineParts
                            .filter((part) => part.machinePartType.id === 6)
                            .map((part) => ({
                              id: part.id,
                              name: `${part.drawingNumber}  ${part.name}`,
                            }))}
                          label={false}
                          sx={{ ...selectInputSx, width: '350px' }}
                          onChange={(e) => {
                            const updatedSolenoidValveReplacementLogs = [
                              ...(maintenanceLogParams.newSolenoidValveReplacementLogs ||
                                []),
                            ]
                            updatedSolenoidValveReplacementLogs[
                              solenoidValveIndex
                            ] = {
                              ...updatedSolenoidValveReplacementLogs[
                                solenoidValveIndex
                              ],
                              oldMachinePartID: solenoidValveLink.machinePartID,
                              solenoidValveID:
                                solenoidValveLink.solenoidValveID,
                            }

                            const updatedMachineSolenoidValveLinks = [
                              ...(maintenanceLogParams.newMachineSolenoidValveLinks ||
                                []),
                            ]
                            updatedMachineSolenoidValveLinks[
                              solenoidValveIndex
                            ] = {
                              ...updatedMachineSolenoidValveLinks[
                                solenoidValveIndex
                              ],
                              machinePartID: Number(e.target.value),
                              solenoidValveID:
                                solenoidValveLink.solenoidValveID,
                            }

                            setMaintenanceLogParams((prev) => ({
                              ...prev,
                              newSolenoidValveReplacementLogs:
                                updatedSolenoidValveReplacementLogs,
                              newMachineSolenoidValveLinks:
                                updatedMachineSolenoidValveLinks,
                            }))
                          }}
                        />
                      </Grid>
                      <Grid>
                        <NumberInput
                          source={`newMachineSolenoidValveLinks[solenoidValveID[${solenoidValveIndex}]]`}
                          label={false}
                          defaultValue={solenoidValveLink.solenoidValveID}
                          disabled
                          sx={{ display: 'none' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              },
            ),
          )}
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid container wrap="wrap">
        {machines
          ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
          ?.map((machine: MachineRecord) =>
            machine.machineLinearActuatorLinks?.map(
              (
                linearActuatorLink: MachineLinearActuatorLinkRecord,
                linearActuatorIndex: number,
              ) => {
                const filteredMachineParts = machineParts.filter(
                  (part) => part.id === linearActuatorLink.machinePartID,
                )

                return (
                  <Grid
                    item
                    sx={{
                      width: '100%',
                      marginBottom: '24px',
                    }}
                    key={`${linearActuatorLink.linearActuator.name}-${linearActuatorIndex}`}>
                    {linearActuatorIndex === 0 && (
                      <Typography sx={modalSubtitleSx}>
                        リニアアクチュエーター
                      </Typography>
                    )}

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="newLinearActuatorReplacementLogs"
                          sx={labelGrayOutSx}>
                          {linearActuatorLink.linearActuator.name + ' 交換前'}
                        </InputLabel>
                        <SelectInput
                          source={`newLinearActuatorReplacementLogs[[${linearActuatorIndex}]oldMachinePartID]`}
                          choices={filteredMachineParts.map((part) => ({
                            id: part.id,
                            name: `${part.drawingNumber}  ${part.name}`,
                          }))}
                          label={false}
                          defaultValue={linearActuatorLink.machinePartID}
                          disabled
                          sx={{ ...selectInputSx, width: '350px' }}
                        />
                      </Grid>
                      <Grid>
                        <NumberInput
                          source={`newLinearActuatorReplacementLogs[linearActuatorID[${linearActuatorIndex}]]`}
                          label={false}
                          defaultValue={linearActuatorLink.linearActuatorID}
                          disabled
                          sx={{ display: 'none' }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="newMachineLinearActuatorLinks"
                          sx={labelGrayOutSx}>
                          {linearActuatorLink.linearActuator.name + ' 交換後'}
                        </InputLabel>
                        <CustomPlaceholderSelectInput
                          type="formInput"
                          placeholder="選択してください"
                          source={`newMachineLinearActuatorLinks[machinePartID[${linearActuatorIndex}]]`}
                          choices={machineParts
                            .filter((part) => part.machinePartType.id === 7)
                            .map((part) => ({
                              id: part.id,
                              name: `${part.drawingNumber}  ${part.name}`,
                            }))}
                          label={false}
                          sx={{ ...selectInputSx, width: '350px' }}
                          onChange={(e) => {
                            const updatedLinearActuatorReplacementLogs = [
                              ...(maintenanceLogParams.newLinearActuatorReplacementLogs ||
                                []),
                            ]
                            updatedLinearActuatorReplacementLogs[
                              linearActuatorIndex
                            ] = {
                              ...updatedLinearActuatorReplacementLogs[
                                linearActuatorIndex
                              ],
                              oldMachinePartID:
                                linearActuatorLink.machinePartID,
                              linearActuatorID:
                                linearActuatorLink.linearActuatorID,
                            }

                            const updatedMachineLinearActuatorLinks = [
                              ...(maintenanceLogParams.newMachineLinearActuatorLinks ||
                                []),
                            ]
                            updatedMachineLinearActuatorLinks[
                              linearActuatorIndex
                            ] = {
                              ...updatedMachineLinearActuatorLinks[
                                linearActuatorIndex
                              ],
                              machinePartID: Number(e.target.value),
                              linearActuatorID:
                                linearActuatorLink.linearActuatorID,
                            }

                            setMaintenanceLogParams((prev) => ({
                              ...prev,
                              newLinearActuatorReplacementLogs:
                                updatedLinearActuatorReplacementLogs,
                              newMachineLinearActuatorLinks:
                                updatedMachineLinearActuatorLinks,
                            }))
                          }}
                        />
                      </Grid>
                      <Grid>
                        <NumberInput
                          source={`newMachineLinearActuatorLinks[linearActuatorID[${linearActuatorIndex}]]`}
                          label={false}
                          defaultValue={linearActuatorLink.linearActuatorID}
                          disabled
                          sx={{ display: 'none' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              },
            ),
          )}
      </Grid>
    </Grid>
  )
}

export default AddMaintenanceLogModalReplacementPartsInput
