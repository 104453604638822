import React from 'react'
import { required } from 'react-admin'
import { Grid, InputLabel, Divider, Typography } from '@mui/material'
import {
  MachinePartRecord,
  MachinePartTypeRecord,
  MachinePartType,
} from '../../../types/records/machine-part-record'
import { masterDataChipPowderMotors } from './MasterDataChipPowderMotors'
import { masterDataDustCollectingFans } from './MasterDataDustCollectingFans'
import { masterDataLinearActuators } from './MasterDataLinearActuators'
import { masterDataPushSolenoids } from './MasterDataPushSolenoids'
import { masterDataSolenoidValves } from './MasterDataSolenoidValves'
import { masterDataTubingPumps } from './MasterDataTubingPumps'
import { masterDataVacuumPumps } from './MasterDataVacuumPumps'
import {
  gridSx,
  labelGrayOutSx,
  labelSx,
  selectInputSx,
} from '../../../assets/sx/form'
import { modalTitleSx, modalDividerSx } from '../../../assets/sx/modalSx'
import CustomPlaceholderSelectInput from '../../customComponent/CustomPlaceholderSelectInput'

type Props = {
  machinePartTypes: MachinePartTypeRecord[]
  machineParts: MachinePartRecord[]
  handleOnChangeMachineTubingPumpLink: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void
  handleOnChangeMachineChipPowderMotorLink: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void
  handleOnChangeMachineDustCollectingFanLink: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void
  handleOnChangeMachineVacuumPumpLink: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void
  handleOnChangeMachineLinearActuatorLink: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void
  handleOnChangeMachineSolenoidValveLink: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void
  handleOnChangeMachinePushSolenoidLink: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void
}

const AddMachineModalMachinePartsInput: React.FC<Props> = ({
  machinePartTypes,
  machineParts,
  handleOnChangeMachineTubingPumpLink,
  handleOnChangeMachineChipPowderMotorLink,
  handleOnChangeMachineDustCollectingFanLink,
  handleOnChangeMachineVacuumPumpLink,
  handleOnChangeMachineLinearActuatorLink,
  handleOnChangeMachineSolenoidValveLink,
  handleOnChangeMachinePushSolenoidLink,
}) => {
  const tubePumpMachinePartsList: { id: number; name: string }[] = []
  const chipPowderMotorMachinePartsList: { id: number; name: string }[] = []
  const dustCollectingFanMachinePartsList: { id: number; name: string }[] = []
  const vacuumPumpMachinePartsList: { id: number; name: string }[] = []
  const linearActuatorMachinePartsList: { id: number; name: string }[] = []
  const solenoidValveMachinePartsList: { id: number; name: string }[] = []
  const pushSolenoidMachinePartsList: { id: number; name: string }[] = []
  machineParts.forEach((machinePart) => {
    machinePartTypes.forEach((machinePartType) => {
      if (machinePart.machinePartType.id === machinePartType.id) {
        switch (machinePartType.name) {
          case MachinePartType.TubePump:
            tubePumpMachinePartsList.push({
              id: machinePart.id,
              name: machinePart.drawingNumber + ' ' + machinePart.name,
            })
            break
          case MachinePartType.Motor:
            chipPowderMotorMachinePartsList.push({
              id: machinePart.id,
              name: machinePart.drawingNumber + ' ' + machinePart.name,
            })
            break
          case MachinePartType.DustCollectingFan:
            dustCollectingFanMachinePartsList.push({
              id: machinePart.id,
              name: machinePart.drawingNumber + ' ' + machinePart.name,
            })
            break
          case MachinePartType.VacuumPump:
            vacuumPumpMachinePartsList.push({
              id: machinePart.id,
              name: machinePart.drawingNumber + ' ' + machinePart.name,
            })
            break
          case MachinePartType.LinearActuator:
            linearActuatorMachinePartsList.push({
              id: machinePart.id,
              name: machinePart.drawingNumber + ' ' + machinePart.name,
            })
            break
          case MachinePartType.SolenoidValve:
            solenoidValveMachinePartsList.push({
              id: machinePart.id,
              name: machinePart.drawingNumber + ' ' + machinePart.name,
            })
            break
          case MachinePartType.PushSolenoid:
            pushSolenoidMachinePartsList.push({
              id: machinePart.id,
              name: machinePart.drawingNumber + ' ' + machinePart.name,
            })
            break
        }
      }
    })
  })

  return (
    <Grid>
      <Typography sx={modalTitleSx}>マシンを追加する 2/2</Typography>
      <Grid sx={gridSx}>
        <InputLabel sx={labelSx}>シロップ用チューブポンプ</InputLabel>
        <Grid>
          {masterDataTubingPumps.map((tubingPump, index) => (
            <Grid key={tubingPump.name}>
              <InputLabel
                htmlFor={`machineTubingPumpLinks[${index}][machinePartId]`}
                sx={labelGrayOutSx}>
                {tubingPump.name} *
              </InputLabel>
              <CustomPlaceholderSelectInput
                type="formInput"
                placeholder="選択してください"
                key={index}
                source={`machineTubingPumpLinks[${index}][machinePartId]`}
                variant="outlined"
                label={false}
                sx={selectInputSx}
                choices={tubePumpMachinePartsList}
                validate={required('エラー選択してください')}
                onChange={(event) =>
                  handleOnChangeMachineTubingPumpLink(event, index)
                }
                fullWidth
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid sx={gridSx}>
        <InputLabel sx={labelSx}>チップ・パウダー用モーター</InputLabel>
        <Grid>
          {masterDataChipPowderMotors.map((chipPowderMotor, index) => (
            <Grid key={chipPowderMotor.name}>
              <InputLabel
                htmlFor={`machineChipPowderMotorLinks[${index}][machinePartId]`}
                sx={labelGrayOutSx}>
                {chipPowderMotor.name} *
              </InputLabel>
              <CustomPlaceholderSelectInput
                type="formInput"
                placeholder="選択してください"
                key={index}
                source={`machineChipPowderMotorLinks[${index}][machinePartId]`}
                variant="outlined"
                label={false}
                sx={selectInputSx}
                choices={chipPowderMotorMachinePartsList}
                validate={required('エラー選択してください')}
                onChange={(event) =>
                  handleOnChangeMachineChipPowderMotorLink(event, index)
                }
                fullWidth
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid sx={gridSx}>
        <InputLabel sx={labelSx}>真空ポンプ</InputLabel>
        <Grid>
          {masterDataVacuumPumps.map((vacuumPump, index) => (
            <Grid key={vacuumPump.name}>
              <InputLabel
                htmlFor={`machineVacuumPumpLinks[${index}][machinePartId]`}
                sx={labelGrayOutSx}>
                {vacuumPump.name} *
              </InputLabel>
              <CustomPlaceholderSelectInput
                type="formInput"
                placeholder="選択してください"
                key={index}
                source={`machineVacuumPumpLinks[${index}][machinePartId]`}
                variant="outlined"
                label={false}
                sx={selectInputSx}
                choices={vacuumPumpMachinePartsList}
                validate={required('エラー選択してください')}
                onChange={(event) =>
                  handleOnChangeMachineVacuumPumpLink(event, index)
                }
                fullWidth
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid sx={gridSx}>
        <InputLabel sx={labelSx}>吸塵ファン</InputLabel>
        <Grid>
          {masterDataDustCollectingFans.map((dustCollectingFan, index) => (
            <Grid key={dustCollectingFan.name}>
              <InputLabel
                htmlFor={`machineDustCollectingFanLinks[${index}][machinePartId]`}
                sx={labelGrayOutSx}>
                {dustCollectingFan.name} *
              </InputLabel>
              <CustomPlaceholderSelectInput
                type="formInput"
                placeholder="選択してください"
                key={index}
                source={`machineDustCollectingFanLinks[${index}][machinePartId]`}
                variant="outlined"
                label={false}
                sx={selectInputSx}
                choices={dustCollectingFanMachinePartsList}
                validate={required('エラー選択してください')}
                onChange={(event) =>
                  handleOnChangeMachineDustCollectingFanLink(event, index)
                }
                fullWidth
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid sx={gridSx}>
        <InputLabel sx={labelSx}>プッシュソレノイド</InputLabel>
        <Grid>
          {masterDataPushSolenoids.map((pushSolenoid, index) => (
            <Grid key={pushSolenoid.name}>
              <InputLabel
                htmlFor={`machinePushSolenoidLinks[${index}][machinePartId]`}
                sx={labelGrayOutSx}>
                {pushSolenoid.name} *
              </InputLabel>
              <CustomPlaceholderSelectInput
                type="formInput"
                placeholder="選択してください"
                key={index}
                source={`machinePushSolenoidLinks[${index}][machinePartId]`}
                variant="outlined"
                label={false}
                sx={selectInputSx}
                choices={pushSolenoidMachinePartsList}
                validate={required('エラー選択してください')}
                onChange={(event) =>
                  handleOnChangeMachinePushSolenoidLink(event, index)
                }
                fullWidth
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid sx={gridSx}>
        <InputLabel sx={labelSx}>ソレノイドバルブ</InputLabel>
        <Grid>
          {masterDataSolenoidValves.map((solenoidValve, index) => (
            <Grid key={solenoidValve.name}>
              <InputLabel
                htmlFor={`machineSolenoidValveLinks[${index}][machinePartId]`}
                sx={labelGrayOutSx}>
                {solenoidValve.name} *
              </InputLabel>
              <CustomPlaceholderSelectInput
                type="formInput"
                placeholder="選択してください"
                key={index}
                source={`machineSolenoidValveLinks[${index}][machinePartId]`}
                variant="outlined"
                label={false}
                sx={selectInputSx}
                choices={solenoidValveMachinePartsList}
                validate={required('エラー選択してください')}
                onChange={(event) =>
                  handleOnChangeMachineSolenoidValveLink(event, index)
                }
                fullWidth
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid sx={gridSx}>
        <InputLabel sx={labelSx}>リニアアクチュエーター</InputLabel>
        <Grid>
          {masterDataLinearActuators.map((linearActuator, index) => (
            <Grid key={linearActuator.name}>
              <InputLabel
                htmlFor={`machineLinearActuatorLinks[${index}][machinePartId]`}
                sx={labelGrayOutSx}>
                {linearActuator.name} *
              </InputLabel>
              <CustomPlaceholderSelectInput
                type="formInput"
                placeholder="選択してください"
                key={index}
                source={`machineLinearActuatorLinks[${index}][machinePartId]`}
                variant="outlined"
                label={false}
                sx={selectInputSx}
                choices={linearActuatorMachinePartsList}
                validate={required('エラー選択してください')}
                onChange={(event) =>
                  handleOnChangeMachineLinearActuatorLink(event, index)
                }
                fullWidth
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AddMachineModalMachinePartsInput
