import React from 'react'
import { Grid, Typography, Divider, Modal, Box } from '@mui/material'

import { MachineRecord } from '../../../types/records/machine-record'
import { MachinePartRecord } from '../../../types/records/machine-part-record'
import { masterDataChipPowderMotors } from './MasterDataChipPowderMotors'
import { masterDataDustCollectingFans } from './MasterDataDustCollectingFans'
import { masterDataLinearActuators } from './MasterDataLinearActuators'
import { masterDataPushSolenoids } from './MasterDataPushSolenoids'
import { masterDataSolenoidValves } from './MasterDataSolenoidValves'
import { masterDataTubingPumps } from './MasterDataTubingPumps'
import { masterDataVacuumPumps } from './MasterDataVacuumPumps'
import { TubingPumpRecord } from '../../../types/records/tubing_pump-record'
import { ChipPowderMotorRecord } from '../../../types/records/chip-powder-motor-record'
import { LinearActuatorRecord } from '../../../types/records/linear-actuator-record'
import { PushSolenoidRecord } from '../../../types/records/push-solenoid-record'
import { SolenoidValveRecord } from '../../../types/records/solenoid-valve-record'
import { VacuumPumpRecord } from '../../../types/records/vacuum-pump-record'
import { DustCollectingFanRecord } from '../../../types/records/dust-collecting-fan'
import CancelButton from '../../button/CancelButton'
import {
  modalWrapperSx,
  cancelButtonWrapperSx,
  confirmGridContainerSx,
  confirmLabelSx,
  confirmInputSx,
} from '../../../assets/sx/form'
import { modalTitleSx } from '../../../assets/sx/modalSx'

type Props = {
  open: boolean
  machine: MachineRecord
  machineParts: MachinePartRecord[]
  onClose: () => void
}

const ShowMachineModal: React.FC<Props> = ({
  open,
  machine,
  machineParts,
  onClose,
}) => {
  const machinePartsMap = new Map<number, MachinePartRecord>()
  machineParts.forEach((machinePart) => {
    machinePartsMap.set(machinePart.id, machinePart)
  })

  const tubePumpsMap = new Map<number, TubingPumpRecord>()
  masterDataTubingPumps.forEach((tubePump) => {
    tubePumpsMap.set(tubePump.id, tubePump)
  })

  const chipPowderMotorsMap = new Map<number, ChipPowderMotorRecord>()
  masterDataChipPowderMotors.forEach((chipPowderMotor) => {
    chipPowderMotorsMap.set(chipPowderMotor.id, chipPowderMotor)
  })

  const linearActuatorsMap = new Map<number, LinearActuatorRecord>()
  masterDataLinearActuators.forEach((linearActuator) => {
    linearActuatorsMap.set(linearActuator.id, linearActuator)
  })

  const pushSolenoidsMap = new Map<number, PushSolenoidRecord>()
  masterDataPushSolenoids.forEach((pushSolenoid) => {
    pushSolenoidsMap.set(pushSolenoid.id, pushSolenoid)
  })

  const solenoidValvesMap = new Map<number, SolenoidValveRecord>()
  masterDataSolenoidValves.forEach((solenoidValve) => {
    solenoidValvesMap.set(solenoidValve.id, solenoidValve)
  })

  const vacuumPumpsMap = new Map<number, VacuumPumpRecord>()
  masterDataVacuumPumps.forEach((vacuumPump) => {
    vacuumPumpsMap.set(vacuumPump.id, vacuumPump)
  })

  const dustCollectingFansMap = new Map<number, DustCollectingFanRecord>()
  masterDataDustCollectingFans.forEach((dustCollectingFan) => {
    dustCollectingFansMap.set(dustCollectingFan.id, dustCollectingFan)
  })

  return (
    machine && (
      <>
        <Modal open={open} onClose={onClose}>
          <Box sx={modalWrapperSx}>
            <Grid>
              <Typography sx={modalTitleSx}>
                マシンマスターを確認する
              </Typography>
              <Grid container sx={confirmGridContainerSx}>
                <Grid sx={confirmLabelSx}>マシン シリアル</Grid>
                <Grid sx={confirmInputSx}>{machine.serial}</Grid>
              </Grid>
              <Grid container sx={confirmGridContainerSx}>
                <Grid sx={confirmLabelSx}>機種コード</Grid>
                <Grid sx={confirmInputSx}>{machine.model}</Grid>
              </Grid>
              <Grid container sx={confirmGridContainerSx}>
                <Grid sx={confirmLabelSx}>SIM IMSI</Grid>
                <Grid sx={confirmInputSx}>{machine.simImsi}</Grid>
              </Grid>
              <Grid container sx={confirmGridContainerSx}>
                <Grid sx={confirmLabelSx}>SIM ICCID</Grid>
                <Grid sx={confirmInputSx}>{machine.iccid}</Grid>
              </Grid>
              <Grid container sx={confirmGridContainerSx}>
                <Grid sx={confirmLabelSx}>製造年月日</Grid>
                <Grid sx={confirmInputSx}>
                  {machine.productionDate && machine.productionDate.toString()}
                </Grid>
              </Grid>
              <Grid container sx={confirmGridContainerSx}>
                <Grid sx={confirmLabelSx}>店舗名</Grid>
                <Grid sx={confirmInputSx}>{machine.store.name}</Grid>
              </Grid>
              <Divider sx={{ mt: '40px', mb: '40px' }} />
              <Grid>
                <Typography sx={modalTitleSx}>
                  シロップ用チューブポンプ
                </Typography>
                <>
                  {machine.machineTubingPumpLinks?.map((link) => {
                    const tubingPump = tubePumpsMap.get(link.tubingPumpID)
                    const machinePart = machinePartsMap.get(link.machinePartID)
                    return (
                      <Grid
                        sx={confirmGridContainerSx}
                        key={link.tubingPumpID}
                        container>
                        <Grid sx={confirmLabelSx}>{tubingPump?.name + ''}</Grid>
                        <Grid sx={{ ...confirmInputSx, fontSize: '14px' }}>
                          {machinePart?.drawingNumber + ' ' + machinePart?.name}
                        </Grid>
                      </Grid>
                    )
                  })}
                </>
              </Grid>
              <Divider sx={{ mt: '40px', mb: '40px' }} />
              <Grid>
                <Typography sx={modalTitleSx}>
                  チップ・パウダー用モーター
                </Typography>
                <>
                  {machine.machineChipPowderMotorLinks?.map((link) => {
                    const chipPowderMotor = chipPowderMotorsMap.get(
                      link.chipPowderMotorID,
                    )
                    const machinePart = machinePartsMap.get(link.machinePartID)
                    return (
                      <Grid
                        sx={confirmGridContainerSx}
                        key={link.chipPowderMotorID}
                        container>
                        <Grid sx={confirmLabelSx}>
                          {chipPowderMotor?.name + ''}
                        </Grid>
                        <Grid sx={{ ...confirmInputSx, fontSize: '14px' }}>
                          {machinePart?.drawingNumber + ' ' + machinePart?.name}
                        </Grid>
                      </Grid>
                    )
                  })}
                </>
              </Grid>
              <Divider sx={{ mt: '40px', mb: '40px' }} />
              <Grid>
                <Typography sx={modalTitleSx}>真空ポンプ</Typography>
                <>
                  {machine.machineVacuumPumpLinks?.map((link) => {
                    const vacuumPump = vacuumPumpsMap.get(link.vacuumPumpID)
                    const machinePart = machinePartsMap.get(link.machinePartID)
                    return (
                      <Grid
                        sx={confirmGridContainerSx}
                        key={link.vacuumPumpID}
                        container>
                        <Grid sx={confirmLabelSx}>{vacuumPump?.name + ''}</Grid>
                        <Grid sx={{ ...confirmInputSx, fontSize: '14px' }}>
                          {machinePart?.drawingNumber + ' ' + machinePart?.name}
                        </Grid>
                      </Grid>
                    )
                  })}
                </>
              </Grid>
              <Divider sx={{ mt: '40px', mb: '40px' }} />
              <Grid>
                <Typography sx={modalTitleSx}>吸塵ファン</Typography>
                <>
                  {machine.machineDustCollectingFanLinks?.map((link) => {
                    const dustCollectingFan = dustCollectingFansMap.get(
                      link.dustCollectingFanID,
                    )
                    const machinePart = machinePartsMap.get(link.machinePartID)
                    return (
                      <Grid
                        sx={confirmGridContainerSx}
                        key={link.dustCollectingFanID}
                        container>
                        <Grid sx={confirmLabelSx}>
                          {dustCollectingFan?.name + ''}
                        </Grid>
                        <Grid sx={{ ...confirmInputSx, fontSize: '14px' }}>
                          {machinePart?.drawingNumber + ' ' + machinePart?.name}
                        </Grid>
                      </Grid>
                    )
                  })}
                </>
              </Grid>
              <Divider sx={{ mt: '40px', mb: '40px' }} />
              <Grid>
                <Typography sx={modalTitleSx}>プッシュソレノイド</Typography>
                <>
                  {machine.machinePushSolenoidLinks?.map((link) => {
                    const pushSolenoidValve = pushSolenoidsMap.get(
                      link.pushSolenoidID,
                    )
                    const machinePart = machinePartsMap.get(link.machinePartID)
                    return (
                      <Grid
                        sx={confirmGridContainerSx}
                        key={link.pushSolenoidID}
                        container>
                        <Grid sx={confirmLabelSx}>
                          {pushSolenoidValve?.name + ''}
                        </Grid>
                        <Grid sx={{ ...confirmInputSx, fontSize: '14px' }}>
                          {machinePart?.drawingNumber + ' ' + machinePart?.name}
                        </Grid>
                      </Grid>
                    )
                  })}
                </>
              </Grid>
              <Divider sx={{ mt: '40px', mb: '40px' }} />
              <Grid>
                <Typography sx={modalTitleSx}>ソレノイドバルブ</Typography>
                <>
                  {machine.machineSolenoidValveLinks?.map((link) => {
                    const solenoidValve = solenoidValvesMap.get(
                      link.solenoidValveID,
                    )
                    const machinePart = machinePartsMap.get(link.machinePartID)
                    return (
                      <Grid
                        sx={confirmGridContainerSx}
                        key={link.solenoidValveID}
                        container>
                        <Grid sx={confirmLabelSx}>
                          {solenoidValve?.name + ''}
                        </Grid>
                        <Grid sx={{ ...confirmInputSx, fontSize: '14px' }}>
                          {machinePart?.drawingNumber + ' ' + machinePart?.name}
                        </Grid>
                      </Grid>
                    )
                  })}
                </>
              </Grid>
              <Divider sx={{ mt: '40px', mb: '40px' }} />
              <Grid>
                <Typography sx={modalTitleSx}>
                  リニアアクチュエーター
                </Typography>
                <>
                  {machine.machineLinearActuatorLinks?.map((link) => {
                    const linearActuator = linearActuatorsMap.get(
                      link.linearActuatorID,
                    )
                    const machinePart = machinePartsMap.get(link.machinePartID)
                    return (
                      <Grid
                        sx={confirmGridContainerSx}
                        key={link.linearActuatorID}
                        container>
                        <Grid sx={confirmLabelSx}>
                          {linearActuator?.name + ''}
                        </Grid>
                        <Grid sx={{ ...confirmInputSx, fontSize: '14px' }}>
                          {machinePart?.drawingNumber + ' ' + machinePart?.name}
                        </Grid>
                      </Grid>
                    )
                  })}
                </>
              </Grid>
              <Divider sx={{ mt: '40px', mb: '40px' }} />
            </Grid>
            <Grid container justifyContent="flex-end">
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton onClick={() => onClose()}>閉じる</CancelButton>
              </Box>
            </Grid>
          </Box>
        </Modal>
      </>
    )
  )
}

export default ShowMachineModal
