import React from 'react'
import { Grid, InputLabel } from '@mui/material'
import { required, TextInput } from 'react-admin'
import {
  labelGrayOutSx,
  textInputSx,
  selectInputSx,
  gridSx,
} from '../../../assets/sx/form'
import {
  MachinePartParams,
  MachinePartRecord,
  MachinePartTypeRecord,
} from '../../../types/records/machine-part-record'
import SelectInputMaximumValue from './SelectInputMaximumValue'
import CustomPlaceholderSelectInput from '../../customComponent/CustomPlaceholderSelectInput'

type Props = {
  machinePartTypes: MachinePartTypeRecord[]
  setMachinePartParam: React.Dispatch<React.SetStateAction<MachinePartParams>>
  machinePartParam: MachinePartParams
  machinePart?: MachinePartRecord | undefined
}

const MachinePartModalBasicInfo: React.FC<Props> = ({
  machinePartTypes,
  setMachinePartParam,
  machinePartParam,
  machinePart,
}) => {
  return (
    <>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="drawingNumber" sx={labelGrayOutSx}>
          図番*
        </InputLabel>
        <TextInput
          source="drawingNumber"
          variant="outlined"
          label={false}
          sx={textInputSx}
          validate={[required('エラー入力してください')]}
          fullWidth
          defaultValue={machinePart?.drawingNumber}
          onChange={(e) =>
            setMachinePartParam({
              ...machinePartParam,
              drawingNumber: e.target.value,
            })
          }
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="machinePartTypeID" sx={labelGrayOutSx}>
          機械部品種別名*
        </InputLabel>
        <CustomPlaceholderSelectInput
          type="formInput"
          placeholder="選択してください"
          source="machinePartTypeID"
          variant="outlined"
          label={false}
          sx={selectInputSx}
          validate={required('エラー選択してください')}
          choices={machinePartTypes}
          fullWidth
          defaultValue={machinePart?.machinePartType?.id}
          onChange={(e) => {
            setMachinePartParam({
              ...machinePartParam,
              machinePartTypeID: e.target.value,
            })
          }}
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="name" sx={labelGrayOutSx}>
          機械部品名*
        </InputLabel>
        <TextInput
          source="name"
          variant="outlined"
          label={false}
          sx={textInputSx}
          validate={required('エラー入力してください')}
          fullWidth
          defaultValue={machinePart?.name}
          onChange={(e) =>
            setMachinePartParam({ ...machinePartParam, name: e.target.value })
          }
        />
      </Grid>
      <SelectInputMaximumValue
        setMachinePartParam={setMachinePartParam}
        machinePartParam={machinePartParam}
        machinePart={machinePart}
        machinePartTypes={machinePartTypes}
      />
    </>
  )
}

export default MachinePartModalBasicInfo
