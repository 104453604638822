import React from 'react'
import { Grid, Typography, InputLabel, Divider } from '@mui/material'
import { useEffect, useState } from 'react'
import { SelectInput, NumberInput } from 'react-admin'
import {
  MaintenanceLogRecord,
  MaintenanceLogParams,
  MachineLinksInfoRecord,
} from '../../../types/records/maintenance-log-record'
import { MachinePartRecord } from '../../../types/records/machine-part-record'
import { MachineRecord } from '../../../types/records/machine-record'
import { MachineSolenoidValveLinkRecord } from '../../../types/records/machine-solenoid-valve-link-record'
import { MachineTubingPumpLinkRecord } from '../../../types/records/machine-tubing-pump-link-record'
import { MachineChipPowderMotorLinkRecord } from '../../../types/records/machine-chip-powder-motor-link-record'
import { MachineVacuumPumpLinkRecord } from '../../../types/records/machine-vacuum-pump-link-record'
import { MachinePushSolenoidLinkRecord } from '../../../types/records/machine-push-solenoid-link-record'
import { MachineLinearActuatorLinkRecord } from '../../../types/records/machine-linear-actuator-link-record'
import { MachineDustCollectingFanLinkRecord } from '../../../types/records/machine-dust-collecting-fan-link-record'
import { labelGrayOutSx, selectInputSx } from '../../../assets/sx/form'
import CustomPlaceholderSelectInput from '../../customComponent/CustomPlaceholderSelectInput'
import { modalDividerSx, modalSubtitleSx } from '../../../assets/sx/modalSx'
import { dataProvider } from '../../../provider/data-provider'

type Props = {
  machineParts: MachinePartRecord[]
  machines: MachineRecord[]
  maintenanceLogParams: MaintenanceLogParams
  maintenanceLog: MaintenanceLogRecord
  hasConfirmed: boolean
  setMaintenanceLogParams: React.Dispatch<
    React.SetStateAction<MaintenanceLogParams>
  >
}

const EditMaintenanceLogModalReplacementPartsInput: React.FC<Props> = ({
  machineParts,
  maintenanceLog,
  maintenanceLogParams,
  machines,
  hasConfirmed,
  setMaintenanceLogParams,
}) => {
  const [machineLinksInfos, setMachineLinksInfo] =
    useState<MachineLinksInfoRecord>()

  const id = maintenanceLog.id
  const getMachineLinkInfos = () => {
    dataProvider
      .getMachineLinkInfos(id)
      .then((res: { data: MachineLinksInfoRecord }) => {
        setMachineLinksInfo(res.data)
      })
  }

  useEffect(() => {
    if (hasConfirmed !== false) {
      return
    }

    // linksの更新
    // 0値を設定し確認画面で部品名のみを表示させる
    const updatedTubingPumpLinks =
      maintenanceLog.machine.machineTubingPumpLinks.map((tubingPumpLink) => ({
        tubingPumpID: tubingPumpLink.tubingPumpID,
        machinePartID: 0,
      }))

    const updatedChipPowderMotorLinks =
      maintenanceLog.machine.machineChipPowderMotorLinks.map(
        (chipPowderMotorLink) => ({
          chipPowderMotorID: chipPowderMotorLink.chipPowderMotorID,
          machinePartID: 0,
        }),
      )

    const updatedVacuumPumpLinks =
      maintenanceLog.machine.machineVacuumPumpLinks.map((vacuumPumpLink) => ({
        vacuumPumpID: vacuumPumpLink.vacuumPumpID,
        machinePartID: 0,
      }))

    const updatedPushSolenoidLinks =
      maintenanceLog.machine.machinePushSolenoidLinks.map(
        (pushSolenoidLink) => ({
          pushSolenoidID: pushSolenoidLink.pushSolenoidID,
          machinePartID: 0,
        }),
      )

    const updatedLinearActuatorLinks =
      maintenanceLog.machine.machineLinearActuatorLinks.map(
        (linearActuatorLink) => ({
          linearActuatorID: linearActuatorLink.linearActuatorID,
          machinePartID: 0,
        }),
      )

    const updatedDustCollectingFanLinks =
      maintenanceLog.machine.machineDustCollectingFanLinks.map(
        (dustCollectingFanLink) => ({
          dustCollectingFanID: dustCollectingFanLink.dustCollectingFanID,
          machinePartID: 0,
        }),
      )

    const updatedSolenoidValveLinks =
      maintenanceLog.machine.machineSolenoidValveLinks.map(
        (solenoidValveLink) => ({
          solenoidValveID: solenoidValveLink.solenoidValveID,
          machinePartID: 0,
        }),
      )

    setMaintenanceLogParams({
      ...maintenanceLogParams,

      // リンクステータスの更新
      newMachineTubingPumpLinks: updatedTubingPumpLinks,
      newMachineChipPowderMotorLinks: updatedChipPowderMotorLinks,
      newMachineVacuumPumpLinks: updatedVacuumPumpLinks,
      newMachinePushSolenoidLinks: updatedPushSolenoidLinks,
      newMachineLinearActuatorLinks: updatedLinearActuatorLinks,
      newMachineDustCollectingFanLinks: updatedDustCollectingFanLinks,
      newMachineSolenoidValveLinks: updatedSolenoidValveLinks,
    })
  }, [])

  useEffect(() => {
    getMachineLinkInfos()
  }, [])

  // 現在の紐付けを格納する配列
  const [defaultRecentTubingPumpValues, setDefaultRecentTubingPumpValues] =
    useState(
      new Array(
        machineLinksInfos?.previousMachineTubingPumpLinks?.length || 0,
      ).fill(null),
    )

  const [
    defaultRecentChipPowderMotorValues,
    setDefaultRecentChipPowderMotorValues,
  ] = useState(
    new Array(
      machineLinksInfos?.previousMachineChipPowderMotorLinks?.length || 0,
    ).fill(null),
  )

  const [defaultRecentVacuumPumpValues, setDefaultRecentVacuumPumpValues] =
    useState(
      new Array(
        machineLinksInfos?.previousMachineVacuumPumpLinks?.length || 0,
      ).fill(null),
    )

  const [
    defaultRecentDustCollectingFanValues,
    setDefaultRecentDustCollectingFanValues,
  ] = useState(
    new Array(
      machineLinksInfos?.previousMachineDustCollectingFanLinks?.length || 0,
    ).fill(null),
  )

  const [defaultRecentPushSolenoidValues, setDefaultRecentPushSolenoidValues] =
    useState(
      new Array(
        machineLinksInfos?.previousMachinePushSolenoidLinks?.length || 0,
      ).fill(null),
    )

  const [
    defaultRecentSolenoidValveValues,
    setDefaultRecentSolenoidValveValues,
  ] = useState(
    new Array(
      machineLinksInfos?.previousMachineSolenoidValveLinks?.length || 0,
    ).fill(null),
  )

  const [
    defaultRecentLinearActuatorValues,
    setDefaultRecentLinearActuatorValues,
  ] = useState(
    new Array(
      machineLinksInfos?.previousMachineLinearActuatorLinks?.length || 0,
    ).fill(null),
  )

  // 各部品のdefaultValuesの作成
  useEffect(() => {
    if (hasConfirmed) return

    if (machineLinksInfos?.recentMachineTubingPumpLinks) {
      machineLinksInfos.recentMachineTubingPumpLinks.forEach(
        ({ machinePartID, id, tubingPumpID }) => {
          if (!machinePartID || id === undefined) return

          // IDから1を引いてインデックスを取得
          const index = tubingPumpID - 1
          defaultRecentTubingPumpValues[index] = machinePartID

          // newMachineTubingPumpLinksを更新
          maintenanceLogParams.newMachineTubingPumpLinks?.forEach((link) => {
            if (link.tubingPumpID === tubingPumpID) {
              link.machinePartID = machinePartID
            }
          })
        },
      )
    }

    if (machineLinksInfos?.recentMachineChipPowderMotorLinks) {
      machineLinksInfos.recentMachineChipPowderMotorLinks.forEach(
        ({ machinePartID, id, chipPowderMotorID }) => {
          if (!machinePartID || id === undefined) return

          // IDから1を引いてインデックスを取得
          const index = chipPowderMotorID - 1
          defaultRecentChipPowderMotorValues[index] = machinePartID

          // newMachineChipPowderMotorLinksを更新
          maintenanceLogParams.newMachineChipPowderMotorLinks?.forEach(
            (link) => {
              if (link.chipPowderMotorID === chipPowderMotorID) {
                link.machinePartID = machinePartID
              }
            },
          )
        },
      )
    }

    if (machineLinksInfos?.recentMachineVacuumPumpLinks) {
      machineLinksInfos.recentMachineVacuumPumpLinks.forEach(
        ({ machinePartID, id, vacuumPumpID }) => {
          if (!machinePartID || id === undefined) return

          // IDから1を引いてインデックスを取得
          const index = vacuumPumpID - 1
          defaultRecentVacuumPumpValues[index] = machinePartID

          // newMachineVacuumPumpLinksを更新
          maintenanceLogParams.newMachineVacuumPumpLinks?.forEach((link) => {
            if (link.vacuumPumpID === vacuumPumpID) {
              link.machinePartID = machinePartID
            }
          })
        },
      )
    }

    if (machineLinksInfos?.recentMachineDustCollectingFanLinks) {
      machineLinksInfos.recentMachineDustCollectingFanLinks.forEach(
        ({ machinePartID, id, dustCollectingFanID }) => {
          if (!machinePartID || id === undefined) return

          // IDから1を引いてインデックスを取得
          const index = dustCollectingFanID - 1
          defaultRecentDustCollectingFanValues[index] = machinePartID

          // newMachineDustCollectingFanLinksを更新
          maintenanceLogParams.newMachineDustCollectingFanLinks?.forEach(
            (link) => {
              if (link.dustCollectingFanID === dustCollectingFanID) {
                link.machinePartID = machinePartID
              }
            },
          )
        },
      )
    }

    if (machineLinksInfos?.recentMachinePushSolenoidLinks) {
      machineLinksInfos.recentMachinePushSolenoidLinks.forEach(
        ({ machinePartID, id, pushSolenoidID }) => {
          if (!machinePartID || id === undefined) return

          // IDから1を引いてインデックスを取得
          const index = pushSolenoidID - 1
          defaultRecentPushSolenoidValues[index] = machinePartID

          // newMachinePushSolenoidLinksを更新
          maintenanceLogParams.newMachinePushSolenoidLinks?.forEach((link) => {
            if (link.pushSolenoidID === pushSolenoidID) {
              link.machinePartID = machinePartID
            }
          })
        },
      )
    }

    if (machineLinksInfos?.recentMachineSolenoidValveLinks) {
      machineLinksInfos.recentMachineSolenoidValveLinks.forEach(
        ({ machinePartID, id, solenoidValveID }) => {
          if (!machinePartID || id === undefined) return

          // IDから1を引いてインデックスを取得
          const index = solenoidValveID - 1
          defaultRecentSolenoidValveValues[index] = machinePartID

          // newMachineSolenoidValveLinksを更新
          maintenanceLogParams.newMachineSolenoidValveLinks?.forEach((link) => {
            if (link.solenoidValveID === solenoidValveID) {
              link.machinePartID = machinePartID
            }
          })
        },
      )
    }

    if (machineLinksInfos?.recentMachineLinearActuatorLinks) {
      machineLinksInfos.recentMachineLinearActuatorLinks.forEach(
        ({ machinePartID, id, linearActuatorID }) => {
          if (!machinePartID || id === undefined) return

          // IDから1を引いてインデックスを取得
          const index = linearActuatorID - 1
          defaultRecentLinearActuatorValues[index] = machinePartID

          // newMachineLinearActuatorLinksを更新
          maintenanceLogParams.newMachineLinearActuatorLinks?.forEach(
            (link) => {
              if (link.linearActuatorID === linearActuatorID) {
                link.machinePartID = machinePartID
              }
            },
          )
        },
      )
    }

    // ステートを更新
    setDefaultRecentTubingPumpValues(defaultRecentTubingPumpValues)
    setDefaultRecentChipPowderMotorValues(defaultRecentChipPowderMotorValues)
    setDefaultRecentVacuumPumpValues(defaultRecentVacuumPumpValues)
    setDefaultRecentDustCollectingFanValues(
      defaultRecentDustCollectingFanValues,
    )
    setDefaultRecentPushSolenoidValues(defaultRecentPushSolenoidValues)
    setDefaultRecentSolenoidValveValues(defaultRecentSolenoidValveValues)
    setDefaultRecentLinearActuatorValues(defaultRecentLinearActuatorValues)
  }, [machineLinksInfos, open])

  return (
    <Grid width={772} marginLeft={2}>
      <Typography sx={{ fontSize: '16px', fontWeight: 700, mb: '10px' }}>
        メンテナンスログを編集する 2/2
      </Typography>

      <Grid container wrap="wrap">
        {machines
          ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
          ?.map((machine: MachineRecord) =>
            machine.machineTubingPumpLinks?.map(
              (
                tubingPumpLink: MachineTubingPumpLinkRecord,
                tubingPumpIndex: number,
              ) => {
                const previousMachinePartID =
                  machineLinksInfos?.previousMachineTubingPumpLinks?.[
                    tubingPumpIndex
                  ]?.machinePartID

                // 登録前の紐付けを格納する配列
                const defaultPreviousValues = new Array(
                  machineLinksInfos?.previousMachineTubingPumpLinks?.length ||
                    0,
                ).fill(null)

                // 登録前の紐付けを格納する処理
                machineLinksInfos?.previousMachineTubingPumpLinks?.forEach(
                  (previousLink) => {
                    if (
                      previousLink.machinePartID &&
                      previousLink.id !== undefined
                    ) {
                      const index = previousLink.tubingPumpID - 1 // IDから1を引いてインデックスを取得
                      defaultPreviousValues[index] = previousLink.machinePartID
                    }
                  },
                )

                if (previousMachinePartID) {
                  return (
                    <Grid
                      item
                      sx={{
                        width: '100%',
                        marginBottom: '24px',
                      }}
                      key={`${tubingPumpLink.tubingPump.name}-${tubingPumpIndex}`}>
                      {tubingPumpIndex === 0 && (
                        <Typography sx={modalSubtitleSx}>
                          シロップ用チューブポンプ
                        </Typography>
                      )}
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <InputLabel
                            htmlFor="newTubingPumpReplacementLogs"
                            sx={labelGrayOutSx}>
                            {tubingPumpLink.tubingPump.name + ' 交換前'}
                          </InputLabel>
                          <SelectInput
                            source={`newTubingPumpReplacementLogs[[${tubingPumpIndex}]oldMachinePartID]`}
                            choices={machineLinksInfos?.previousMachineTubingPumpLinks?.map(
                              (part) => {
                                const matchingMachinePart = machineParts.find(
                                  (machinePart) =>
                                    machinePart.id === part.machinePartID,
                                )

                                const displayName = matchingMachinePart
                                  ? `${matchingMachinePart.drawingNumber}  ${matchingMachinePart.name}`
                                  : part.machinePartID

                                return {
                                  id: part.machinePartID,
                                  name: displayName,
                                }
                              },
                            )}
                            label={false}
                            sx={{ ...selectInputSx, width: '350px' }}
                            disabled
                            defaultValue={
                              defaultPreviousValues[tubingPumpIndex]
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel
                            htmlFor="newMachineTubingPumpLinks"
                            sx={labelGrayOutSx}>
                            {tubingPumpLink.tubingPump.name + ' 交換後'}
                          </InputLabel>
                          <CustomPlaceholderSelectInput
                            type="formInput"
                            placeholder="選択してください"
                            source={`newMachineTubingPumpLinks[machinePartID[${tubingPumpIndex}]]`}
                            choices={machineParts
                              .filter((part) => part.machinePartType.id === 1)
                              .map((part) => ({
                                id: part.id,
                                name: `${part.drawingNumber}  ${part.name}`,
                              }))}
                            defaultValue={
                              defaultRecentTubingPumpValues[tubingPumpIndex]
                            }
                            label={false}
                            sx={{ ...selectInputSx, width: '350px' }}
                            onChange={(e) => {
                              const updatedMachineTubingPumpLinks = [
                                ...(maintenanceLogParams.newMachineTubingPumpLinks ||
                                  []),
                              ]
                              updatedMachineTubingPumpLinks[tubingPumpIndex] = {
                                ...updatedMachineTubingPumpLinks[
                                  tubingPumpIndex
                                ],
                                machinePartID: Number(e.target.value),
                                tubingPumpID: tubingPumpLink.tubingPumpID,
                              }

                              setMaintenanceLogParams((prev) => ({
                                ...prev,
                                newMachineTubingPumpLinks:
                                  updatedMachineTubingPumpLinks,
                              }))
                            }}
                          />
                        </Grid>
                        <Grid>
                          <NumberInput
                            source={`newMachineTubingPumpLinks[tubingPumpID[${tubingPumpIndex}]]`}
                            label={false}
                            defaultValue={tubingPumpLink.tubingPumpID}
                            disabled
                            sx={{ display: 'none' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                }
              },
            ),
          )}
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid container wrap="wrap">
        {machines
          ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
          ?.map((machine: MachineRecord) =>
            machine.machineChipPowderMotorLinks?.map(
              (
                chipPowderMotorLink: MachineChipPowderMotorLinkRecord,
                chipPowderMotorIndex: number,
              ) => {
                const previousMachinePartID =
                  machineLinksInfos?.previousMachineChipPowderMotorLinks?.[
                    chipPowderMotorIndex
                  ]?.machinePartID

                // 登録前の紐付けを格納する配列
                const defaultPreviousValues = new Array(
                  machineLinksInfos?.previousMachineChipPowderMotorLinks
                    ?.length || 0,
                ).fill(null)

                // 登録前の紐付けを格納する処理
                machineLinksInfos?.previousMachineChipPowderMotorLinks?.forEach(
                  (previousLink) => {
                    if (
                      previousLink.machinePartID &&
                      previousLink.id !== undefined
                    ) {
                      const index = previousLink.chipPowderMotorID - 1 // IDから1を引いてインデックスを取得
                      defaultPreviousValues[index] = previousLink.machinePartID
                    }
                  },
                )

                if (previousMachinePartID !== undefined) {
                  return (
                    <Grid
                      item
                      sx={{
                        width: '100%',
                        marginBottom: '24px',
                      }}
                      key={`${chipPowderMotorLink.chipPowderMotor.name}-${chipPowderMotorIndex}`}>
                      {chipPowderMotorIndex === 0 && (
                        <Typography sx={modalSubtitleSx}>
                          チップ・パウダー用モーター
                        </Typography>
                      )}
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <InputLabel
                            htmlFor="newChipPowderMotorReplacementLogs"
                            sx={labelGrayOutSx}>
                            {chipPowderMotorLink.chipPowderMotor.name +
                              ' 交換前'}
                          </InputLabel>
                          <SelectInput
                            source={`newChipPowderMotorReplacementLogs[[${chipPowderMotorIndex}]oldMachinePartID]`}
                            choices={machineLinksInfos?.previousMachineChipPowderMotorLinks?.map(
                              (part) => {
                                const matchingMachinePart = machineParts.find(
                                  (machinePart) =>
                                    machinePart.id === part.machinePartID,
                                )

                                const displayName = matchingMachinePart
                                  ? `${matchingMachinePart.drawingNumber}  ${matchingMachinePart.name}`
                                  : part.machinePartID

                                return {
                                  id: part.machinePartID,
                                  name: displayName,
                                }
                              },
                            )}
                            label={false}
                            sx={{ ...selectInputSx, width: '350px' }}
                            disabled
                            defaultValue={
                              defaultPreviousValues[chipPowderMotorIndex]
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel
                            htmlFor="newMachineChipPowderMotorLinks"
                            sx={labelGrayOutSx}>
                            {chipPowderMotorLink.chipPowderMotor.name +
                              ' 交換後'}
                          </InputLabel>
                          <CustomPlaceholderSelectInput
                            type="formInput"
                            placeholder="選択してください"
                            source={`newMachineChipPowderMotorLinks[machinePartID[${chipPowderMotorIndex}]]`}
                            choices={machineParts
                              .filter((part) => part.machinePartType.id === 2)
                              .map((part) => ({
                                id: part.id,
                                name: `${part.drawingNumber}  ${part.name}`,
                              }))}
                            defaultValue={
                              defaultRecentChipPowderMotorValues[
                                chipPowderMotorIndex
                              ]
                            }
                            label={false}
                            sx={{ ...selectInputSx, width: '350px' }}
                            onChange={(e) => {
                              const updatedMachineChipPowderMotorLinks = [
                                ...(maintenanceLogParams.newMachineChipPowderMotorLinks ||
                                  []),
                              ]
                              updatedMachineChipPowderMotorLinks[
                                chipPowderMotorIndex
                              ] = {
                                ...updatedMachineChipPowderMotorLinks[
                                  chipPowderMotorIndex
                                ],
                                machinePartID: Number(e.target.value),
                                chipPowderMotorID:
                                  chipPowderMotorLink.chipPowderMotorID,
                              }

                              setMaintenanceLogParams((prev) => ({
                                ...prev,
                                newMachineChipPowderMotorLinks:
                                  updatedMachineChipPowderMotorLinks,
                              }))
                            }}
                          />
                        </Grid>
                        <Grid>
                          <NumberInput
                            source={`newMachineChipPowderMotorLinks[chipPowderMotorID[${chipPowderMotorIndex}]]`}
                            label={false}
                            defaultValue={chipPowderMotorLink.chipPowderMotorID}
                            disabled
                            sx={{ display: 'none' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                }
              },
            ),
          )}
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid container wrap="wrap">
        {machines
          ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
          ?.map((machine: MachineRecord) =>
            machine.machineVacuumPumpLinks?.map(
              (
                vacuumPumpLink: MachineVacuumPumpLinkRecord,
                vacuumPumpIndex: number,
              ) => {
                const previousMachinePartID =
                  machineLinksInfos?.previousMachineVacuumPumpLinks?.[
                    vacuumPumpIndex
                  ]?.machinePartID

                // 登録前の紐付けを格納する配列
                const defaultPreviousValues = new Array(
                  machineLinksInfos?.previousMachineVacuumPumpLinks?.length ||
                    0,
                ).fill(null)

                // 登録前の紐付けを格納する処理
                machineLinksInfos?.previousMachineVacuumPumpLinks?.forEach(
                  (previousLink) => {
                    if (
                      previousLink.machinePartID &&
                      previousLink.id !== undefined
                    ) {
                      const index = previousLink.vacuumPumpID - 1 // IDから1を引いてインデックスを取得
                      defaultPreviousValues[index] = previousLink.machinePartID
                    }
                  },
                )

                if (previousMachinePartID) {
                  return (
                    <Grid
                      item
                      sx={{
                        width: '100%',
                        marginBottom: '24px',
                      }}
                      key={`${vacuumPumpLink.vacuumPump.name}-${vacuumPumpIndex}`}>
                      {vacuumPumpIndex === 0 && (
                        <Typography sx={modalSubtitleSx}>真空ポンプ</Typography>
                      )}
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <InputLabel
                            htmlFor="newVacuumPumpReplacementLogs"
                            sx={labelGrayOutSx}>
                            {vacuumPumpLink.vacuumPump.name + ' 交換前'}
                          </InputLabel>
                          <SelectInput
                            source={`newVacuumPumpReplacementLogs[[${vacuumPumpIndex}]oldMachinePartID]`}
                            choices={machineLinksInfos?.previousMachineVacuumPumpLinks?.map(
                              (part) => {
                                const matchingMachinePart = machineParts.find(
                                  (machinePart) =>
                                    machinePart.id === part.machinePartID,
                                )

                                const displayName = matchingMachinePart
                                  ? `${matchingMachinePart.drawingNumber}  ${matchingMachinePart.name}`
                                  : part.machinePartID

                                return {
                                  id: part.machinePartID,
                                  name: displayName,
                                }
                              },
                            )}
                            label={false}
                            sx={{ ...selectInputSx, width: '350px' }}
                            disabled
                            defaultValue={
                              defaultPreviousValues[vacuumPumpIndex]
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel
                            htmlFor="newMachineVacuumPumpLinks"
                            sx={labelGrayOutSx}>
                            {vacuumPumpLink.vacuumPump.name + ' 交換後'}
                          </InputLabel>
                          <CustomPlaceholderSelectInput
                            type="formInput"
                            placeholder="選択してください"
                            source={`newMachineVacuumPumpLinks[machinePartID[${vacuumPumpIndex}]]`}
                            choices={machineParts
                              .filter((part) => part.machinePartType.id === 3)
                              .map((part) => ({
                                id: part.id,
                                name: `${part.drawingNumber}  ${part.name}`,
                              }))}
                            defaultValue={
                              defaultRecentVacuumPumpValues[vacuumPumpIndex]
                            }
                            label={false}
                            sx={{ ...selectInputSx, width: '350px' }}
                            onChange={(e) => {
                              const updatedMachineVacuumPumpLinks = [
                                ...(maintenanceLogParams.newMachineVacuumPumpLinks ||
                                  []),
                              ]
                              updatedMachineVacuumPumpLinks[vacuumPumpIndex] = {
                                ...updatedMachineVacuumPumpLinks[
                                  vacuumPumpIndex
                                ],
                                machinePartID: Number(e.target.value),
                                vacuumPumpID: vacuumPumpLink.vacuumPumpID,
                              }

                              setMaintenanceLogParams((prev) => ({
                                ...prev,
                                newMachineVacuumPumpLinks:
                                  updatedMachineVacuumPumpLinks,
                              }))
                            }}
                          />
                        </Grid>
                        <Grid>
                          <NumberInput
                            source={`newMachineVacuumPumpLinks[vacuumPumpID[${vacuumPumpIndex}]]`}
                            label={false}
                            defaultValue={vacuumPumpLink.vacuumPumpID}
                            disabled
                            sx={{ display: 'none' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                }
              },
            ),
          )}
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid container wrap="wrap">
        {machines
          ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
          ?.map((machine: MachineRecord) =>
            machine.machineDustCollectingFanLinks?.map(
              (
                dustCollectingFanLink: MachineDustCollectingFanLinkRecord,
                dustCollectingFanIndex: number,
              ) => {
                const previousMachinePartID =
                  machineLinksInfos?.previousMachineDustCollectingFanLinks?.[
                    dustCollectingFanIndex
                  ]?.machinePartID

                // 登録前の紐付けを格納する配列
                const defaultPreviousValues = new Array(
                  machineLinksInfos?.previousMachineDustCollectingFanLinks
                    ?.length || 0,
                ).fill(null)

                // 登録前の紐付けを格納する処理
                machineLinksInfos?.previousMachineDustCollectingFanLinks?.forEach(
                  (previousLink) => {
                    if (
                      previousLink.machinePartID &&
                      previousLink.id !== undefined
                    ) {
                      const index = previousLink.dustCollectingFanID - 1 // IDから1を引いてインデックスを取得
                      defaultPreviousValues[index] = previousLink.machinePartID
                    }
                  },
                )

                if (previousMachinePartID) {
                  return (
                    <Grid
                      item
                      sx={{
                        width: '100%',
                        marginBottom: '24px',
                      }}
                      key={`${dustCollectingFanLink.dustCollectingFan.name}-${dustCollectingFanIndex}`}>
                      {dustCollectingFanIndex === 0 && (
                        <Typography sx={modalSubtitleSx}>吸塵ファン</Typography>
                      )}
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <InputLabel
                            htmlFor="newDustCollectingFanReplacementLogs"
                            sx={labelGrayOutSx}>
                            {dustCollectingFanLink.dustCollectingFan.name +
                              ' 交換前'}
                          </InputLabel>
                          <SelectInput
                            source={`newDustCollectingFanReplacementLogs[[${dustCollectingFanIndex}]oldMachinePartID]`}
                            choices={machineLinksInfos?.previousMachineDustCollectingFanLinks?.map(
                              (part) => {
                                const matchingMachinePart = machineParts.find(
                                  (machinePart) =>
                                    machinePart.id === part.machinePartID,
                                )

                                const displayName = matchingMachinePart
                                  ? `${matchingMachinePart.drawingNumber}  ${matchingMachinePart.name}`
                                  : part.machinePartID

                                return {
                                  id: part.machinePartID,
                                  name: displayName,
                                }
                              },
                            )}
                            label={false}
                            sx={{ ...selectInputSx, width: '350px' }}
                            disabled
                            defaultValue={
                              defaultPreviousValues[dustCollectingFanIndex]
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel
                            htmlFor="newMachineDustCollectingFanLinks"
                            sx={labelGrayOutSx}>
                            {dustCollectingFanLink.dustCollectingFan.name +
                              ' 交換後'}
                          </InputLabel>
                          <CustomPlaceholderSelectInput
                            type="formInput"
                            placeholder="選択してください"
                            source={`newMachineDustCollectingFanLinks[machinePartID[${dustCollectingFanIndex}]]`}
                            choices={machineParts
                              .filter((part) => part.machinePartType.id === 4)
                              .map((part) => ({
                                id: part.id,
                                name: `${part.drawingNumber}  ${part.name}`,
                              }))}
                            defaultValue={
                              defaultRecentDustCollectingFanValues[
                                dustCollectingFanIndex
                              ]
                            }
                            label={false}
                            sx={{ ...selectInputSx, width: '350px' }}
                            onChange={(e) => {
                              const updatedMachineDustCollectingFanLinks = [
                                ...(maintenanceLogParams.newMachineDustCollectingFanLinks ||
                                  []),
                              ]
                              updatedMachineDustCollectingFanLinks[
                                dustCollectingFanIndex
                              ] = {
                                ...updatedMachineDustCollectingFanLinks[
                                  dustCollectingFanIndex
                                ],
                                machinePartID: Number(e.target.value),
                                dustCollectingFanID:
                                  dustCollectingFanLink.dustCollectingFanID,
                              }

                              setMaintenanceLogParams((prev) => ({
                                ...prev,
                                newMachineDustCollectingFanLinks:
                                  updatedMachineDustCollectingFanLinks,
                              }))
                            }}
                          />
                        </Grid>
                        <Grid>
                          <NumberInput
                            source={`newMachineDustCollectingFanLinks[dustCollectingFanID[${dustCollectingFanIndex}]]`}
                            label={false}
                            defaultValue={
                              dustCollectingFanLink.dustCollectingFanID
                            }
                            disabled
                            sx={{ display: 'none' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                }
              },
            ),
          )}
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid container wrap="wrap">
        {machines
          ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
          ?.map((machine: MachineRecord) =>
            machine.machinePushSolenoidLinks?.map(
              (
                pushSolenoidLink: MachinePushSolenoidLinkRecord,
                pushSolenoidIndex: number,
              ) => {
                const previousMachinePartID =
                  machineLinksInfos?.previousMachinePushSolenoidLinks?.[
                    pushSolenoidIndex
                  ]?.machinePartID

                // 登録前の紐付けを格納する配列
                const defaultPreviousValues = new Array(
                  machineLinksInfos?.previousMachinePushSolenoidLinks?.length ||
                    0,
                ).fill(null)

                // 現在の紐付けを格納する配列

                // 登録前の紐付けを格納する処理
                machineLinksInfos?.previousMachinePushSolenoidLinks?.forEach(
                  (previousLink) => {
                    if (
                      previousLink.machinePartID &&
                      previousLink.id !== undefined
                    ) {
                      const index = previousLink.pushSolenoidID - 1 // IDから1を引いてインデックスを取得
                      defaultPreviousValues[index] = previousLink.machinePartID
                    }
                  },
                )

                if (previousMachinePartID !== undefined) {
                  return (
                    <Grid
                      item
                      sx={{
                        width: '100%',
                        marginBottom: '24px',
                      }}
                      key={`${pushSolenoidLink.pushSolenoid.name}-${pushSolenoidIndex}`}>
                      {pushSolenoidIndex === 0 && (
                        <Typography sx={modalSubtitleSx}>
                          プッシュソレノイド
                        </Typography>
                      )}
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <InputLabel
                            htmlFor="newPushSolenoidReplacementLogs"
                            sx={labelGrayOutSx}>
                            {pushSolenoidLink.pushSolenoid.name + ' 交換前'}
                          </InputLabel>
                          <SelectInput
                            source={`newPushSolenoidReplacementLogs[[${pushSolenoidIndex}]oldMachinePartID]`}
                            choices={machineLinksInfos?.previousMachinePushSolenoidLinks?.map(
                              (part) => {
                                const matchingMachinePart = machineParts.find(
                                  (machinePart) =>
                                    machinePart.id === part.machinePartID,
                                )

                                const displayName = matchingMachinePart
                                  ? `${matchingMachinePart.drawingNumber}  ${matchingMachinePart.name}`
                                  : part.machinePartID

                                return {
                                  id: part.machinePartID,
                                  name: displayName,
                                }
                              },
                            )}
                            label={false}
                            sx={{ ...selectInputSx, width: '350px' }}
                            disabled
                            defaultValue={
                              defaultPreviousValues[pushSolenoidIndex]
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel
                            htmlFor="newMachinePushSolenoidLinks"
                            sx={labelGrayOutSx}>
                            {pushSolenoidLink.pushSolenoid.name + ' 交換後'}
                          </InputLabel>
                          <CustomPlaceholderSelectInput
                            type="formInput"
                            placeholder="選択してください"
                            source={`newMachinePushSolenoidLinks[machinePartID[${pushSolenoidIndex}]]`}
                            choices={machineParts
                              .filter((part) => part.machinePartType.id === 5)
                              .map((part) => ({
                                id: part.id,
                                name: `${part.drawingNumber}  ${part.name}`,
                              }))}
                            defaultValue={
                              defaultRecentPushSolenoidValues[pushSolenoidIndex]
                            }
                            label={false}
                            sx={{ ...selectInputSx, width: '350px' }}
                            onChange={(e) => {
                              const updatedMachinePushSolenoidLinks = [
                                ...(maintenanceLogParams.newMachinePushSolenoidLinks ||
                                  []),
                              ]
                              updatedMachinePushSolenoidLinks[
                                pushSolenoidIndex
                              ] = {
                                ...updatedMachinePushSolenoidLinks[
                                  pushSolenoidIndex
                                ],
                                machinePartID: Number(e.target.value),
                                pushSolenoidID: pushSolenoidLink.pushSolenoidID,
                              }

                              setMaintenanceLogParams((prev) => ({
                                ...prev,
                                newMachinePushSolenoidLinks:
                                  updatedMachinePushSolenoidLinks,
                              }))
                            }}
                          />
                        </Grid>
                        <Grid>
                          <NumberInput
                            source={`newMachinePushSolenoidLinks[pushSolenoidID[${pushSolenoidIndex}]]`}
                            label={false}
                            defaultValue={pushSolenoidLink.pushSolenoidID}
                            disabled
                            sx={{ display: 'none' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                }
              },
            ),
          )}
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid container wrap="wrap">
        {machines
          ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
          ?.map((machine: MachineRecord) =>
            machine.machineSolenoidValveLinks?.map(
              (
                solenoidValveLink: MachineSolenoidValveLinkRecord,
                solenoidValveIndex: number,
              ) => {
                const previousMachinePartID =
                  machineLinksInfos?.previousMachineSolenoidValveLinks?.[
                    solenoidValveIndex
                  ]?.machinePartID

                // 登録前の紐付けを格納する配列
                const defaultPreviousValues = new Array(
                  machineLinksInfos?.previousMachineSolenoidValveLinks
                    ?.length || 0,
                ).fill(null)

                // 登録前の紐付けを格納する処理
                machineLinksInfos?.previousMachineSolenoidValveLinks?.forEach(
                  (previousLink) => {
                    if (
                      previousLink.machinePartID &&
                      previousLink.id !== undefined
                    ) {
                      const index = previousLink.solenoidValveID - 1 // IDから1を引いてインデックスを取得
                      defaultPreviousValues[index] = previousLink.machinePartID
                    }
                  },
                )

                if (previousMachinePartID) {
                  return (
                    <Grid
                      item
                      sx={{
                        width: '100%',
                        marginBottom: '24px',
                      }}
                      key={`${solenoidValveLink.solenoidValve.name}-${solenoidValveIndex}`}>
                      {solenoidValveIndex === 0 && (
                        <Typography sx={modalSubtitleSx}>
                          ソレノイドバルブ
                        </Typography>
                      )}
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <InputLabel
                            htmlFor="newSolenoidValveReplacementLogs"
                            sx={labelGrayOutSx}>
                            {solenoidValveLink.solenoidValve.name + ' 交換前'}
                          </InputLabel>
                          <SelectInput
                            source={`newSolenoidValveReplacementLogs[[${solenoidValveIndex}]oldMachinePartID]`}
                            choices={machineLinksInfos?.previousMachineSolenoidValveLinks?.map(
                              (part) => {
                                const matchingMachinePart = machineParts.find(
                                  (machinePart) =>
                                    machinePart.id === part.machinePartID,
                                )

                                const displayName = matchingMachinePart
                                  ? `${matchingMachinePart.drawingNumber}  ${matchingMachinePart.name}`
                                  : part.machinePartID

                                return {
                                  id: part.machinePartID,
                                  name: displayName,
                                }
                              },
                            )}
                            label={false}
                            sx={{ ...selectInputSx, width: '350px' }}
                            disabled
                            defaultValue={
                              defaultPreviousValues[solenoidValveIndex]
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel
                            htmlFor="newMachineSolenoidValveLinks"
                            sx={labelGrayOutSx}>
                            {solenoidValveLink.solenoidValve.name + ' 交換後'}
                          </InputLabel>
                          <CustomPlaceholderSelectInput
                            type="formInput"
                            placeholder="選択してください"
                            source={`newMachineSolenoidValveLinks[machinePartID[${solenoidValveIndex}]]`}
                            choices={machineParts
                              .filter((part) => part.machinePartType.id === 6)
                              .map((part) => ({
                                id: part.id,
                                name: `${part.drawingNumber}  ${part.name}`,
                              }))}
                            defaultValue={
                              defaultRecentSolenoidValveValues[
                                solenoidValveIndex
                              ]
                            }
                            label={false}
                            sx={{ ...selectInputSx, width: '350px' }}
                            onChange={(e) => {
                              const updatedMachineSolenoidValveLinks = [
                                ...(maintenanceLogParams.newMachineSolenoidValveLinks ||
                                  []),
                              ]
                              updatedMachineSolenoidValveLinks[
                                solenoidValveIndex
                              ] = {
                                ...updatedMachineSolenoidValveLinks[
                                  solenoidValveIndex
                                ],
                                machinePartID: Number(e.target.value),
                                solenoidValveID:
                                  solenoidValveLink.solenoidValveID,
                              }

                              setMaintenanceLogParams((prev) => ({
                                ...prev,
                                newMachineSolenoidValveLinks:
                                  updatedMachineSolenoidValveLinks,
                              }))
                            }}
                          />
                        </Grid>
                        <Grid>
                          <NumberInput
                            source={`newMachineSolenoidValveLinks[solenoidValveID[${solenoidValveIndex}]]`}
                            label={false}
                            defaultValue={solenoidValveLink.solenoidValveID}
                            disabled
                            sx={{ display: 'none' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                }
              },
            ),
          )}
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid container wrap="wrap">
        {machines
          ?.filter((machine) => machine.id === maintenanceLogParams.machineID)
          ?.map((machine: MachineRecord) =>
            machine.machineLinearActuatorLinks?.map(
              (
                linearActuatorLink: MachineLinearActuatorLinkRecord,
                linearActuatorIndex: number,
              ) => {
                const previousMachinePartID =
                  machineLinksInfos?.previousMachineLinearActuatorLinks?.[
                    linearActuatorIndex
                  ]?.machinePartID

                // 登録前の紐付けを格納する配列
                const defaultPreviousValues = new Array(
                  machineLinksInfos?.previousMachineLinearActuatorLinks
                    ?.length || 0,
                ).fill(null)

                // 登録前の紐付けを格納する処理
                machineLinksInfos?.previousMachineLinearActuatorLinks?.forEach(
                  (previousLink) => {
                    if (
                      previousLink.machinePartID &&
                      previousLink.id !== undefined
                    ) {
                      const index = previousLink.linearActuatorID - 1 // IDから1を引いてインデックスを取得
                      defaultPreviousValues[index] = previousLink.machinePartID
                    }
                  },
                )

                if (previousMachinePartID !== undefined) {
                  return (
                    <Grid
                      item
                      sx={{
                        width: '100%',
                        marginBottom: '24px',
                      }}
                      key={`${linearActuatorLink.linearActuator.name}-${linearActuatorIndex}`}>
                      {linearActuatorIndex === 0 && (
                        <Typography sx={modalSubtitleSx}>
                          リニアアクチュエーター
                        </Typography>
                      )}
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <InputLabel
                            htmlFor="newLinearActuatorReplacementLogs"
                            sx={labelGrayOutSx}>
                            {linearActuatorLink.linearActuator.name + ' 交換前'}
                          </InputLabel>
                          <SelectInput
                            source={`newLinearActuatorReplacementLogs[[${linearActuatorIndex}]oldMachinePartID]`}
                            choices={machineLinksInfos?.previousMachineLinearActuatorLinks?.map(
                              (part) => {
                                const matchingMachinePart = machineParts.find(
                                  (machinePart) =>
                                    machinePart.id === part.machinePartID,
                                )

                                const displayName = matchingMachinePart
                                  ? `${matchingMachinePart.drawingNumber}  ${matchingMachinePart.name}`
                                  : part.machinePartID

                                return {
                                  id: part.machinePartID,
                                  name: displayName,
                                }
                              },
                            )}
                            label={false}
                            sx={{ ...selectInputSx, width: '350px' }}
                            disabled
                            defaultValue={
                              defaultPreviousValues[linearActuatorIndex]
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel
                            htmlFor="newMachineLinearActuatorLinks"
                            sx={labelGrayOutSx}>
                            {linearActuatorLink.linearActuator.name + ' 交換後'}
                          </InputLabel>
                          <CustomPlaceholderSelectInput
                            type="formInput"
                            placeholder="選択してください"
                            source={`newMachineLinearActuatorLinks[machinePartID[${linearActuatorIndex}]]`}
                            choices={machineParts
                              .filter((part) => part.machinePartType.id === 7)
                              .map((part) => ({
                                id: part.id,
                                name: `${part.drawingNumber}  ${part.name}`,
                              }))}
                            defaultValue={
                              defaultRecentLinearActuatorValues[
                                linearActuatorIndex
                              ]
                            }
                            label={false}
                            sx={{ ...selectInputSx, width: '350px' }}
                            onChange={(e) => {
                              const updatedMachineLinearActuatorLinks = [
                                ...(maintenanceLogParams.newMachineLinearActuatorLinks ||
                                  []),
                              ]
                              updatedMachineLinearActuatorLinks[
                                linearActuatorIndex
                              ] = {
                                ...updatedMachineLinearActuatorLinks[
                                  linearActuatorIndex
                                ],
                                machinePartID: Number(e.target.value),
                                linearActuatorID:
                                  linearActuatorLink.linearActuatorID,
                              }

                              setMaintenanceLogParams((prev) => ({
                                ...prev,
                                newMachineLinearActuatorLinks:
                                  updatedMachineLinearActuatorLinks,
                              }))
                            }}
                          />
                        </Grid>
                        <Grid>
                          <NumberInput
                            source={`newMachineLinearActuatorLinks[linearActuatorID[${linearActuatorIndex}]]`}
                            label={false}
                            defaultValue={linearActuatorLink.linearActuatorID}
                            disabled
                            sx={{ display: 'none' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                }
              },
            ),
          )}
      </Grid>
    </Grid>
  )
}

export default EditMaintenanceLogModalReplacementPartsInput
