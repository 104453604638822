import React, { useEffect, useState } from 'react'
import { Form, SaveButton } from 'react-admin'
import { Grid, Modal, Box, Button, Typography } from '@mui/material'
import CancelInputModal from '../CancelInputModal'
import useCancelModal from '../../../hooks/useCancelModal'
import {
  MachinePartParams,
  MachinePartTypeRecord,
  MaximumValueType,
  WhichMaximumValueType,
} from '../../../types/records/machine-part-record'
import CancelButton from '../../button/CancelButton'
import { ModalSectionType } from './ModalSectionType'
import MachinePartModalBasicInfo from './MachinePartModalBasicInfo'
import MachinePartModalConfirm from './MachinePartModalConfirm'
import {
  modalWrapperSx,
  nextButtonSx,
  cancelButtonWrapperSx,
  saveButtonSx,
} from '../../../assets/sx/form'
import { modalTitleSx } from '../../../assets/sx/modalSx'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: (data: MachinePartParams) => void
  machinePartTypes: MachinePartTypeRecord[]
}

const AddMachinePartModal: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  machinePartTypes,
}) => {
  const [machinePartParam, setMachinePartParam] = useState<MachinePartParams>(
    {},
  )
  const [previewMachinePartParam, setPreviewMachinePartParam] =
    useState<MachinePartParams>({})

  useEffect(() => {
    if (open) {
      setModalSection(ModalSectionType.BasicInfo)
      setMachinePartParam({})
    }
  }, [open])

  const moveToBasicInfoSection = () => {
    setModalSection(ModalSectionType.BasicInfo)
  }

  const canMoveToConfirmSection = () => {
    // 必須項目チェック
    if (
      !machinePartParam.drawingNumber ||
      !machinePartParam.name ||
      !machinePartParam.machinePartTypeID
    ) {
      return false
    }

    const machinePartType = machinePartTypes.find(
      (target) => target.id === machinePartParam.machinePartTypeID,
    )
    if (!machinePartType) return false

    switch (WhichMaximumValueType(machinePartType.name)) {
      case MaximumValueType.OperatingHour:
        if (!machinePartParam.maximumOperatingHour) return false
        break
      case MaximumValueType.ActuatingCycle:
        if (!machinePartParam.maximumActuatingCycle) return false
        break
      case MaximumValueType.None:
        return false
    }
    return true
  }

  const [modalSection, setModalSection] = useState<ModalSectionType>(
    ModalSectionType.BasicInfo,
  )

  const moveToConfirmSection = () => {
    const imputMachinePartParams = { ...machinePartParam }
    const machinePartType = machinePartTypes.find(
      (target) => target.id === machinePartParam.machinePartTypeID,
    )

    if (machinePartType) {
      switch (WhichMaximumValueType(machinePartType.name)) {
        case MaximumValueType.OperatingHour:
          delete imputMachinePartParams.maximumActuatingCycle
          break
        case MaximumValueType.ActuatingCycle:
          delete imputMachinePartParams.maximumOperatingHour
          break
      }
    }

    setModalSection(ModalSectionType.Confirm)
    setPreviewMachinePartParam(imputMachinePartParams)
  }

  const onCreateSubmit = (data: MachinePartParams) => {
    const machinePartType = machinePartTypes.find(
      (target) => target.id === machinePartParam.machinePartTypeID,
    )

    // machinePartTypeに対応した最大値のみ残す
    if (machinePartType) {
      switch (WhichMaximumValueType(machinePartType.name)) {
        case MaximumValueType.OperatingHour:
          data.maximumActuatingCycle = undefined
          break
        case MaximumValueType.ActuatingCycle:
          data.maximumOperatingHour = undefined
          break
      }
    }

    if (modalSection === ModalSectionType.BasicInfo) {
      moveToConfirmSection()
      return
    }
    onSubmit(data)
  }

  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box sx={modalWrapperSx}>
          <Form onSubmit={onCreateSubmit} mode="onBlur">
            {modalSection === ModalSectionType.BasicInfo ? (
              <>
                <Typography sx={modalTitleSx}>機械部品を追加する</Typography>
                <MachinePartModalBasicInfo
                  machinePartTypes={machinePartTypes}
                  setMachinePartParam={setMachinePartParam}
                  machinePartParam={machinePartParam}
                />
              </>
            ) : (
              <></>
            )}
            {modalSection === ModalSectionType.Confirm ? (
              <MachinePartModalConfirm
                machinePartTypes={machinePartTypes}
                machinePart={previewMachinePartParam as MachinePartParams}
              />
            ) : (
              <></>
            )}
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              sx={{ mt: '40px' }}>
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton
                  onClick={() => {
                    switch (modalSection) {
                      case ModalSectionType.BasicInfo:
                        handleOnCancelModalOpen()
                        break
                      case ModalSectionType.Confirm:
                        moveToBasicInfoSection()
                    }
                  }}>
                  {modalSection == ModalSectionType.BasicInfo
                    ? 'キャンセル'
                    : '戻る'}
                </CancelButton>
              </Box>
              {modalSection == ModalSectionType.BasicInfo && (
                <Button
                  sx={{ ...nextButtonSx, width: '104px' }}
                  onClick={moveToConfirmSection}
                  disabled={!canMoveToConfirmSection()}>
                  追加する
                </Button>
              )}
              {modalSection == ModalSectionType.Confirm && (
                <SaveButton label="登録する" sx={saveButtonSx} />
              )}
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default AddMachinePartModal
