import React, { useState } from 'react'
import { Menu, useLogout, useNotify } from 'react-admin'
import { Grid } from '@mui/material'
import { ReactComponent as MachineIcon } from '../assets/images/machine.svg'
import { ReactComponent as UserIcon } from '../assets/images/user.svg'
import { ReactComponent as ErrorLogIcon } from '../assets/images/error_log.svg'
import { ReactComponent as MachineToolIcon } from '../assets/images/machine_tool.svg'
import { ReactComponent as LogoutIcon } from '../assets/images/logout.svg'
import LogoutModal from '../components/modal/LogoutModal'
import Logo from '../assets/images/wide_logo.svg'
import style from './Sidebar.module.scss'
import { sidebarSx } from '../assets/sx/sidebar'

// eslint-disable-next-line
const MySidebar: React.FC<any> = (props) => {
  // サイドバー表示時、アイコンだけになるのを防ぐため、sidebar.openをtrueにする
  localStorage.setItem('RaStore.sidebar.open', 'true')
  const logout = useLogout()
  const notify = useNotify()
  const [logoutModalOpen, setLogoutModalOpen] = useState<boolean>(false)
  const closeLogoutModal = () => {
    setLogoutModalOpen(false)
  }

  const onLogout = () => {
    logout().then(() => {
      notify('ログアウトしました')
    })
  }

  return (
    <Menu className={style['hide-sidebar']} sx={sidebarSx}>
      <Grid
        sx={{
          alignItems: 'center',
          padding: '24px',
        }}>
        <img src={Logo} alt="Image" style={{ width: '173px' }} />
      </Grid>
      <Menu.Item
        className={style['sidebar-item']}
        to="/staffs"
        primaryText="ユーザーマスター"
        leftIcon={<UserIcon />}
      />
      <Menu.Item
        className={style['sidebar-item']}
        to="/machines"
        primaryText="マシンマスター"
        leftIcon={<MachineIcon />}
      />
      <Menu.Item
        className={style['sidebar-item']}
        to="/machine-parts"
        primaryText="機械部品マスター"
        leftIcon={<MachineToolIcon />}
      />
      <Menu.Item
        className={style['sidebar-item']}
        to="/error-logs"
        primaryText="エラーログ"
        leftIcon={<ErrorLogIcon />}
      />
      <Menu.Item
        className={style['sidebar-item']}
        to="/maintenance-logs"
        primaryText="メンテログマスター"
        leftIcon={<MachineToolIcon />}
      />
      <Grid sx={{ height: '100%', display: 'flex', alignItems: 'flex-end' }}>
        <Menu.DashboardItem
          className={style['sidebar-item']}
          onClick={() => setLogoutModalOpen(true)}
          primaryText="ログアウト"
          leftIcon={<LogoutIcon />}
        />
      </Grid>
      <LogoutModal
        onLogout={onLogout}
        onClose={closeLogoutModal}
        open={logoutModalOpen}
      />
    </Menu>
  )
}

export default MySidebar
