export const RadioButtonGroupInputSx = {
  '&.MuiFormControl-root': {
    width: '100%',
    color: '#000',
    marginTop: '0!important',
  },
  '& .MuiFormGroup-row': {
    height: '48px',
    display: 'flex',
    justifyContent: 'space-around',
    borderRight: '1px solid #EDEAE3',
    '& .MuiFormControlLabel-labelPlacementEnd': {
      flex: 1,
      justifyContent: 'center',
      margin: 0,
      width: '100px',
      color: '#757575',
      '&:has(span.Mui-checked)': {
        color: '#005739',
        background: 'linear-gradient(transparent 90%, #005739 0%)',
      },
      '& .PrivateSwitchBase-root': {
        display: 'none',
      },
      '& .MuiFormControlLabel-label': {
        fontWeight: 'bold',
      },
    },
  },
  '& + .RaFilterFormInput-spacer': {
    display: 'none',
  },
}

export const RecipeRadioButtonGroupInputSx = {
  '&.MuiFormControl-root': {
    width: '100%',
    color: '#000',
    marginTop: '0!important',
  },
  '& .MuiFormGroup-row': {
    height: '48px',
    display: 'flex',
    justifyContent: 'left',
    borderRight: '1px solid #EDEAE3',
    '& .MuiFormControlLabel-labelPlacementEnd': {
      flex: 1,
      justifyContent: 'center',
      margin: 0,
      width: '100px',
      color: '#757575',
      '&:has(span.Mui-checked)': {
        color: '#005739',
        background: 'linear-gradient(transparent 90%, #005739 0%)',
      },
      '& .PrivateSwitchBase-root': {
        display: 'none',
      },
      '& .MuiFormControlLabel-label': {
        fontWeight: 'bold',
        fontSize: '14px',
      },
    },
  },
  '& + .RaFilterFormInput-spacer': {
    display: 'none',
  },
}

export const RecipeFilterSelectInputSx = {
  '&.MuiFormControl-root': {
    width: '100%',
    color: '#000',
    marginTop: '0!important',
    '&.MuiList-root': {
      width: '50%',
    },
  },
  '& + .RaFilterFormInput-spacer': {
    display: 'none',
  },
  legend: {
    display: 'none',
  },
}

export const StartDateInputSx = {
  height: '100%',
  marginTop: '0!important',
  '& .MuiInputBase-formControl': {
    '& .MuiOutlinedInput-input': {
      height: '32px',
      color: '#757575',
      flexDirection: 'row-reverse',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRight: 'none',
    },
  },
  '& .MuiOutlinedInput-root legend': {
    display: 'none',
  },
  '& + .RaFilterFormInput-spacer': {
    display: 'none',
  },
  'input[type="date" i]::-webkit-calendar-picker-indicator': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: '0',
  },
}

export const EndDateInputSx = {
  marginTop: '0!important',
  '& .MuiInputBase-formControl': {
    '& .MuiOutlinedInput-input': {
      height: '32px',
      color: '#757575',
      flexDirection: 'row-reverse',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderLeft: 'none',
      '&::before': {
        content: '""',
        display: 'inline-block',
        width: '1px',
        height: '24px',
        backgroundColor: '#757575',
        position: 'absolute',
        top: '16px',
        left: '0',
      },
    },
  },
  '& .MuiOutlinedInput-root legend': {
    display: 'none',
  },
  '& + .RaFilterFormInput-spacer': {
    display: 'none',
  },
  'input[type="date" i]::-webkit-calendar-picker-indicator': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: '0',
  },
}

export const IngredientIngredientCategorySelectInputSx = {
  '&.MuiFormControl-root': {
    width: '100%',
    color: '#000',
    marginTop: '0!important',
    '&.MuiList-root': {
      width: '50%',
    },
  },
  '& + .RaFilterFormInput-spacer': {
    display: 'none',
  },
  legend: {
    display: 'none',
  },
}

export const ErrorLogStatusInputSx = {
  '&.MuiFormControl-root': {
    width: '100%',
    color: '#000',
    marginTop: '0!important',
  },
  '& .MuiFormGroup-row': {
    height: '48px',
    display: 'flex',
    justifyContent: 'space-around',
    borderRight: '1px solid #EDEAE3',
    '& .MuiFormControlLabel-labelPlacementEnd': {
      flex: 1,
      justifyContent: 'center',
      margin: 0,
      width: '100px',
      color: '#757575',
      '&:has(span.Mui-checked)': {
        color: '#005739',
        background: 'linear-gradient(transparent 90%, #005739 0%)',
      },
      '& .PrivateSwitchBase-root': {
        display: 'none',
      },
      '& .MuiFormControlLabel-label': {
        fontWeight: 'bold',
        fontSize: '14px',
      },
    },
  },
  '& + .RaFilterFormInput-spacer': {
    display: 'none',
  },
}

export const ErrorLogStartDateInputSx = {
  '& .MuiInputBase-formControl': {
    '& .MuiOutlinedInput-input': {
      color: '#757575',
      flexDirection: 'row-reverse',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRight: 'none',
      borderRadius: '4px 0 0 4px',
      '&::before': {
        content: '""',
        display: 'inline-block',
        width: '1px',
        height: '24px',
        backgroundColor: '#757575',
        position: 'absolute',
        top: '12px',
        left: '0',
      },
    },
  },
  '& .MuiOutlinedInput-root legend': {
    display: 'none',
  },
  '& + .RaFilterFormInput-spacer': {
    display: 'none',
  },
}

export const ErrorLogEndDateInputSx = {
  '& .MuiInputBase-formControl': {
    '& .MuiOutlinedInput-input': {
      color: '#757575',
      flexDirection: 'row-reverse',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderLeft: 'none',
      borderRadius: '0 4px 4px 0',
      '&::before': {
        content: '""',
        display: 'inline-block',
        width: '1px',
        height: '24px',
        backgroundColor: '#757575',
        position: 'absolute',
        top: '12px',
        left: '0',
      },
    },
  },
  '& .MuiOutlinedInput-root legend': {
    display: 'none',
  },
  '& + .RaFilterFormInput-spacer': {
    display: 'none',
  },
}

export const ErrorLogErrorLevelSelectInputSx = {
  '&.MuiFormControl-root': {
    width: '100%',
    color: '#000',
    marginTop: '0!important',

    '&.MuiList-root': {
      width: '50%',
    },
  },
  '& + .RaFilterFormInput-spacer': {
    display: 'none',
  },
  legend: {
    display: 'none',
  },
}

export const MachinePartSelectInputSx = {
  '&.MuiFormControl-root': {
    width: '100%',
    color: '#000',
    marginTop: '0!important',
    '&.MuiList-root': {
      width: '50%',
    },
  },
  '& + .RaFilterFormInput-spacer': {
    display: 'none',
  },
  legend: {
    display: 'none',
  },
}

export const FilterSelectInputSx = {
  '&.MuiFormControl-root': {
    width: '100%',
    color: '#000',
    marginTop: '0!important',
    '&.MuiList-root': {
      width: '50%',
    },
  },
  '& .MuiInputBase-input': {
    height: '32px',
  },
  '&.MuiTextField-root': {
    minWidth: '100%',
  },
  '& + .RaFilterFormInput-spacer': {
    display: 'none',
  },
  legend: {
    display: 'none',
  },
}

export const TextInputSx = {
  marginTop: '0!important',
  borderRight: '1px solid #EDEAE3',
  '& .MuiInputBase-input': {
    height: '32px',
  },
  '& .MuiOutlinedInput-root legend': {
    display: 'none',
  },
  '& + .RaFilterFormInput-spacer': {
    display: 'none',
  },
}
