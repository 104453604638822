import React, { MouseEvent, useState } from 'react'
import {
  TextField,
  TextInput,
  DateInput,
  FunctionField,
  useDataProvider,
  useNotify,
  useRefresh,
  useGetList,
  RadioButtonGroupInput,
} from 'react-admin'
import { format, parseISO } from 'date-fns'
import { InputAdornment, Grid } from '@mui/material'
import AddButton from '../components/button/AddButton'
import IconButton from '../components/button/IconButton'
import AddMachineModal from '../components/modal/machines/AddMachineModal'
import DeleteMachineModal from '../components/modal/machines/DeleteMachineModal'
import EditMachineModal from '../components/modal/machines/EditMachineModal'
import ShowMachineModal from '../components/modal/machines/ShowMachineModal'

import ListDatagrid from '../components/ListDatagrid'
import {
  MachinePartRecord,
  MachinePartTypeRecord,
} from '../types/records/machine-part-record'
import { MachineRecord, MachineParams } from '../types/records/machine-record'
import { useAuthorize, useNotifyForbidden } from '../hooks/authorization'
import { ReactComponent as DetailIcon } from '../assets/images/details.svg'
import { ReactComponent as SearchIcon } from '../assets/images/search.svg'
import { ReactComponent as TrashIcon } from '../assets/images/trash.svg'
import { ReactComponent as PencilIcon } from '../assets/images/pencil.svg'
import { ReactComponent as CalenderIcon } from '../assets/images/calender.svg'
import {
  TextInputSx,
  StartDateInputSx,
  EndDateInputSx,
  RadioButtonGroupInputSx,
} from './FilterSx'
import styles from './MachineList.module.scss'
import { firstFieldSx } from '../assets/sx/field'
import { setFilterWidth } from '../assets/styles/setFilterWidth'

const MachineList: React.FC = () => {
  const existingData = JSON.parse(
    localStorage.getItem('RaStore.machines.listParams') || '{}',
  )

  const updatedData = {
    ...existingData,
    displayedFilters: {},
    filter: {
      operationStatus: 'inOperation',
      productionDataAtStart: `${format(new Date(), 'yyyy-MM-dd')}`,
      productionDataAtEnd: `${format(new Date(), 'yyyy-MM-dd')}`,
    },
    order: 'ASC',
    page: 1,
    perPage: 10,
    sort: 'id',
  }

  //  更新したデータを再度localStorageに保存する
  localStorage.setItem(
    'RaStore.machines.listParams',
    JSON.stringify(updatedData),
  )

  const filters = [
    <RadioButtonGroupInput
      className="machineOperationStatus"
      key="operationStatus"
      label={false}
      source="operationStatus"
      alwaysOn
      margin="none"
      sx={RadioButtonGroupInputSx}
      choices={[
        { id: 'inOperation', name: '運用中' },
        { id: 'preOperating', name: '運用前' },
      ]}
    />,
    <TextInput
      className="machineStoreName"
      key="storeName"
      label={false}
      source="storeName"
      placeholder="店舗名で検索"
      alwaysOn
      fullWidth
      resettable
      sx={TextInputSx}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />,
    <TextInput
      className="machineMachineSerial"
      key="machineSerial"
      label={false}
      source="machineSerial"
      placeholder="マシン シリアルで検索"
      alwaysOn
      fullWidth
      resettable
      sx={TextInputSx}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />,
    <DateInput
      className="machineProductionDateAtStart"
      key="productionDateAtStart"
      label={false}
      source="productionDateAtStart"
      alwaysOn
      fullWidth
      sx={StartDateInputSx}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CalenderIcon />
          </InputAdornment>
        ),
      }}
    />,
    <DateInput
      className="machineProductionDateAtEnd"
      key="productionDateAtEnd"
      label={false}
      source="productionDateAtEnd"
      alwaysOn
      fullWidth
      sx={EndDateInputSx}
    />,
  ]
  // フィルターの幅を設定する
  setFilterWidth('machineOperationStatus', '20%')
  setFilterWidth('machineStoreName', '30%')
  setFilterWidth('machineMachineSerial', '30%')
  setFilterWidth('machineProductionDateAtStart', '10%')
  setFilterWidth('machineProductionDateAtEnd', '10%')

  const dataProvider = useDataProvider()
  const machinePartTypes = useGetList('machine-part-types')
  const machineParts = useGetList('machine-parts')
  const notify = useNotify()
  const refresh = useRefresh()
  const [targetMachine, setTargetMachine] = useState<MachineRecord>()
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false)
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [showModalOpen, setShowModalOpen] = useState<boolean>(false)

  const canCreateMachine = useAuthorize('machine', 'create')
  const canUpdateMachine = useAuthorize('machine', 'update')
  const canDeleteMachine = useAuthorize('machine', 'delete')
  const notifyForbidden = useNotifyForbidden()

  const openCreateModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!canCreateMachine) {
      notifyForbidden()
      return
    }

    setCreateModalOpen(true)
  }

  const openEditModal = (
    e: MouseEvent<HTMLElement>,
    targetMachine: MachineRecord | undefined,
  ) => {
    e.preventDefault()
    if (!canUpdateMachine) {
      notifyForbidden()
      return
    }

    setEditModalOpen(true)
    setTargetMachine(targetMachine)
  }

  const openDeleteModal = (
    e: MouseEvent<HTMLElement>,
    targetMachine: MachineRecord | undefined,
  ) => {
    e.preventDefault()
    if (!canDeleteMachine) {
      notifyForbidden()
      return
    }

    setDeleteModalOpen(true)
    setTargetMachine(targetMachine)
  }

  const openShowModal = (
    e: MouseEvent<HTMLElement>,
    targetMachine: MachineRecord | undefined,
  ) => {
    e.preventDefault()
    setShowModalOpen(true)
    setTargetMachine(targetMachine)
  }

  const closeModal = () => {
    setCreateModalOpen(false)
    setEditModalOpen(false)
  }

  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const closeShowModal = () => {
    setShowModalOpen(false)
  }

  const onDeleteSubmit = (machine: MachineRecord) => {
    dataProvider
      .delete('machines', { id: machine.id, previousData: machine })
      .then(() => {
        notify('マシンを削除しました')
        setDeleteModalOpen(false)

        refresh()
      })
      .catch(() => {
        notify('マシンの削除に失敗しました')
      })
  }

  const onCreateSubmit = (data: MachineParams) => {
    dataProvider
      .create('machines', { data })
      .then(() => {
        notify('マシンを追加しました')
        setCreateModalOpen(false)
        refresh()
      })
      .catch((error) => {
        notify(error.message, { type: 'error' })
      })
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)

  const onUpdateSubmit = (
    id: number,
    data: MachineParams,
    previousData: MachineRecord,
  ) => {
    dataProvider
      .update('machines', {
        id: id,
        data: data,
        previousData: previousData,
      })
      .then(() => {
        notify('マシンを更新しました')
        setEditModalOpen(false)

        refresh()
      })
      .catch((error) => {
        notify(error.message, { type: 'error' })
      })
  }
  const modals =
    machinePartTypes.data && machineParts.data
      ? [
          <AddMachineModal
            key="addMachineModal"
            open={createModalOpen}
            machinePartTypes={machinePartTypes.data as MachinePartTypeRecord[]}
            machineParts={machineParts.data as MachinePartRecord[]}
            onClose={closeModal}
            onSubmit={onCreateSubmit}
          />,
          <EditMachineModal
            key="editMachineModal"
            machine={targetMachine}
            open={editModalOpen}
            onClose={closeModal}
            onSubmit={onUpdateSubmit}
          />,
          <DeleteMachineModal
            key="deleteMachineModal"
            machine={targetMachine}
            open={deleteModalOpen}
            onClose={closeDeleteModal}
            onSubmit={onDeleteSubmit}
          />,
          <ShowMachineModal
            key="showMachineModal"
            machine={targetMachine as MachineRecord}
            machineParts={machineParts.data as MachinePartRecord[]}
            open={showModalOpen}
            onClose={closeShowModal}
          />,
        ]
      : []

  const settingButtonItems = [
    { tooltipLabel: '詳細', icon: <DetailIcon />, onClick: openShowModal },
    { tooltipLabel: '編集', icon: <PencilIcon />, onClick: openEditModal },
    { tooltipLabel: '削除', icon: <TrashIcon />, onClick: openDeleteModal },
  ]

  return (
    <>
      <ListDatagrid
        title="マシンマスター"
        filters={filters}
        addButton={
          <AddButton onClick={openCreateModal} disabled={!canCreateMachine}>
            マシンを追加する
          </AddButton>
        }
        modals={modals}>
        <TextField
          source="id"
          label="マシンID"
          sortable={false}
          headerClassName={styles.machineIDField}
          sx={firstFieldSx}
        />
        <TextField
          source="serial"
          label="マシン シリアル"
          sortable={false}
          headerClassName={styles.machineSerialField}
        />
        <TextField
          source="model"
          label="機種コード"
          sortable={false}
          headerClassName={styles.machineModelField}
        />
        <TextField
          source="simImsi"
          label="SIM IMSI"
          sortable={false}
          headerClassName={styles.machineSimImsiField}
        />
        <FunctionField
          render={(record: MachineRecord) =>
            record && record.productionDate
              ? format(parseISO(record.productionDate), 'yyyy/MM/dd')
              : ''
          }
          label="製造年月日"
          sortable={false}
          headerClassName={styles.machineProductionDateField}
        />
        <TextField
          source="store.name"
          label="店舗名"
          sortable={false}
          headerClassName={styles.machineStoreNameField}
        />
        <FunctionField
          sortable={false}
          headerClassName={styles.machineIconButtonsField}
          render={(record: MachineRecord) => {
            return (
              <Grid container display="flex" justifyContent="right">
                {settingButtonItems.map((item, index) => {
                  return (
                    <IconButton<MachineRecord>
                      key={`iconButton_${index}`}
                      tooltipLabel={item.tooltipLabel}
                      onClick={item.onClick}
                      icon={item.icon}
                      record={record}
                    />
                  )
                })}
              </Grid>
            )
          }}
        />
      </ListDatagrid>
    </>
  )
}

export default MachineList
