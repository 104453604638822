import React from 'react'
import { Grid, Typography, Divider } from '@mui/material'

import { MachineParams } from '../../../types/records/machine-record'
import { MachinePartRecord } from '../../../types/records/machine-part-record'
import { masterDataChipPowderMotors } from './MasterDataChipPowderMotors'
import { masterDataDustCollectingFans } from './MasterDataDustCollectingFans'
import { masterDataLinearActuators } from './MasterDataLinearActuators'
import { masterDataPushSolenoids } from './MasterDataPushSolenoids'
import { masterDataSolenoidValves } from './MasterDataSolenoidValves'
import { masterDataTubingPumps } from './MasterDataTubingPumps'
import { masterDataVacuumPumps } from './MasterDataVacuumPumps'
import { TubingPumpRecord } from '../../../types/records/tubing_pump-record'
import { ChipPowderMotorRecord } from '../../../types/records/chip-powder-motor-record'
import { LinearActuatorRecord } from '../../../types/records/linear-actuator-record'
import { PushSolenoidRecord } from '../../../types/records/push-solenoid-record'
import { SolenoidValveRecord } from '../../../types/records/solenoid-valve-record'
import { VacuumPumpRecord } from '../../../types/records/vacuum-pump-record'
import { DustCollectingFanRecord } from '../../../types/records/dust-collecting-fan'

import {
  confirmGridContainerSx,
  confirmLabelSx,
  confirmInputSx,
} from '../../../assets/sx/form'
import { modalTitleSx } from '../../../assets/sx/modalSx'

type Props = {
  formData: MachineParams
  machineParts: MachinePartRecord[]
}

const AddMachineModalConfirm: React.FC<Props> = ({
  formData,
  machineParts,
}) => {
  const machinePartsMap = new Map<number, MachinePartRecord>()
  machineParts.forEach((machinePart) => {
    machinePartsMap.set(machinePart.id, machinePart)
  })

  const tubePumpsMap = new Map<number, TubingPumpRecord>()
  masterDataTubingPumps.forEach((tubePump) => {
    tubePumpsMap.set(tubePump.id, tubePump)
  })

  const chipPowderMotorsMap = new Map<number, ChipPowderMotorRecord>()
  masterDataChipPowderMotors.forEach((chipPowderMotor) => {
    chipPowderMotorsMap.set(chipPowderMotor.id, chipPowderMotor)
  })

  const linearActuatorsMap = new Map<number, LinearActuatorRecord>()
  masterDataLinearActuators.forEach((linearActuator) => {
    linearActuatorsMap.set(linearActuator.id, linearActuator)
  })

  const pushSolenoidsMap = new Map<number, PushSolenoidRecord>()
  masterDataPushSolenoids.forEach((pushSolenoid) => {
    pushSolenoidsMap.set(pushSolenoid.id, pushSolenoid)
  })

  const solenoidValvesMap = new Map<number, SolenoidValveRecord>()
  masterDataSolenoidValves.forEach((solenoidValve) => {
    solenoidValvesMap.set(solenoidValve.id, solenoidValve)
  })

  const vacuumPumpsMap = new Map<number, VacuumPumpRecord>()
  masterDataVacuumPumps.forEach((vacuumPump) => {
    vacuumPumpsMap.set(vacuumPump.id, vacuumPump)
  })

  const dustCollectingFansMap = new Map<number, DustCollectingFanRecord>()
  masterDataDustCollectingFans.forEach((dustCollectingFan) => {
    dustCollectingFansMap.set(dustCollectingFan.id, dustCollectingFan)
  })

  return (
    <Grid>
      <Typography sx={modalTitleSx}>マシンマスターを確認する</Typography>
      <Grid container sx={confirmGridContainerSx}>
        <Grid sx={confirmLabelSx}>マシン シリアル *</Grid>
        <Grid sx={confirmInputSx}>{formData.serial}</Grid>
      </Grid>
      <Grid container sx={confirmGridContainerSx}>
        <Grid sx={confirmLabelSx}>機種コード *</Grid>
        <Grid sx={confirmInputSx}>{formData.model}</Grid>
      </Grid>
      <Grid container sx={confirmGridContainerSx}>
        <Grid sx={confirmLabelSx}>SIM IMSI *</Grid>
        <Grid sx={confirmInputSx}>{formData.simImsi}</Grid>
      </Grid>
      <Grid container sx={confirmGridContainerSx}>
        <Grid sx={confirmLabelSx}>SIM ICCID *</Grid>
        <Grid sx={confirmInputSx}>{formData.iccid}</Grid>
      </Grid>
      <Grid container sx={confirmGridContainerSx}>
        <Grid sx={confirmLabelSx}>製造年月日</Grid>
        <Grid sx={confirmInputSx}>
          {formData.productionDate && formData.productionDate.toString()}
        </Grid>
      </Grid>
      <Divider sx={{ mt: '40px', mb: '40px' }} />
      <Grid>
        <Typography sx={modalTitleSx}>シロップ用チューブポンプ</Typography>
        <>
          {formData.machineTubingPumpLinks?.map((link) => {
            const tubingPump = tubePumpsMap.get(link.tubingPumpId)
            const machinePart = machinePartsMap.get(link.machinePartId)
            return (
              <Grid
                sx={confirmGridContainerSx}
                key={link.tubingPumpId}
                container>
                <Grid sx={confirmLabelSx}>{tubingPump?.name + ' *'}</Grid>
                <Grid sx={{ ...confirmInputSx, fontSize: '14px' }}>
                  {machinePart?.drawingNumber + ' ' + machinePart?.name}
                </Grid>
              </Grid>
            )
          })}
        </>
      </Grid>
      <Divider sx={{ mt: '40px', mb: '40px' }} />
      <Grid>
        <Typography sx={modalTitleSx}>チップ・パウダー用モーター</Typography>
        <>
          {formData.machineChipPowderMotorLinks?.map((link) => {
            const chipPowderMotor = chipPowderMotorsMap.get(
              link.chipPowderMotorId,
            )
            const machinePart = machinePartsMap.get(link.machinePartId)
            return (
              <Grid
                sx={confirmGridContainerSx}
                key={link.chipPowderMotorId}
                container>
                <Grid sx={confirmLabelSx}>{chipPowderMotor?.name + ' *'}</Grid>
                <Grid sx={{ ...confirmInputSx, fontSize: '14px' }}>
                  {machinePart?.drawingNumber + ' ' + machinePart?.name}
                </Grid>
              </Grid>
            )
          })}
        </>
      </Grid>
      <Divider sx={{ mt: '40px', mb: '40px' }} />
      <Grid>
        <Typography sx={modalTitleSx}>真空ポンプ</Typography>
        <>
          {formData.machineVacuumPumpLinks?.map((link) => {
            const vacuumPump = vacuumPumpsMap.get(link.vacuumPumpId)
            const machinePart = machinePartsMap.get(link.machinePartId)
            return (
              <Grid
                sx={confirmGridContainerSx}
                key={link.vacuumPumpId}
                container>
                <Grid sx={confirmLabelSx}>{vacuumPump?.name + ' *'}</Grid>
                <Grid sx={{ ...confirmInputSx, fontSize: '14px' }}>
                  {machinePart?.drawingNumber + ' ' + machinePart?.name}
                </Grid>
              </Grid>
            )
          })}
        </>
      </Grid>
      <Divider sx={{ mt: '40px', mb: '40px' }} />
      <Grid>
        <Typography sx={modalTitleSx}>吸塵ファン</Typography>
        <>
          {formData.machineDustCollectingFanLinks?.map((link) => {
            const dustCollectingFan = dustCollectingFansMap.get(
              link.dustCollectingFanId,
            )
            const machinePart = machinePartsMap.get(link.machinePartId)
            return (
              <Grid
                sx={confirmGridContainerSx}
                key={link.dustCollectingFanId}
                container>
                <Grid sx={confirmLabelSx}>
                  {dustCollectingFan?.name + ' *'}
                </Grid>
                <Grid sx={{ ...confirmInputSx, fontSize: '14px' }}>
                  {machinePart?.drawingNumber + ' ' + machinePart?.name}
                </Grid>
              </Grid>
            )
          })}
        </>
      </Grid>
      <Divider sx={{ mt: '40px', mb: '40px' }} />
      <Grid>
        <Typography sx={modalTitleSx}>プッシュソレノイド</Typography>
        <>
          {formData.machinePushSolenoidLinks?.map((link) => {
            const pushSolenoidValve = pushSolenoidsMap.get(link.pushSolenoidId)
            const machinePart = machinePartsMap.get(link.machinePartId)
            return (
              <Grid
                sx={confirmGridContainerSx}
                key={link.pushSolenoidId}
                container>
                <Grid sx={confirmLabelSx}>
                  {pushSolenoidValve?.name + ' *'}
                </Grid>
                <Grid sx={{ ...confirmInputSx, fontSize: '14px' }}>
                  {machinePart?.drawingNumber + ' ' + machinePart?.name}
                </Grid>
              </Grid>
            )
          })}
        </>
      </Grid>
      <Divider sx={{ mt: '40px', mb: '40px' }} />
      <Grid>
        <Typography sx={modalTitleSx}>ソレノイドバルブ</Typography>
        <>
          {formData.machineSolenoidValveLinks?.map((link) => {
            const solenoidValve = solenoidValvesMap.get(link.solenoidValveId)
            const machinePart = machinePartsMap.get(link.machinePartId)
            return (
              <Grid
                sx={confirmGridContainerSx}
                key={link.solenoidValveId}
                container>
                <Grid sx={confirmLabelSx}>{solenoidValve?.name + ' *'}</Grid>
                <Grid sx={{ ...confirmInputSx, fontSize: '14px' }}>
                  {machinePart?.drawingNumber + ' ' + machinePart?.name}
                </Grid>
              </Grid>
            )
          })}
        </>
      </Grid>
      <Divider sx={{ mt: '40px', mb: '40px' }} />
      <Grid>
        <Typography sx={modalTitleSx}>リニアアクチュエーター</Typography>
        <>
          {formData.machineLinearActuatorLinks?.map((link) => {
            const linearActuator = linearActuatorsMap.get(link.linearActuatorId)
            const machinePart = machinePartsMap.get(link.machinePartId)
            return (
              <Grid
                sx={confirmGridContainerSx}
                key={link.linearActuatorId}
                container>
                <Grid sx={confirmLabelSx}>{linearActuator?.name + ' *'}</Grid>
                <Grid sx={{ ...confirmInputSx, fontSize: '14px' }}>
                  {machinePart?.drawingNumber + ' ' + machinePart?.name}
                </Grid>
              </Grid>
            )
          })}
        </>
      </Grid>
      <Divider sx={{ mt: '40px', mb: '40px' }} />
    </Grid>
  )
}

export default AddMachineModalConfirm
