import React from 'react'
import { Link } from 'react-router-dom'
import {
  Form,
  TextInput,
  SaveButton,
  required,
  email,
  PasswordInput,
  useLogin,
  useNotify,
} from 'react-admin'
import {
  Container,
  Typography,
  Grid,
  Box,
  Stack,
  InputLabel,
} from '@mui/material'

import { ServiceLogo } from '../components/ServiceLogo'

import { gridSx, labelSx, textInputSx, saveButtonSx } from '../assets/sx/form'

import { LoginParams } from '../provider/types'

const Login: React.FC = () => {
  const login = useLogin()
  const notify = useNotify()
  const onSubmit = (data: LoginParams) => {
    // TODO: エラーハンドリングをもっと正確に
    // エラーメッセージなどを丁寧にする
    login(data).catch(() => {
      notify('メールアドレスまたは、パスワードが間違っています', {
        type: 'error',
      })
    })
  }

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ backgroundColor: 'primary.main' }}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}>
        <Stack sx={{ maxWidth: '398px' }}>
          <Box sx={{ mb: 5 }}>
            <ServiceLogo />
          </Box>
          <Box
            sx={{
              backgroundColor: '#FFF',
              p: 3,
              borderRadius: 1,
              mb: 3,
            }}>
            <Box sx={{ mb: 5 }}>
              <Typography sx={{ fontWeight: 700 }}>ログイン</Typography>
            </Box>
            <Form onSubmit={onSubmit}>
              <Grid sx={gridSx}>
                <InputLabel htmlFor="username" sx={labelSx}>
                  メールアドレス
                </InputLabel>
                <TextInput
                  source="email"
                  variant="outlined"
                  label={false}
                  sx={textInputSx}
                  validate={[
                    required('エラー入力してください'),
                    email('エラーメールアドレスを入力してください'),
                  ]}
                  fullWidth
                />
              </Grid>
              <Grid sx={gridSx}>
                <InputLabel htmlFor="password" sx={labelSx}>
                  パスワード
                </InputLabel>
                <PasswordInput
                  source="password"
                  variant="outlined"
                  label={false}
                  sx={textInputSx}
                  validate={[required('エラー入力してください')]}
                  fullWidth
                />
              </Grid>
              <Grid container direction="row" justifyContent="flex-end">
                <SaveButton label="ログイン" sx={saveButtonSx} />
              </Grid>
            </Form>
          </Box>
          <Grid container justifyContent="center">
            <Link to="/password-reset-token" style={{ textDecoration: 'none' }}>
              <Typography sx={{ color: '#fff' }}>
                パスワードをお忘れの方
              </Typography>
            </Link>
          </Grid>
        </Stack>
      </Grid>
    </Container>
  )
}

export default Login
