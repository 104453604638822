import React, { useState, useEffect } from 'react'
import { Grid, InputLabel, Typography } from '@mui/material'
import {
  confirmGridContainerSx,
  confirmInputSx,
  confirmLabelSx,
} from '../../../assets/sx/form'
import { modalTitleSx } from '../../../assets/sx/modalSx'
import {
  MachinePartParams,
  MachinePartTypeRecord,
} from '../../../types/records/machine-part-record'

type Props = {
  machinePart: MachinePartParams
  machinePartTypes: MachinePartTypeRecord[]
}

const MachinePartModalConfirm: React.FC<Props> = ({
  machinePart,
  machinePartTypes,
}) => {
  const [targetMachinePartType, setTargetMachinePartType] =
    useState<MachinePartTypeRecord>()

  useEffect(() => {
    setTargetMachinePartType(
      machinePartTypes.find(
        (machinePartType) =>
          machinePartType.id == machinePart?.machinePartTypeID,
      ),
    )
  }, [])

  return (
    <Grid>
      <Typography sx={modalTitleSx}>機械部品を確認する</Typography>
      <Grid sx={confirmGridContainerSx} container>
        <InputLabel sx={confirmLabelSx}>図番 *</InputLabel>
        <Grid sx={confirmInputSx}>{machinePart?.drawingNumber}</Grid>
      </Grid>
      <Grid sx={confirmGridContainerSx} container>
        <InputLabel sx={confirmLabelSx}>機械部品種別名 *</InputLabel>
        <Grid sx={confirmInputSx}>{targetMachinePartType?.name}</Grid>
      </Grid>
      <Grid sx={confirmGridContainerSx} container>
        <InputLabel sx={confirmLabelSx}>機械部品名 *</InputLabel>
        <Grid sx={confirmInputSx}>{machinePart?.name}</Grid>
      </Grid>
      {machinePart?.maximumOperatingHour ? (
        <Grid sx={confirmGridContainerSx} container>
          <InputLabel sx={confirmLabelSx}>最大稼働時間</InputLabel>
          <Grid sx={confirmInputSx}>
            {machinePart?.maximumOperatingHour + '時間'}
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      {machinePart?.maximumActuatingCycle ? (
        <Grid sx={confirmGridContainerSx} container>
          <InputLabel sx={confirmLabelSx}>最大稼働回数</InputLabel>
          <Grid sx={confirmInputSx}>
            {machinePart?.maximumActuatingCycle + '万回'}
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  )
}

export default MachinePartModalConfirm
