import React, { ReactNode, ReactElement } from 'react'
import {
  Datagrid,
  ListBase,
  Pagination,
  ListToolbar,
  useNotify,
  useListController,
} from 'react-admin'
import { Container, Typography, Grid, Box } from '@mui/material'
import { ReactComponent as NoDataIcon } from '../assets/images/no_data.svg'

import { ErrorResponse } from './types'

import styles from './ListDatagrid.module.scss'
import CircularProgress from '@mui/material/CircularProgress'

type Props = {
  title: string
  filters: ReactElement | ReactElement[]
  children: ReactNode
  addButton?: ReactElement
  modals: ReactElement[]
}

const ListDatagrid: React.FC<Props> = ({
  title,
  filters,
  children,
  addButton,
  modals,
}) => {
  const notify = useNotify()
  const onError = (err: unknown) => {
    notify((err as ErrorResponse).body.Message, { type: 'error' })
  }
  const { isLoading } = useListController()

  const noDataTSX: ReactElement = (
    <Grid
      sx={{
        height: '406px ',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Grid sx={{ textAlign: 'center' }}>
        <NoDataIcon style={{ width: '24px', height: '24px' }} />
        <Grid sx={{ color: '#000000' }}>データがありません</Grid>
      </Grid>
    </Grid>
  )

  const loadingDataTSX = (
    <Grid
      sx={{
        height: '406px ',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Grid sx={{ textAlign: 'center' }}>
        <CircularProgress style={{ width: '24px', height: '24px' }} />
        <Grid sx={{ color: '#000000' }}>Loading</Grid>
      </Grid>
    </Grid>
  )

  return (
    <ListBase
      queryOptions={{
        onError: onError,
        initialData: {
          data: [],
          total: 0,
          pageInfo: { hasNextPage: false, hasPreviousPage: false },
        },
      }}>
      <Container
        maxWidth={false}
        sx={{
          height: 'auto',
          overflow: 'scroll',
          borderRadius: '8px',
          padding: '0 0 8px 0 !important',
        }}>
        <Grid>
          <Grid
            sx={{ padding: '17px 24px', flexWrap: 'nowrap', height: '64px' }}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid #EDEAE3">
            <Typography
              noWrap
              sx={{
                color: '#000',
                fontWeight: '700',
                fontSize: '20px',
              }}>
              {title}
            </Typography>
            <Box sx={{ width: 'auto' }}>
              {addButton}
              {modals.map((modal) => modal)}
            </Box>
          </Grid>
          <ListToolbar filters={filters} className={styles.toolbar} />
        </Grid>
        {isLoading ? (
          loadingDataTSX
        ) : (
          <Datagrid
            hover={false}
            bulkActionButtons={false}
            empty={noDataTSX}
            sx={{
              '& .RaDatagrid-headerRow': {
                backgroundColor: '#FAF9F7',
                borderBottom: '1px solid #EDEAE3',
                height: '48px',
                '& .RaDatagrid-headerCell': {
                  border: 'none',
                  backgroundColor: '#FAF9F7',
                  color: '#757575',
                  fontWeight: 400,
                  padding: '6px 12px',
                  '& span': {
                    fontSize: '12px',
                    lineHeight: '18px',
                  },
                },
              },
              '& .RaDatagrid-rowCell': {
                borderBottom: '1px solid #EDEAE3',
                padding: '14px 12px',
              },
            }}>
            {children}
          </Datagrid>
        )}
      </Container>
      <Pagination
        rowsPerPageOptions={[]}
        // レコード数0の際に表示されるNo results foundの文字列を削除するために空のタグをlimitに設置
        limit={<></>}
        sx={{
          display: 'flex',
          flexFlow: 'column',
          height: '15%',
          backgroundColor: 'primary.main',
          '& .MuiTablePagination-toolbar': {
            backgroundColor: 'primary.main',
            padding: '40px 0',
            justifyContent: 'center',
            '& .MuiTablePagination-spacer': {
              flex: 'none',
            },
            '& .MuiTablePagination-actions': {
              margin: 0,
            },
            '& .MuiTablePagination-displayedRows': {
              display: 'none',
            },
            '& .MuiPagination-text': {
              color: '#9F9F9F',
            },
            '& .MuiPaginationItem-icon': {
              color: '#9F9F9F',
              fontSize: '24px',
            },
            '& .MuiPaginationItem-root': {
              color: '#9F9F9F',
              borderRadius: 1,
              minWidth: '48px',
              height: '48px',
              fontWeight: 700,
              margin: 0,
            },
            '& .Mui-selected': {
              backgroundColor: 'black',
              color: 'white',
              borderRadius: 1,
            },
            '& .MuiPaginationItem-ellipsis': {
              lineHeight: '48px',
            },
            '& .MuiTouchRipple-root': {
              '& *': {
                backgroundColor: 'black',
              },
            },
          },
        }}
      />
    </ListBase>
  )
}

export default ListDatagrid
