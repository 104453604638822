import React, { useEffect, useState } from 'react'
import { Form, SaveButton } from 'react-admin'
import { Grid, Modal, Box, Button, Typography } from '@mui/material'
import CancelInputModal from '../CancelInputModal'
import useCancelModal from '../../../hooks/useCancelModal'
import {
  MachinePartParams,
  MachinePartRecord,
  MachinePartTypeRecord,
  MaximumValueType,
  WhichMaximumValueType,
} from '../../../types/records/machine-part-record'
import CancelButton from '../../button/CancelButton'
import { ModalSectionType } from './ModalSectionType'
import MachinePartModalConfirm from './MachinePartModalConfirm'
import {
  modalWrapperSx,
  nextButtonSx,
  cancelButtonWrapperSx,
  saveButtonSx,
} from '../../../assets/sx/form'
import { modalTitleSx } from '../../../assets/sx/modalSx'
import MachinePartModalBasicInfo from './MachinePartModalBasicInfo'

type Props = {
  machinePart: MachinePartRecord | undefined
  open: boolean
  onClose: () => void
  onSubmit: (
    id: number,
    data: MachinePartParams,
    previousData: MachinePartRecord,
  ) => void
  machinePartTypes: MachinePartTypeRecord[]
}

const EditMachinePartModal: React.FC<Props> = ({
  machinePart,
  open,
  onClose,
  onSubmit,
  machinePartTypes,
}) => {
  const [machinePartParam, setMachinePartParam] = useState<MachinePartParams>(
    {},
  )
  const [previewMachinePartParam, setPreviewMachinePartParam] =
    useState<MachinePartParams>({})

  const [modalSection, setModalSection] = useState<ModalSectionType>(
    ModalSectionType.BasicInfo,
  )

  useEffect(() => {
    if (open) {
      setModalSection(ModalSectionType.BasicInfo)

      const param: MachinePartParams = {
        name: machinePart?.name,
        drawingNumber: machinePart?.drawingNumber,
        machinePartTypeID: machinePart?.machinePartType?.id,
        maximumOperatingHour:
          machinePart?.motorPartLifetime?.maximumOperatingHour,
        maximumActuatingCycle:
          machinePart?.machinePartLifetime?.maximumActuatingCycle,
      }
      setMachinePartParam(param)
    }
  }, [open])

  const moveToBasicInfoSection = () => {
    setModalSection(ModalSectionType.BasicInfo)
  }

  const canMoveToConfirmSection = () => {
    // 必須項目チェック
    if (
      !machinePartParam.drawingNumber ||
      !machinePartParam.name ||
      !machinePartParam.machinePartTypeID
    ) {
      return false
    }

    const machinePartType = machinePartTypes.find(
      (target) => target.id === machinePartParam.machinePartTypeID,
    )
    if (!machinePartType) return false

    switch (WhichMaximumValueType(machinePartType.name)) {
      case MaximumValueType.OperatingHour:
        if (!machinePartParam.maximumOperatingHour) return false
        break
      case MaximumValueType.ActuatingCycle:
        if (!machinePartParam.maximumActuatingCycle) return false
        break
      case MaximumValueType.None:
        return false
    }

    // 入力値に変更が一切なかった場合にはfalse(react-adminの仕様に合わせる)
    if (
      machinePartParam.drawingNumber === machinePart?.drawingNumber &&
      machinePartParam.name === machinePart?.name &&
      machinePartParam.machinePartTypeID === machinePart?.machinePartType?.id
    ) {
      if (
        machinePart?.motorPartLifetime &&
        Number(machinePartParam.maximumOperatingHour) ===
          machinePart.motorPartLifetime.maximumOperatingHour
      ) {
        // 最大稼働時間が変更されていない場合はfalse
        return false
      }
      if (
        machinePart?.machinePartLifetime &&
        Number(machinePartParam.maximumActuatingCycle) ===
          machinePart.machinePartLifetime.maximumActuatingCycle
      ) {
        // 最大作動回数が変更されていない場合はfalse
        return false
      }
    }

    return true
  }

  const moveToConfirmSection = () => {
    const imputMachinePartParams = { ...machinePartParam }
    const machinePartType = machinePartTypes.find(
      (target) => target.id === machinePartParam.machinePartTypeID,
    )

    if (machinePartType) {
      switch (WhichMaximumValueType(machinePartType.name)) {
        case MaximumValueType.OperatingHour:
          delete imputMachinePartParams.maximumActuatingCycle
          break
        case MaximumValueType.ActuatingCycle:
          delete imputMachinePartParams.maximumOperatingHour
          break
      }
    }

    setModalSection(ModalSectionType.Confirm)
    setPreviewMachinePartParam(imputMachinePartParams)
  }

  const onUpdateSubmit = (data: MachinePartParams) => {
    if (!machinePart || !machinePart.id) {
      return null
    }

    const machinePartType = machinePartTypes.find(
      (target) => target.id === machinePartParam.machinePartTypeID,
    )

    // machinePartTypeに対応した最大値のみ残す
    if (machinePartType) {
      switch (WhichMaximumValueType(machinePartType.name)) {
        case MaximumValueType.OperatingHour:
          data.maximumActuatingCycle = undefined
          break
        case MaximumValueType.ActuatingCycle:
          data.maximumOperatingHour = undefined
          break
      }
    }

    if (modalSection === ModalSectionType.BasicInfo) {
      moveToConfirmSection()
      return
    }
    onSubmit(machinePart.id, data, machinePart)
  }

  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box sx={modalWrapperSx}>
          <Form onSubmit={onUpdateSubmit} record={machinePart} mode="onBlur">
            {modalSection === ModalSectionType.BasicInfo ? (
              <>
                <Typography sx={modalTitleSx}>機械部品を編集する</Typography>
                <MachinePartModalBasicInfo
                  machinePartTypes={machinePartTypes}
                  setMachinePartParam={setMachinePartParam}
                  machinePartParam={machinePartParam}
                  machinePart={machinePart}
                />
              </>
            ) : (
              <></>
            )}
            {modalSection === ModalSectionType.Confirm ? (
              <MachinePartModalConfirm
                machinePartTypes={machinePartTypes}
                machinePart={previewMachinePartParam as MachinePartParams}
              />
            ) : (
              <></>
            )}
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              sx={{ mt: '40px' }}>
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton
                  onClick={() => {
                    switch (modalSection) {
                      case ModalSectionType.BasicInfo:
                        handleOnCancelModalOpen()
                        break
                      case ModalSectionType.Confirm:
                        moveToBasicInfoSection()
                    }
                  }}>
                  {modalSection == ModalSectionType.BasicInfo
                    ? 'キャンセル'
                    : '戻る'}
                </CancelButton>
              </Box>
              {modalSection == ModalSectionType.BasicInfo && (
                <Button
                  sx={nextButtonSx}
                  onClick={moveToConfirmSection}
                  disabled={!canMoveToConfirmSection()}>
                  次へ
                </Button>
              )}
              {modalSection == ModalSectionType.Confirm && (
                <SaveButton label="更新する" sx={saveButtonSx} />
              )}
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default EditMachinePartModal
