import { DustCollectingFanRecord } from '../../../types/records/dust-collecting-fan'

export const masterDataDustCollectingFans: DustCollectingFanRecord[] = [
  {
    id: 1,
    name: '左吸塵ファン',
  },
  {
    id: 2,
    name: '右吸塵ファン',
  },
]
