import React, { useEffect } from 'react'
import { Grid, Typography, Divider } from '@mui/material'
import {
  confirmGridContainerSx,
  confirmLabelSx,
  confirmInputSx,
} from '../../../assets/sx/form'
import { modalTitleSx, modalDividerSx } from '../../../assets/sx/modalSx'
import { MaintenanceTypeRecord } from '../../../types/records/maintenance-type-record'
import { ErrorLogRecord } from '../../../types/records/error-log-record'
import { MachineRecord } from '../../../types/records/machine-record'
import { MachinePartRecord } from '../../../types/records/machine-part-record'
import { MaintenanceLogParams } from '../../../types/records/maintenance-log-record'
import { formatDate } from '../../../assets/utils/formData'

type Props = {
  maintenanceLog: MaintenanceLogParams
  maintenanceTypes: MaintenanceTypeRecord[]
  errorLogs: ErrorLogRecord[]
  machines: MachineRecord[]
  machineParts: MachinePartRecord[]
  setHasConfirmed: (hasConfirmed: boolean) => void
  isEditMode?: boolean
}

const MaintenanceLogConfirm: React.FC<Props> = ({
  maintenanceLog,
  machines,
  maintenanceTypes,
  errorLogs,
  machineParts,
  isEditMode,
  setHasConfirmed,
}) => {
  useEffect(() => {
    setHasConfirmed(true)
  }, [])

  return (
    <Grid sx={{ width: 398 }}>
      <Typography sx={modalTitleSx}>
        {isEditMode
          ? 'メンテナンスログ編集を確認する'
          : 'メンテナンスログ追加を確認する'}
      </Typography>
      <Grid sx={confirmGridContainerSx} container>
        <Grid sx={{ ...confirmLabelSx, mt: 'auto' }}>マシン シリアル *</Grid>
        <Grid
          sx={{
            ...confirmInputSx,
            fontSize: '14px',
            wordBreak: 'break-all',
            whiteSpace: 'normal',
          }}>
          {' '}
          {
            machines.find((machine) => machine.id === maintenanceLog?.machineID)
              ?.serial
          }
        </Grid>
      </Grid>
      <Grid sx={confirmGridContainerSx} container>
        <Grid sx={{ ...confirmLabelSx, mt: 'auto' }}>メンテナンス種別 *</Grid>
        <Grid
          sx={{
            ...confirmInputSx,
            fontSize: '14px',
            wordBreak: 'break-all',
            whiteSpace: 'normal',
          }}>
          {
            maintenanceTypes.find(
              (maintenanceType) =>
                maintenanceLog.maintenanceTypeID === maintenanceType?.id,
            )?.name
          }
        </Grid>
      </Grid>
      <Grid sx={confirmGridContainerSx} container>
        <Grid sx={{ ...confirmLabelSx, mt: 'auto' }}>詳細 *</Grid>
        <Grid
          sx={{
            ...confirmInputSx,
            fontSize: '14px',
            wordBreak: 'break-all',
            whiteSpace: 'normal',
          }}>
          {maintenanceLog?.description}
        </Grid>
      </Grid>
      <Grid sx={confirmGridContainerSx} container>
        <Grid sx={{ ...confirmLabelSx, mb: 'auto' }}>実施日 *</Grid>
        <Grid
          sx={{
            ...confirmInputSx,
            fontSize: '14px',
            wordBreak: 'break-all',
            whiteSpace: 'normal',
          }}>
          {formatDate(maintenanceLog?.implementedAt)}
        </Grid>
      </Grid>
      <Grid sx={confirmGridContainerSx} container>
        <Grid sx={{ ...confirmLabelSx, mb: 'auto' }}>解決したエラー</Grid>
        <Grid
          sx={{
            ...confirmInputSx,
            fontSize: '14px',
            wordBreak: 'break-all',
            whiteSpace: 'normal',
          }}>
          {
            errorLogs.find(
              (errorLog) => maintenanceLog.errorLogID === errorLog.id,
            )?.errorEvent.name
          }
        </Grid>
      </Grid>
      <Divider sx={modalDividerSx} />
      <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
        シロップ用チューブポンプ
      </Typography>
      <Grid sx={confirmGridContainerSx}>
        {maintenanceLog?.newMachineTubingPumpLinks?.map(
          (tubingPumpLink, index) => {
            const part = machineParts.find(
              (part) => part.id === tubingPumpLink.machinePartID,
            )

            const tubingPumpPart = machines
              .flatMap((machine) => machine.machineTubingPumpLinks)
              .find(
                (link) => link.id === tubingPumpLink.tubingPumpID,
              )?.tubingPump

            return (
              <Grid sx={{ mb: '24px' }} key={index} container>
                <Grid
                  sx={{
                    ...confirmLabelSx,
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                  }}>
                  {tubingPumpPart?.name} *
                </Grid>
                <Grid
                  sx={{
                    ...confirmInputSx,
                    fontSize: '14px',
                    wordBreak: 'break-all',
                    whiteSpace: 'normal',
                  }}>
                  {part ? part.drawingNumber + ' ' + part.name : ''}
                </Grid>
                {index !==
                  (maintenanceLog?.newMachineTubingPumpLinks?.length || 0) -
                    1 && <br />}
              </Grid>
            )
          },
        )}
      </Grid>
      <Divider sx={modalDividerSx} />
      <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
        チップ・パウダー用モーター
      </Typography>
      <Grid sx={confirmGridContainerSx}>
        {maintenanceLog?.newMachineChipPowderMotorLinks?.map(
          (chipPowderMotorLink, index) => {
            const part = machineParts.find(
              (part) => part.id === chipPowderMotorLink.machinePartID,
            )
            const chipPowderMotorPart = machines
              .flatMap((machine) => machine.machineChipPowderMotorLinks)
              .find(
                (link) => link.id === chipPowderMotorLink.chipPowderMotorID,
              )?.chipPowderMotor
            return (
              <Grid sx={{ mb: '24px' }} key={index} container>
                <Grid
                  sx={{
                    ...confirmLabelSx,
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                  }}>
                  {chipPowderMotorPart?.name} *
                </Grid>
                <Grid
                  sx={{
                    ...confirmInputSx,
                    fontSize: '14px',
                    wordBreak: 'break-all',
                    whiteSpace: 'normal',
                  }}>
                  {part ? part.drawingNumber + ' ' + part.name : ''}
                </Grid>
                {index !==
                  (maintenanceLog?.newMachineChipPowderMotorLinks?.length ||
                    0) -
                    1 && <br />}
              </Grid>
            )
          },
        )}
      </Grid>
      <Divider sx={modalDividerSx} />
      <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
        真空ポンプ
      </Typography>
      <Grid sx={confirmGridContainerSx}>
        {maintenanceLog?.newMachineVacuumPumpLinks?.map(
          (vacuumPumpLink, index) => {
            const part = machineParts.find(
              (part) => part.id === vacuumPumpLink.machinePartID,
            )
            const vacuumPumpPart = machines
              .flatMap((machine) => machine.machineVacuumPumpLinks)
              .find(
                (link) => link.id === vacuumPumpLink.vacuumPumpID,
              )?.vacuumPump
            return (
              <Grid sx={{ mb: '24px' }} key={index} container>
                <Grid
                  sx={{
                    ...confirmLabelSx,
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                  }}>
                  {vacuumPumpPart?.name} *
                </Grid>
                <Grid
                  sx={{
                    ...confirmInputSx,
                    fontSize: '14px',
                    wordBreak: 'break-all',
                    whiteSpace: 'normal',
                  }}>
                  {part ? part.drawingNumber + ' ' + part.name : ''}
                </Grid>
                {index !==
                  (maintenanceLog?.newMachineVacuumPumpLinks?.length || 0) -
                    1 && <br />}
              </Grid>
            )
          },
        )}
      </Grid>
      <Divider sx={modalDividerSx} />
      <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
        吸塵ファン
      </Typography>
      <Grid sx={confirmGridContainerSx}>
        {maintenanceLog?.newMachineDustCollectingFanLinks?.map(
          (dustCollectingFanLink, index) => {
            const part = machineParts.find(
              (part) => part.id === dustCollectingFanLink.machinePartID,
            )

            const dustCollectingFanPart = machines
              .flatMap((machine) => machine.machineDustCollectingFanLinks)
              .find(
                (link) => link.id === dustCollectingFanLink.dustCollectingFanID,
              )?.dustCollectingFan

            return (
              <Grid sx={{ mb: '24px' }} key={index} container>
                <Grid
                  sx={{
                    ...confirmLabelSx,
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                  }}>
                  {dustCollectingFanPart?.name} *
                </Grid>
                <Grid
                  sx={{
                    ...confirmInputSx,
                    fontSize: '14px',
                    wordBreak: 'break-all',
                    whiteSpace: 'normal',
                  }}>
                  {part ? part.drawingNumber + ' ' + part.name : ''}
                </Grid>
                {index !==
                  (maintenanceLog?.newMachineDustCollectingFanLinks?.length ||
                    0) -
                    1 && <br />}
              </Grid>
            )
          },
        )}
      </Grid>
      <Divider sx={modalDividerSx} />
      <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
        プッシュソレノイド
      </Typography>
      <Grid sx={confirmGridContainerSx}>
        {maintenanceLog?.newMachinePushSolenoidLinks?.map(
          (pushSolenoidLink, index) => {
            const part = machineParts.find(
              (part) => part.id === pushSolenoidLink.machinePartID,
            )

            const pushSolenoidPart = machines
              .flatMap((machine) => machine.machinePushSolenoidLinks)
              .find(
                (link) => link.id === pushSolenoidLink.pushSolenoidID,
              )?.pushSolenoid

            return (
              <Grid sx={{ mb: '24px' }} key={index} container>
                <Grid
                  sx={{
                    ...confirmLabelSx,
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                  }}>
                  {pushSolenoidPart?.name} *
                </Grid>
                <Grid
                  sx={{
                    ...confirmInputSx,
                    fontSize: '14px',
                    wordBreak: 'break-all',
                    whiteSpace: 'normal',
                  }}>
                  {part ? part.drawingNumber + ' ' + part.name : ''}
                </Grid>
                {index !==
                  (maintenanceLog?.newMachinePushSolenoidLinks?.length || 0) -
                    1 && <br />}
              </Grid>
            )
          },
        )}
      </Grid>
      <Divider sx={modalDividerSx} />
      <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
        ソレノイドバルブ
      </Typography>
      <Grid sx={confirmGridContainerSx}>
        {maintenanceLog?.newMachineSolenoidValveLinks?.map(
          (solenoidValveLink, index) => {
            const part = machineParts.find(
              (part) => part.id === solenoidValveLink.machinePartID,
            )

            const solenoidValvePart = machines
              .flatMap((machine) => machine.machineSolenoidValveLinks)
              .find(
                (link) => link.id === solenoidValveLink.solenoidValveID,
              )?.solenoidValve

            return (
              <Grid sx={{ mb: '24px' }} key={index} container>
                <Grid
                  sx={{
                    ...confirmLabelSx,
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                  }}>
                  {solenoidValvePart?.name} *
                </Grid>
                <Grid
                  sx={{
                    ...confirmInputSx,
                    fontSize: '14px',
                    wordBreak: 'break-all',
                    whiteSpace: 'normal',
                  }}>
                  {part ? part.drawingNumber + ' ' + part.name : ''}
                </Grid>
                {index !==
                  (maintenanceLog?.newMachineSolenoidValveLinks?.length || 0) -
                    1 && <br />}
              </Grid>
            )
          },
        )}
      </Grid>
      <Divider sx={modalDividerSx} />
      <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
        リニアアクチュエーター
      </Typography>
      <Grid sx={confirmGridContainerSx}>
        {maintenanceLog?.newMachineLinearActuatorLinks?.map(
          (linearActuatorLink, index) => {
            const part = machineParts.find(
              (part) => part.id === linearActuatorLink.machinePartID,
            )

            const linearActuatorPart = machines
              .flatMap((machine) => machine.machineLinearActuatorLinks)
              .find(
                (link) => link.id === linearActuatorLink.linearActuatorID,
              )?.linearActuator

            return (
              <Grid sx={{ mb: '24px' }} key={index} container>
                <Grid
                  sx={{
                    ...confirmLabelSx,
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                  }}>
                  {linearActuatorPart?.name} *
                </Grid>
                <Grid
                  sx={{
                    ...confirmInputSx,
                    fontSize: '14px',
                    wordBreak: 'break-all',
                    whiteSpace: 'normal',
                    width: '187px',
                  }}>
                  {part ? part.drawingNumber + ' ' + part.name : ''}
                </Grid>
                {index !==
                  (maintenanceLog?.newMachineLinearActuatorLinks?.length || 0) -
                    1 && <br />}
              </Grid>
            )
          },
        )}
      </Grid>
    </Grid>
  )
}

export default MaintenanceLogConfirm
