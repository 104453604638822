import React, { useEffect, useState } from 'react'
import { Grid, Typography, Modal, Box, Divider } from '@mui/material'
import {
  confirmGridContainerSx,
  confirmInputWithLineBreakSx,
  confirmLabelWithLineBreakSx,
} from '../../../assets/sx/form'
import { MaintenanceTypeRecord } from '../../../types/records/maintenance-type-record'
import { ErrorLogRecord } from '../../../types/records/error-log-record'
import { MachineRecord } from '../../../types/records/machine-record'
import { MachinePartRecord } from '../../../types/records/machine-part-record'
import {
  MaintenanceLogRecord,
  ShowMaintenanceLogInfoRecord,
} from '../../../types/records/maintenance-log-record'
import { formatDate } from '../../../assets/utils/formData'
import CancelButton from '../../button/CancelButton'
import { dataProvider } from '../../../provider/data-provider'
import {
  wideModalWrapperSx,
  cancelButtonWrapperSx,
} from '../../../assets/sx/form'

type Props = {
  maintenanceLog: MaintenanceLogRecord
  maintenanceTypes: MaintenanceTypeRecord[]
  errorLogs: ErrorLogRecord[]
  machines: MachineRecord[]
  machineParts: MachinePartRecord[]
  open: boolean
  onClose: () => void
}

const ShowMaintenanceLogModal: React.FC<Props> = ({
  maintenanceLog,
  machines,
  maintenanceTypes,
  errorLogs,
  machineParts,
  open,
  onClose,
}) => {
  const [machineLinksInfos, setMachineLinksInfo] = useState<
    ShowMaintenanceLogInfoRecord | undefined
  >()

  const id = maintenanceLog?.id

  const getShowMachineLinkInfos = () => {
    if (id !== undefined && id !== null) {
      dataProvider
        .getShowMachineLinkInfos(id)
        .then((res: { data: ShowMaintenanceLogInfoRecord }) => {
          setMachineLinksInfo(res.data)
        })
    }
  }

  useEffect(() => {
    getShowMachineLinkInfos()
  }, [open])

  return (
    (machineLinksInfos && (
      <Modal open={open} onClose={onClose}>
        <Box sx={wideModalWrapperSx}>
          <Grid>
            <Typography sx={{ fontSize: '16px', fontWeight: 700, mb: '40px' }}>
              メンテナンスログを確認する
            </Typography>
            <Grid
              container
              direction="row"
              sx={{ mb: '24px', alignItems: 'flex-start' }}>
              <Grid item xs={5.75} style={{ flex: 1 }}>
                <Grid sx={confirmLabelWithLineBreakSx}>マシン シリアル *</Grid>
              </Grid>
              <Grid item xs={0.5}></Grid>
              <Grid item xs={5.75} style={{ flex: 1 }}>
                <Grid sx={confirmInputWithLineBreakSx}>
                  {
                    machines.find(
                      (machine) => machine.id === maintenanceLog?.machine.id,
                    )?.serial
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              sx={{ mb: '24px', alignItems: 'flex-start' }}>
              <Grid item xs={5.75} style={{ flex: 1 }}>
                <Grid sx={confirmLabelWithLineBreakSx}>メンテナンス種別 *</Grid>
              </Grid>
              <Grid item xs={0.5}></Grid>
              <Grid item xs={5.75} style={{ flex: 1 }}>
                <Grid sx={confirmInputWithLineBreakSx}>
                  {maintenanceLog?.maintenanceType?.id &&
                    maintenanceTypes.find(
                      (maintenanceType) =>
                        maintenanceLog.maintenanceType.id ===
                        maintenanceType?.id,
                    )?.name}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              sx={{ mb: '24px', alignItems: 'flex-start' }}>
              <Grid item xs={5.75} style={{ flex: 1 }}>
                <Grid sx={confirmLabelWithLineBreakSx}>詳細 *</Grid>
              </Grid>
              <Grid item xs={0.5}></Grid>
              <Grid item xs={5.75} style={{ flex: 1 }}>
                <Grid sx={confirmInputWithLineBreakSx}>
                  {maintenanceLog?.description}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              sx={{ mb: '24px', alignItems: 'flex-start' }}>
              <Grid item xs={5.75} style={{ flex: 1 }}>
                <Grid sx={confirmLabelWithLineBreakSx}>実施日 *</Grid>
              </Grid>
              <Grid item xs={0.5}></Grid>
              <Grid item xs={5.75} style={{ flex: 1 }}>
                <Grid sx={confirmInputWithLineBreakSx}>
                  {formatDate(maintenanceLog?.implementedAt)}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              sx={{ mb: '24px', alignItems: 'flex-start' }}>
              <Grid item xs={5.75} style={{ flex: 1 }}>
                <Grid sx={confirmLabelWithLineBreakSx}>解決したエラー *</Grid>
              </Grid>
              <Grid item xs={0.5}></Grid>
              <Grid item xs={5.75} style={{ flex: 1 }}>
                <Grid sx={confirmInputWithLineBreakSx}>
                  {maintenanceLog?.errorLogID &&
                    errorLogs.find(
                      (errorLog) => maintenanceLog.errorLogID === errorLog.id,
                    )?.errorEvent?.name}
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ mt: '20px', mb: '20px' }} />
            <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
              シロップ用チューブポンプ
            </Typography>
            <Grid sx={confirmGridContainerSx} container direction="column">
              {machineLinksInfos?.previousMachineTubingPumpLinks?.map(
                (tubingPumpLink, index) => {
                  // 交換前の情報を取得する
                  const partBefore = machineParts.find(
                    (part) => part.id === tubingPumpLink.machinePartID,
                  )

                  // 交換後の情報を取得する
                  const tubingPumpLinkAfter =
                    machineLinksInfos?.afterMachineTubingPumpLinks[index]
                  const partAfter = machineParts.find(
                    (part) => part.id === tubingPumpLinkAfter?.machinePartID,
                  )

                  return (
                    <Grid
                      key={index}
                      container
                      direction="row"
                      sx={{ mb: '24px', alignItems: 'flex-start' }}>
                      <Grid item xs={5.75} style={{ flex: 1 }}>
                        <Grid sx={confirmLabelWithLineBreakSx}>
                          {tubingPumpLink?.tubingPump.name + ' 交換前'}
                        </Grid>
                        <Grid sx={confirmInputWithLineBreakSx}>
                          {partBefore
                            ? partBefore.drawingNumber + ' ' + partBefore.name
                            : ''}
                        </Grid>
                      </Grid>
                      <Grid item xs={0.5}></Grid>
                      <Grid item xs={5.75} style={{ flex: 1 }}>
                        <Grid sx={confirmLabelWithLineBreakSx}>
                          {tubingPumpLink?.tubingPump.name + ' 交換後'}
                        </Grid>
                        <Grid sx={confirmInputWithLineBreakSx}>
                          {partAfter
                            ? partAfter.drawingNumber + ' ' + partAfter.name
                            : ''}
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                },
              )}
            </Grid>
            <Divider sx={{ mt: '20px', mb: '20px' }} />
            <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
              チップ・パウダー用モーター
            </Typography>
            <Grid sx={confirmGridContainerSx} container direction="column">
              {machineLinksInfos?.previousMachineChipPowderMotorLinks?.map(
                (chipPowderMotorLink, index) => {
                  // 交換前のパーツ情報
                  const partBefore = machineParts.find(
                    (part) => part.id === chipPowderMotorLink.machinePartID,
                  )

                  // 交換後のパーツ情報
                  const chipPowderMotorLinkAfter =
                    machineLinksInfos?.afterMachineChipPowderMotorLinks[index]
                  const partAfter = machineParts.find(
                    (part) =>
                      part.id === chipPowderMotorLinkAfter?.machinePartID,
                  )

                  return (
                    <Grid
                      key={index}
                      container
                      direction="row"
                      sx={{ mb: '24px', alignItems: 'flex-start' }}>
                      <Grid item xs={5.75} style={{ flex: 1 }}>
                        <Grid sx={confirmLabelWithLineBreakSx}>
                          {chipPowderMotorLink?.chipPowderMotor.name +
                            ' 交換前'}
                        </Grid>
                        <Grid sx={confirmInputWithLineBreakSx}>
                          {partBefore
                            ? partBefore.drawingNumber + ' ' + partBefore.name
                            : ''}
                        </Grid>
                      </Grid>
                      <Grid item xs={0.5}></Grid>
                      <Grid item xs={5.75} style={{ flex: 1 }}>
                        <Grid sx={confirmLabelWithLineBreakSx}>
                          {chipPowderMotorLink?.chipPowderMotor.name +
                            ' 交換後'}
                        </Grid>
                        <Grid sx={confirmInputWithLineBreakSx}>
                          {partAfter
                            ? partAfter.drawingNumber + ' ' + partAfter.name
                            : ''}
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                },
              )}
            </Grid>
            <Divider sx={{ mt: '20px', mb: '20px' }} />
            <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
              真空ポンプ
            </Typography>
            <Grid sx={confirmGridContainerSx} container direction="column">
              {machineLinksInfos?.previousMachineVacuumPumpLinks?.map(
                (vacuumPumpLink, index) => {
                  const partBefore = machineParts.find(
                    (part) => part.id === vacuumPumpLink.machinePartID,
                  )

                  const vacuumPumpLinkAfter =
                    machineLinksInfos?.afterMachineVacuumPumpLinks[index]
                  const partAfter = machineParts.find(
                    (part) => part.id === vacuumPumpLinkAfter?.machinePartID,
                  )

                  return (
                    <Grid
                      key={index}
                      container
                      direction="row"
                      sx={{ mb: '24px', alignItems: 'flex-start' }}>
                      <Grid item xs={5.75} style={{ flex: 1 }}>
                        <Grid sx={confirmLabelWithLineBreakSx}>
                          {vacuumPumpLink?.vacuumPump.name + ' 交換前'}
                        </Grid>
                        <Grid sx={confirmInputWithLineBreakSx}>
                          {partBefore
                            ? partBefore.drawingNumber + ' ' + partBefore.name
                            : ''}
                        </Grid>
                      </Grid>
                      <Grid item xs={0.5}></Grid>
                      <Grid item xs={5.75} style={{ flex: 1 }}>
                        <Grid sx={confirmLabelWithLineBreakSx}>
                          {vacuumPumpLink?.vacuumPump.name + ' 交換後'}
                        </Grid>
                        <Grid sx={confirmInputWithLineBreakSx}>
                          {partAfter
                            ? partAfter.drawingNumber + ' ' + partAfter.name
                            : ''}
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                },
              )}
            </Grid>
            <Divider sx={{ mt: '20px', mb: '20px' }} />
            <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
              吸塵ファン
            </Typography>
            <Grid sx={confirmGridContainerSx} container direction="column">
              {machineLinksInfos?.previousMachineDustCollectingFanLinks?.map(
                (dustCollectingFanLink, index) => {
                  const partBefore = machineParts.find(
                    (part) => part.id === dustCollectingFanLink.machinePartID,
                  )

                  const dustCollectingFanLinkAfter =
                    machineLinksInfos?.afterMachineDustCollectingFanLinks[index]
                  const partAfter = machineParts.find(
                    (part) =>
                      part.id === dustCollectingFanLinkAfter?.machinePartID,
                  )

                  return (
                    <Grid
                      key={index}
                      container
                      direction="row"
                      sx={{ mb: '24px', alignItems: 'flex-start' }}>
                      <Grid item xs={5.75} style={{ flex: 1 }}>
                        <Grid sx={confirmLabelWithLineBreakSx}>
                          {dustCollectingFanLink?.dustCollectingFan.name +
                            ' 交換前'}
                        </Grid>
                        <Grid sx={confirmInputWithLineBreakSx}>
                          {partBefore
                            ? partBefore.drawingNumber + ' ' + partBefore.name
                            : ''}
                        </Grid>
                      </Grid>
                      <Grid item xs={0.5}></Grid>
                      <Grid item xs={5.75} style={{ flex: 1 }}>
                        <Grid sx={confirmLabelWithLineBreakSx}>
                          {dustCollectingFanLink?.dustCollectingFan.name +
                            ' 交換後'}
                        </Grid>
                        <Grid sx={confirmInputWithLineBreakSx}>
                          {partAfter
                            ? partAfter.drawingNumber + ' ' + partAfter.name
                            : ''}
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                },
              )}
            </Grid>
            <Divider sx={{ mt: '20px', mb: '20px' }} />
            <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
              プッシュソレノイド
            </Typography>
            <Grid sx={confirmGridContainerSx} container direction="column">
              {machineLinksInfos?.previousMachinePushSolenoidLinks?.map(
                (pushSolenoidLink, index) => {
                  const partBefore = machineParts.find(
                    (part) => part.id === pushSolenoidLink.machinePartID,
                  )

                  const pushSolenoidLinkAfter =
                    machineLinksInfos?.afterMachinePushSolenoidLinks[index]
                  const partAfter = machineParts.find(
                    (part) => part.id === pushSolenoidLinkAfter?.machinePartID,
                  )

                  return (
                    <Grid
                      key={index}
                      container
                      direction="row"
                      sx={{ mb: '24px', alignItems: 'flex-start' }}>
                      <Grid item xs={5.75} style={{ flex: 1 }}>
                        <Grid sx={confirmLabelWithLineBreakSx}>
                          {pushSolenoidLink?.pushSolenoid.name + ' 交換前'}
                        </Grid>
                        <Grid sx={confirmInputWithLineBreakSx}>
                          {partBefore
                            ? partBefore.drawingNumber + ' ' + partBefore.name
                            : ''}
                        </Grid>
                      </Grid>
                      <Grid item xs={0.5}></Grid>
                      <Grid item xs={5.75} style={{ flex: 1 }}>
                        <Grid sx={confirmLabelWithLineBreakSx}>
                          {pushSolenoidLink?.pushSolenoid.name + ' 交換後'}
                        </Grid>
                        <Grid sx={confirmInputWithLineBreakSx}>
                          {partAfter
                            ? partAfter.drawingNumber + ' ' + partAfter.name
                            : ''}
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                },
              )}
            </Grid>
            <Divider sx={{ mt: '20px', mb: '20px' }} />
            <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
              ソレノイドバルブ
            </Typography>
            <Grid sx={confirmGridContainerSx} container direction="column">
              {machineLinksInfos?.previousMachineSolenoidValveLinks?.map(
                (solenoidValveLink, index) => {
                  const partBefore = machineParts.find(
                    (part) => part.id === solenoidValveLink.machinePartID,
                  )

                  const solenoidValveLinkAfter =
                    machineLinksInfos?.afterMachineSolenoidValveLinks[index]
                  const partAfter = machineParts.find(
                    (part) => part.id === solenoidValveLinkAfter?.machinePartID,
                  )

                  return (
                    <Grid
                      key={index}
                      container
                      direction="row"
                      sx={{ mb: '24px', alignItems: 'flex-start' }}>
                      <Grid item xs={5.75} style={{ flex: 1 }}>
                        <Grid sx={confirmLabelWithLineBreakSx}>
                          {solenoidValveLink?.solenoidValve.name + ' 交換前'}
                        </Grid>
                        <Grid sx={confirmInputWithLineBreakSx}>
                          {partBefore
                            ? partBefore.drawingNumber + ' ' + partBefore.name
                            : ''}
                        </Grid>
                      </Grid>
                      <Grid item xs={0.5}></Grid>
                      <Grid item xs={5.75} style={{ flex: 1 }}>
                        <Grid sx={confirmLabelWithLineBreakSx}>
                          {solenoidValveLink?.solenoidValve.name + ' 交換後'}
                        </Grid>
                        <Grid sx={confirmInputWithLineBreakSx}>
                          {partAfter
                            ? partAfter.drawingNumber + ' ' + partAfter.name
                            : ''}
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                },
              )}
            </Grid>
            <Divider sx={{ mt: '20px', mb: '20px' }} />
            <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
              リニアアクチュエーター
            </Typography>
            <Grid sx={confirmGridContainerSx} container direction="column">
              {machineLinksInfos?.previousMachineLinearActuatorLinks?.map(
                (linearActuatorLink, index) => {
                  const partBefore = machineParts.find(
                    (part) => part.id === linearActuatorLink.machinePartID,
                  )

                  const linearActuatorLinkAfter =
                    machineLinksInfos?.afterMachineLinearActuatorLinks[index]
                  const partAfter = machineParts.find(
                    (part) =>
                      part.id === linearActuatorLinkAfter?.machinePartID,
                  )
                  return (
                    <Grid
                      key={index}
                      container
                      direction="row"
                      sx={{ mb: '24px', alignItems: 'flex-start' }}>
                      <Grid item xs={5.75} style={{ flex: 1 }}>
                        <Grid sx={confirmLabelWithLineBreakSx}>
                          {linearActuatorLink?.linearActuator.name + ' 交換前'}
                        </Grid>
                        <Grid sx={confirmInputWithLineBreakSx}>
                          {partBefore
                            ? partBefore.drawingNumber + ' ' + partBefore.name
                            : ''}
                        </Grid>
                      </Grid>
                      <Grid item xs={0.5}></Grid>
                      <Grid item xs={5.75} style={{ flex: 1 }}>
                        <Grid sx={confirmLabelWithLineBreakSx}>
                          {linearActuatorLink?.linearActuator.name + ' 交換後'}
                        </Grid>
                        <Grid sx={confirmInputWithLineBreakSx}>
                          {partAfter
                            ? partAfter.drawingNumber + ' ' + partAfter.name
                            : ''}
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                },
              )}
            </Grid>
            <Divider sx={{ mt: '20px', mb: '20px' }} />
            <Grid container justifyContent="flex-end">
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton onClick={onClose}>閉じる</CancelButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    )) || <></>
  )
}

export default ShowMaintenanceLogModal
