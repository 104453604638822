import React from 'react'
import { Box, Grid, InputLabel, Modal, Typography } from '@mui/material'
import { ErrorLogRecord } from '../../types/records/error-log-record'
import { format, parseISO } from 'date-fns'
import {
  modalWrapperSx,
  labelGrayOutSx,
  cancelButtonWrapperSx,
  confirmGridContainerSx,
  confirmInputGridSx,
} from '../../assets/sx/form'
import { modalTitleSx } from '../../assets/sx/modalSx'
import CancelButton from '../button/CancelButton'

type Props = {
  errorLog: ErrorLogRecord | undefined
  open: boolean
  onClose: () => void
}

const ShowErrorLogModal: React.FC<Props> = ({ open, onClose, errorLog }) => {
  return errorLog ? (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={modalWrapperSx}>
          <Typography sx={modalTitleSx}>エラーログを確認する</Typography>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel
              htmlFor="name"
              sx={{
                ...labelGrayOutSx,
                mb: 'auto',
                mr: 'auto',
                width: '50%',
              }}>
              ステータス
            </InputLabel>
            <Grid sx={confirmInputGridSx}>
              {errorLog.isSolved ? '対応済み' : '未対応'}
            </Grid>
          </Grid>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel
              htmlFor="name"
              sx={{
                ...labelGrayOutSx,
                mb: 'auto',
                mr: 'auto',
                width: '50%',
              }}>
              イベントID
            </InputLabel>
            <Grid sx={confirmInputGridSx}>{errorLog.id}</Grid>
          </Grid>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel
              htmlFor="name"
              sx={{
                ...labelGrayOutSx,
                mb: 'auto',
                mr: 'auto',
                width: '50%',
              }}>
              エラーレベル
            </InputLabel>
            <Grid sx={confirmInputGridSx}>
              {errorLog.errorEvent.errorLevel}
            </Grid>
          </Grid>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel
              htmlFor="name"
              sx={{
                ...labelGrayOutSx,
                mb: 'auto',
                mr: 'auto',
                width: '50%',
              }}>
              エラー名
            </InputLabel>
            <Grid sx={confirmInputGridSx}>{errorLog.errorEvent.name}</Grid>
          </Grid>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel
              htmlFor="name"
              sx={{
                ...labelGrayOutSx,
                mb: 'auto',
                mr: 'auto',
                width: '50%',
              }}>
              エラー詳細
            </InputLabel>
            <Grid sx={confirmInputGridSx}>
              {errorLog.errorEvent.description !== ''
                ? errorLog.errorEvent.description
                : '-'}
            </Grid>
          </Grid>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel
              htmlFor="name"
              sx={{
                ...labelGrayOutSx,
                mb: 'auto',
                mr: 'auto',
                width: '50%',
              }}>
              店舗名
            </InputLabel>
            <Grid sx={confirmInputGridSx}>{errorLog.machine.store.name}</Grid>
          </Grid>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel
              htmlFor="name"
              sx={{
                ...labelGrayOutSx,
                mb: 'auto',
                mr: 'auto',
                width: '50%',
              }}>
              マシンシリアル
            </InputLabel>
            <Grid sx={confirmInputGridSx}>{errorLog.machine.serial}</Grid>
          </Grid>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel
              htmlFor="name"
              sx={{
                ...labelGrayOutSx,
                mb: 'auto',
                mr: 'auto',
                width: '50%',
              }}>
              発生日時
            </InputLabel>
            <Grid sx={confirmInputGridSx}>
              {format(parseISO(errorLog.occurredAt), 'yyyy/MM/dd HH:mm')}
            </Grid>
          </Grid>
          {errorLog.orderLog && (
            <Grid>
              <Typography sx={modalTitleSx}>エラーに紐づく注文情報</Typography>
              <Grid sx={confirmGridContainerSx} container>
                <InputLabel
                  htmlFor="name"
                  sx={{
                    ...labelGrayOutSx,
                    mb: 'auto',
                    mr: 'auto',
                    width: '50%',
                  }}>
                  オーダーログID
                </InputLabel>
                <Grid sx={confirmInputGridSx}>{errorLog.orderLog.id}</Grid>
              </Grid>
              <Grid sx={confirmGridContainerSx} container>
                <InputLabel
                  htmlFor="name"
                  sx={{
                    ...labelGrayOutSx,
                    mb: 'auto',
                    mr: 'auto',
                    width: '50%',
                  }}>
                  レシピ名
                </InputLabel>
                <Grid sx={confirmInputGridSx}>
                  {errorLog.orderLog.recipeName}
                </Grid>
              </Grid>
              <Grid sx={confirmGridContainerSx} container>
                <InputLabel
                  htmlFor="name"
                  sx={{
                    ...labelGrayOutSx,
                    mb: 'auto',
                    mr: 'auto',
                    width: '50%',
                  }}>
                  調理ID
                </InputLabel>
                <Grid sx={confirmInputGridSx}>
                  {errorLog.orderLog.cookingID}
                </Grid>
              </Grid>
              <Grid sx={confirmGridContainerSx} container>
                <InputLabel
                  htmlFor="name"
                  sx={{
                    ...labelGrayOutSx,
                    mb: 'auto',
                    mr: 'auto',
                    width: '50%',
                  }}>
                  注文受付日時
                </InputLabel>
                <Grid sx={confirmInputGridSx}>
                  {format(
                    parseISO(errorLog.orderLog.orderReceivedAt),
                    'yyyy/MM/dd HH:mm',
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid container justifyContent="flex-end">
            <Box sx={cancelButtonWrapperSx}>
              <CancelButton onClick={() => onClose()}>閉じる</CancelButton>
            </Box>
          </Grid>
        </Box>
      </Modal>
    </>
  ) : (
    <></>
  )
}

export default ShowErrorLogModal
