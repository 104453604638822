import React, {
  useState,
  ErrorInfo,
  ComponentType,
  HtmlHTMLAttributes,
} from 'react'
import { Error, ErrorProps, SkipNavigationButton } from 'react-admin'
import { ErrorBoundary } from 'react-error-boundary'
import clsx from 'clsx'
import { CoreLayoutProps } from 'ra-core'
import Sidebar from './Sidebar'
import { styled } from '@mui/material/styles'

import TabletMenu from './TabletMenu'
import styles from './Sidebar.module.scss'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'

import Drawer from '@mui/material/Drawer'
import Logo from '../assets/images/wide_logo.svg'

import MenuIcon from '@mui/icons-material/Menu'

export const Layout = (props: LayoutProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open)
  }
  const { children, className, error: errorComponent, title, ...rest } = props
  const [errorInfo, setErrorInfo] = useState<ErrorInfo>()

  const handleError = (error: Error, info: ErrorInfo) => {
    setErrorInfo(info)
  }

  return (
    <StyledLayout className={clsx('layout', className)} {...rest}>
      <AppBar position="static" className={styles['hide-appbar']}>
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}>
              <MenuIcon fontSize="large" />
            </IconButton>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <img
              src={Logo}
              alt="Image"
              style={{ marginRight: '0px', width: '173px' }}
            />
          </div>
          <div>{/* 中央揃えにするための空要素 */}</div>
        </Toolbar>
      </AppBar>
      <Drawer
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{ style: { width: '252px', backgroundColor: '#212121' } }}>
        <TabletMenu anchor="left" />
      </Drawer>
      <SkipNavigationButton />
      <div className={LayoutClasses.appFrame}>
        <main className={LayoutClasses.contentWithSidebar}>
          <Sidebar />
          <div id="main-content" className={LayoutClasses.content}>
            <ErrorBoundary
              onError={handleError}
              fallbackRender={({ error, resetErrorBoundary }) => (
                <Error
                  error={error}
                  errorComponent={errorComponent}
                  errorInfo={errorInfo}
                  resetErrorBoundary={resetErrorBoundary}
                  title={title}
                />
              )}>
              {children}
            </ErrorBoundary>
          </div>
        </main>
      </div>
    </StyledLayout>
  )
}

export interface LayoutProps
  extends CoreLayoutProps,
    Omit<HtmlHTMLAttributes<HTMLDivElement>, 'title'> {
  className?: string
  error?: ComponentType<ErrorProps>
}

export interface LayoutState {
  hasError: boolean
  error?: Error
  errorInfo?: ErrorInfo
}

const PREFIX = 'RaLayout'
export const LayoutClasses = {
  appFrame: `${PREFIX}-appFrame`,
  contentWithSidebar: `${PREFIX}-contentWithSidebar`,
  content: `${PREFIX}-content`,
}

const StyledLayout = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1,
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  position: 'relative',
  minWidth: '1440px',
  width: '100%',
  color: theme.palette.getContrastText(theme.palette.background.default),

  [`& .${LayoutClasses.appFrame}`]: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(7),
    },
  },
  [`& .${LayoutClasses.contentWithSidebar}`]: {
    display: 'flex',
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [`& .${LayoutClasses.content}`]: {
    backgroundColor: theme.palette.background.default,
    zIndex: 2,
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 0,
    padding: 0,
    margin: '20px 0',
    [theme.breakpoints.up('xs')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(1),
    },
  },
}))

export default Layout
